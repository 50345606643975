import React from 'react'
import { Button } from 'components/common/Button'
import IconButton from '@mui/material/IconButton'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormButtons, FormFooter } from 'components/common/Form'
import {
  TestsContainer,
  TestsGrid,
  TestsHeader,
  TestsWrapper,
  CheckBoxFlex,
  DrawerContentContainer,
  FilterHeader,
  FilterTitle,
} from '../styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import CheckBox from 'components/common/Checkbox'
import useAddTestToGroup from 'hooks/tests/GroupTestAssessment/useAddTestToGroup'
import TestItem from 'pages/tests/testItem/TestItem'
import { ITestAssessment } from 'services/tests/getTestsService.interface'
import { IGroupTestAssessmentProps } from 'hooks/tests/GroupTestAssessment/useAddTestToGroup.interface'
import { isItemSelected } from 'utils/selection'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const AddTestToGroup = ({
  groupData,
  closeDrawer,
  onTestAdded,
}: IGroupTestAssessmentProps): React.JSX.Element => {
  const {
    t,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    selectAllItem,
    isAllSelected,
    tests,
    handleButtonClick,
    selectedItems,
    selectItem,
    setIsSearchType,
  } = useAddTestToGroup({ groupData, closeDrawer, onTestAdded })

  return (
    <>
      <CustomDrawerHeader title={t('tests_layout.new_test')} onClose={closeDrawer} />
      <DrawerContent headerHeight={68}>
        <FilterHeader>
          <FilterTitle>{t('actions.filters')}</FilterTitle>
        </FilterHeader>
        <FilterHeader width='50%'>
          <form onSubmit={onSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
            />
          </form>
        </FilterHeader>
        <DrawerContentContainer>
          <TestsHeader>
            <FilterTitle>{t('general.tests')}</FilterTitle>
            <TestsWrapper>
              <CheckBoxFlex>
                <FilterTitle>{t('general.select_all')}</FilterTitle>
                <CheckBox onChange={selectAllItem} checked={isAllSelected} />
              </CheckBoxFlex>
            </TestsWrapper>
          </TestsHeader>
          <>
            <TestsContainer>
              <TestsGrid>
                {tests.map((test: ITestAssessment, index: number) => (
                  <TestItem
                    key={index}
                    item={test}
                    showCheckBox={true}
                    setIsSearchType={setIsSearchType}
                    selectItem={selectItem}
                    isChecked={Boolean(test.id && isItemSelected(test.id, selectedItems))}
                    disableNavigation={true}
                  />
                ))}
              </TestsGrid>
            </TestsContainer>
          </>

          <FormFooter>
            <div
              style={{
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <FormButtons>
                <Button
                  text={t('actions.save')}
                  type='small'
                  onClick={handleButtonClick}
                  background='#06C68F'
                />
                <Button
                  text={t('actions.cancel')}
                  type='small'
                  onClick={closeDrawer}
                  background='#E0E1E2'
                  textColor='#414141'
                />
              </FormButtons>
            </div>
          </FormFooter>
        </DrawerContentContainer>
      </DrawerContent>
    </>
  )
}

export default AddTestToGroup
