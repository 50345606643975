import React, { ReactElement } from 'react'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import { t } from 'i18next'
import TextInput from 'components/common/TextInput/TextInput'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import { Header, Wrapper, RightContainer } from './styled-components'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { ReactComponent as FilterAltOffIcon } from 'assets/remove-filter.svg'
import { LayoutWrapper } from '../groupsLayout/styled-components'
import Drawer from 'components/common/Drawer'
import Grid from 'components/common/GridV2'
import LinkQuestionsToTestAssessmentDrawer from './linkQuestionsToTestAssessmentDrawer/LinkQuestionsToTestAssessmentDrawer'
import MyLoader from 'loading/loading'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { IQuestionsLayout } from './questions.interface'
import useTestQuestionsServices from 'hooks/tests/TestDetails/QuestionsLayout/useTestQuestionsServices'
import FilterDrawer from 'pages/questions/FilterDrawer'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ToolbarItem } from 'components/common/Toolbar'
import TablePagination from 'components/common/Pagination/TablePagination'
import { TFunction } from 'interfaces/TFunction'
import { SplashScreen } from 'components/common'
import Tooltip from '@mui/material/Tooltip'

const QuestionsLayout = ({ testAssessment, companyId }: IQuestionsLayout): ReactElement => {
  const {
    state,
    config,
    actions,
    questions,
    currentPage,
    questionsLoading,
    questionsRefetch,
    handleAddQuestionsToTestAssessment,
    handleRemoveQuestionsFromTestAssessment,
    handleSearchChange,
    handleResetClick,
    handleSearchSubmit,
    handleFilterClick,
    perPage,
    setAddQuestionsDrawer,
    selectedItemsOnPage,
    filtered,
    searchValue,
    selectedItem,
    redirectToQuestion,
    selectItem,
    selectAllItem,
    setFilterDrawerOpened,
    addQuestionsDrawer,
    filterData,
    setFilterData,
    setFiltered,
    handleChangePage,
    handleChangeRowsPerPage,
    filterDrawerOpened,
    setCurrentPage,
    createButton,
    isSearchType,
  } = useTestQuestionsServices({
    testAssessmentId: testAssessment.id as string,
    testAssessmentQuestions: testAssessment.questions as string[],
  })

  return (
    <>
      {questionsLoading && !searchValue ? (
        <MyLoader />
      ) : !questions?.data.length &&
        !isSearchType &&
        !filtered &&
        searchValue === '' &&
        companyId ? (
        <SplashScreen
          title={t('questions_layout.no_questions')}
          subTitle={t('questions_layout.create_questions')}
          createButton={createButton}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <LayoutWrapper>
          <Header>
            <Wrapper>
              <Wrapper>
                <Button
                  text={t('quiz_details.add_question')}
                  color='secondary'
                  onClick={(): void => {
                    setAddQuestionsDrawer(true)
                  }}
                  icon={<AddCircleIcon />}
                  background='#06C68F'
                />
                <GridMultipleActions
                  selectedItems={selectedItemsOnPage.length}
                  actions={[
                    {
                      id: 0,
                      color: 'secondary',
                      hide: !state.userPermission.deleteModule,
                      tooltipText: `${t('actions.delete')}`,
                      disabled: selectedItemsOnPage.length < 1,
                      onClick: () => handleRemoveQuestionsFromTestAssessment(selectedItemsOnPage),
                      component: <DeleteIcon fontSize='small' />,
                    },
                  ].filter((i: { hide: boolean }) => !i.hide)}
                />
              </Wrapper>
              <RightContainer>
                <ToolbarItem $noGap>
                  <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                    <Tooltip title={t('actions.filter')} arrow>
                      {filtered ? <RedFilterIcon /> : <FilterIcon />}
                    </Tooltip>
                  </IconButton>
                  {filtered && (
                    <IconButton disabled={!filtered && !searchValue} onClick={handleResetClick}>
                      <Tooltip title={t('actions.remove_filter')} arrow>
                        <FilterAltOffIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </ToolbarItem>
                <form onSubmit={handleSearchSubmit}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type='submit'>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                  />
                </form>
              </RightContainer>
            </Wrapper>
          </Header>
          {questions && questions.data.length ? (
            <div>
              <Grid
                actionConfig={actions}
                config={config}
                data={questions.data || []}
                selectedItems={selectedItem[currentPage] || []}
                selectAllItem={selectAllItem}
                selectItem={selectItem}
                selected={_.size(selectedItem[currentPage] || [])}
                redirectToDetailsHandler={redirectToQuestion}
                width={40}
                indexOfSpecificWidth={0}
              />
              <TablePagination
                currentPage={currentPage}
                rowsPerPage={perPage}
                count={questions.totalCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <SplashScreen
              title={t('general.no_results_found')}
              subTitle={t('general.try_adjusting_your_search_or_filter')}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          )}
        </LayoutWrapper>
      )}
      <Drawer opened={addQuestionsDrawer} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <LinkQuestionsToTestAssessmentDrawer
          testAssessmentId={testAssessment.id as string}
          questionsToNotInclude={testAssessment.questions || []}
          closeDrawer={(): void => setAddQuestionsDrawer(false)}
          handleCloseDrawer={handleAddQuestionsToTestAssessment}
          manualCompanyId={companyId}
        />
      </Drawer>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => setFilterDrawerOpened(false)}
        totalWidth='600px'
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel='module'
          handleFilterClick={handleFilterClick}
          companyId={companyId}
          refetch={questionsRefetch}
          currentPage={1}
          setIsFiltered={setFiltered}
          setCurrentPage={setCurrentPage}
          t={t as unknown as TFunction}
        />
      </Drawer>
    </>
  )
}

export default QuestionsLayout
