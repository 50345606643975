import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { ISelectAll, ISelectedItem } from 'components/common/FilterInterface/filter.interface'
import { ITags } from 'components/common/Tags/tags.interface'
import { IOption } from 'components/UsersLayout/staticData'
import { FormikValues } from 'formik'
import { IAttachment } from 'interfaces/common'
import { IGroup } from 'interfaces/groups'
import { TFunction } from 'interfaces/TFunction'
import { ICourse } from 'pages/courses/courseList.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IActionConfig, IColumnConfig } from 'pages/tasks/TasksLayout.interface'
import { ChangeEvent, FormEvent } from 'react'

export interface IFetchMoreVariables {
  filter?: ITaskFilter
  currentPage?: number
  perPage?: number
  companyId?: string
}
export interface ITaskFilter {
  status?: string
  assignedTo?: string
  name?: string
  groupIds?: string | null
}

interface ITag {
  id?: string
  label: string
  value?: string
}

export interface ITask {
  _id?: string
  id?: string
  title?: string
  name?: string
  trigger?: string
  description?: string
  createDate?: string
  status?: string
  courseId?: ICourse | string
  video: IAttachment | string | null
  audio: IAttachment | string | null
  moduleId?: IModule | string
  attachment?: IAttachment | null
  groupIds?: IGroup[] | (string | undefined)[]
  tags?: ITag[]
}
export interface ITasksResponse {
  data: ITask[] | null
  totalPages: number
}

export interface IUseFetchTasksResponse {
  tasks: ITask[]
  totalPages?: number
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ITask>>
  fetchMore: (variables?: IFetchMoreVariables) => void
}

export enum GetTasksByGroupFiltersByEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface IUseFetchTasksByGroupProps {
  searchValue?: string
  currentPage?: number
  filterBy: GetTasksByGroupFiltersByEnums
  perPage?: number
  groupId?: string
  tags?: string[]
}
export interface IUseAddTaskToGroupReturnType {
  t: TFunction
  searchValue: string
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectAllItem: () => void
  tasks: ITask[] | null
  selectAll: ISelectAll
  selectedTasks: string[]
  selectItem: (id: string) => void
  gridConfig?: IColumnConfig[]
  selectedItem: ISelectedItem
  currentPage: number
  onFormSubmit: () => Promise<void>
  perPage: number
  handlePaginationClick: (event: ChangeEvent<unknown> | null, newPage: number) => Promise<void>
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  loading: boolean
  hasNoTasks: boolean
  showNoResultsMessage: boolean
  totalPages: number | undefined
  handleTagSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  tagList: ITag[]
  setFieldValue: (
    field: string,
    value: string | number | boolean | IOption | null | ITag[],
    shouldValidate?: boolean | undefined,
  ) => void
  values: FormikValues
}

export interface ITaskToGroupDto {
  taskIds?: string[]
  groupId?: string
}
export interface IFetchTasksByCompanyIdProps {
  companyId?: string
  groupId?: string
  taskId?: string | undefined
}

export interface IAddTaskToGroupResponse {
  addTask: (variables: ITaskToGroupDto) => void
  tasks: ITask[] | null
  loading: boolean
  error: string
}
export interface IRemoveTaskFromGroupResponse {
  removeTasksFromGroup: (removeTaskDto: ITaskToGroupDto) => Promise<void>
  tasks: ITask[] | null
  loading: boolean
  error: string
}

export interface IUseGroupTasksReturn {
  t: TFunction
  tasks: ITask[]
  refetchTasks: (params: { searchValue: string; groupId: string }) => void
  createButton: () => React.JSX.Element
  searchValue: string
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  groupId?: string
  loading: boolean
  isSearchType: boolean
  initialLoad: boolean
  hasNoTasks: boolean
  selectedTasks: string[]
  selectAll: ISelectAll
  gridConfig: IColumnConfig[]
  actions: IActionConfig[]
  selectAllItem: () => void
  selectItem: (id?: string) => void
  selectedItem: ISelectedItem
  currentPage: number
  handlePaginationClick: (event: ChangeEvent<unknown> | null, newPage: number) => Promise<void>
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  deleteTasks: (id?: string) => void
  perPage: number
  totalPages?: number
}

export interface GetTasksByCompanyIdGroupedByTagsResponse {
  getTasksByCompanyIdGroupedByTags: {
    data: ITask[]
    totalPages: number
  }
}

export interface ITasksGroupedByTag {
  tag: string
  tasks: ITask[]
}

export interface IGetTagsResponse {
  getAllTags: ITags[]
}

export interface IUseFetchTags {
  data: ITags[] | undefined
  loading: boolean
  error: string | undefined
}

export interface IUseFetchGroupsByTaskProps {
  taskId: string | undefined
  groupInclusion: GetGroupsByTaskFiltersEnums
}

export interface IUseFetchGroupsResponse {
  groups: IGroup[]
  totalCount: number
  currentPage: number
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<IGroup>>
  fetchMore: (variables: IUseFetchGroupsByTaskProps) => void
}

export enum GetGroupsByTaskFiltersEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface IAddGroupsToTaskResponse {
  addGroups: (variables: IGroupsToTaskDto) => void
  tasks: ITask[] | null
  loading: boolean
  error: string
}

export interface IGroupsToTaskDto {
  groupIds: string[]
  taskId: string
}

export interface IRemoveGroupsFromTaskResponse {
  removeGroups: (variables: IGroupsToTaskDto) => void
  tasks: ITask[] | null
  loading: boolean
  error: string
}
