import React from 'react'
import { t } from 'i18next'
import {
  TextWrapper,
  DetailsHeader,
  InformationBackground,
  ProfileBackground,
  StyledFlex,
  CenteredText,
} from './styled-components'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'

const UserDetailsComponent = ({
  profileData,
}: {
  profileData: IPersonalInformation
}): React.JSX.Element => {
  return (
    <StyledFlex>
      <ProfileBackground>
        <DetailsHeader>{t('user_details.biography')}</DetailsHeader>
        <TextWrapper>
          {profileData.biography ? (
            profileData.biography
          ) : (
            <CenteredText>{t('general.no_info')}</CenteredText>
          )}
        </TextWrapper>
      </ProfileBackground>
      <InformationBackground>
        <DetailsHeader>{t('form_fields.note')}</DetailsHeader>
        <TextWrapper>
          {profileData.note ? (
            profileData.note
          ) : (
            <CenteredText>{t('general.no_info')}</CenteredText>
          )}
        </TextWrapper>
      </InformationBackground>
    </StyledFlex>
  )
}

export default UserDetailsComponent
