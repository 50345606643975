import { gql } from 'graphql.macro'

export const GET_BOOKS_BY_COMPANY_ID = gql`
  query GetBooksByCompanyId(
    $companyId: String
    $searchValue: String
    $currentPage: Int
    $perPage: Int
  ) {
    getBooksByCompanyId(
      companyId: $companyId
      searchValue: $searchValue
      currentPage: $currentPage
      perPage: $perPage
    ) {
      data {
        companyId
        contentDocument {
          name
          link
          fileType
        }
        coverImage {
          name
          fileType
          link
        }
        description
        id
        title
      }
      totalPages
    }
  }
`

export const GET_BOOKS_BY_GROUP = gql`
  query GetBooksByGroup(
    $groupId: String!
    $searchValue: String
    $filterBy: GroupInclusionEnums
    $currentPage: Int
    $perPage: Int
  ) {
    getBooksByGroup(
      groupId: $groupId
      searchValue: $searchValue
      filterBy: $filterBy
      currentPage: $currentPage
      perPage: $perPage
    ) {
      totalPages
      data {
        companyId
        contentDocument {
          fileType
          link
          name
          size
          thumbnail
        }
        id
        title
        coverImage {
          name
          size
          fileType
          link
          thumbnail
        }
        description
      }
    }
  }
`

export const DELETE_BOOKS = gql`
  mutation DeleteBooks($ids: [String]!) {
    deleteBooks(ids: $ids)
  }
`
export const ADD_BOOK_TO_GROUP = gql`
  mutation AddBooksToGroup($bookIds: [String]!, $groupId: String!) {
    addBooksToGroup(bookIds: $bookIds, groupId: $groupId) {
      id
    }
  }
`
export const REMOVE_BOOKS_FROM_GROUP = gql`
  mutation RemoveBooksFromGroup($bookIds: [String]!, $groupId: String!) {
    removeBooksFromGroup(bookIds: $bookIds, groupId: $groupId) {
      id
    }
  }
`

export const UPDATE_BOOK = gql`
  mutation UpdateBook($bookId: String!, $updateBookDto: BookInput) {
    updateBook(id: $bookId, updateBookDto: $updateBookDto) {
      id
    }
  }
`

export const ADD_BOOK = gql`
  mutation CreateBook($createBookDto: BookInput!) {
    createBook(createBookDto: $createBookDto) {
      id
    }
  }
`

export const GET_BOOK_BY_ID = gql`
  query GetBookById($bookId: String!) {
    getBookById(id: $bookId) {
      id
      title
      description
      coverImage {
        name
        link
        fileType
      }
      contentDocument {
        link
        name
        fileType
      }
      companyId
    }
  }
`
