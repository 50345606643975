import React, { ReactElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GroupMainInfo from './GroupMainInfo'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ReactComponent as Users } from 'components/common/Button/icons/user-multiple.svg'
import { DrawerEventEmitter } from 'helpers/drawer'
import { actionConfig } from './gridConfig'
import { useUserValue } from 'context/UserContext'
import useDeleteGroup from 'hooks/group/useDeleteGroup'
import { useSwal } from 'hooks/useSwal'
import BreadCrumbV2 from 'components/common/BreadCrumbV2'
import { TabNavigation, TabPanel, activeTabClassName } from 'components/common/Tabs/Tabs'
import { styles } from 'pages/modules/ModuleDetail/ModuleDetailContent/styled-components'
import { NavigationWrapper } from './styled-components'
import GroupStudents from '../GroupStudents/GroupStudents'
import GroupTutors from '../GroupTutors/GroupTutors'
import GroupCourses from './GroupCourses'
import { GroupTestAssessments } from '../GroupTestAssessments/GroupTestAssessments'
import GroupBooks from '../GroupBooks/GroupBooks'
import { getGroupByIdService } from 'services/group/getGroupById/getGroupByIdService'
import { IGroup } from 'interfaces/groups'
import GroupTasks from '../GroupTasks/GroupTasks'

const GroupDashboard = (): ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const { data, loading, refetch } = getGroupByIdService(id)
  const group = data?.getGroupById

  const { deleteGroupServ } = useDeleteGroup()

  const handleDeleteGroup = (groupIds: string[]): void => {
    // If no group IDs are provided, exit the function early
    if (groupIds.length === 0) return

    // Determine if multiple groups are being deleted to set the appropriate title
    const isMultipleGroups = groupIds.length > 1
    const title = isMultipleGroups
      ? `${t('popups.delete_many')} ${t('general.groups')}?`
      : `${t('popups.delete_single')} ${t('general.group')}?`

    const params = {
      title,
      // Define the confirmation action to delete groups and navigate back to the groups page
      onConfirm: async (): Promise<void> => {
        await deleteGroupServ(groupIds, refetch)
        refetch()
        navigate('/groups')
      },
      confirmText: t('popups.confirm_delete'),
    }
    // Trigger a confirmation dialog with the specified parameters
    fireSwal(params)
  }

  const handleClick = (event: string | string[] | IGroup, actionType?: string): void => {
    // Handle deletion action if specified
    if (actionType === 'delete') {
      // Convert single string event to an array for consistency
      const groupIds = typeof event === 'string' ? [event] : (event as string[])
      handleDeleteGroup(groupIds)
      return
    }

    // If the event is a group object and not a deletion, open the group information drawer
    if (!Array.isArray(event) && actionType !== 'delete') {
      const { id, company } = event as IGroup
      DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
        id,
        companyId: company ? company.id : null,
      })
    }
  }

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    setValue(newValue)
  }

  const actions = actionConfig(handleClick, state.userPermission, t)

  const breadCrumbData = [
    {
      label: `Groups`,
      icon: <Users />,
      link: `/groups`,
    },
    {
      label: group?.name || '',
    },
  ]

  return (
    <div>
      <BreadCrumbV2 data={breadCrumbData} />
      {group ? (
        <div>
          <GroupMainInfo group={data.getGroupById} actions={actions} />
          <NavigationWrapper>
            <TabNavigation style={{ marginBlock: '20px' }}>
              <AppBar position='static' style={styles.bar}>
                <Tabs value={value} onChange={handleTabChange} aria-label='simple tabs example'>
                  <Tab
                    label={`${t('general.students')}`}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab label={t('general.coaches')} {...activeTabClassName(1)} style={styles.btn} />
                  <Tab label={t('general.courses')} {...activeTabClassName(2)} style={styles.btn} />
                  <Tab label={t('general.tests')} {...activeTabClassName(3)} style={styles.btn} />
                  <Tab label={t('general.books')} {...activeTabClassName(4)} style={styles.btn} />
                  <Tab label={t('general.tasks')} {...activeTabClassName(5)} style={styles.btn} />
                </Tabs>
              </AppBar>
            </TabNavigation>

            <TabPanel value={value} index={0} padding={0}>
              <GroupStudents group={group} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <GroupTutors group={group} />
            </TabPanel>

            <TabPanel value={value} index={2} padding={0}>
              <GroupCourses groupId={id || ''} />
            </TabPanel>
            <TabPanel value={value} index={3} padding={0}>
              <GroupTestAssessments group={group} />
            </TabPanel>
            <TabPanel value={value} index={4} padding={0}>
              <GroupBooks group={group} />
            </TabPanel>
            <TabPanel value={value} index={5} padding={0}>
              <GroupTasks group={group} />
            </TabPanel>
          </NavigationWrapper>
        </div>
      ) : loading ? (
        <div>Loading...</div>
      ) : (
        <div>{t('general.error')}</div>
      )}
    </div>
  )
}

export default GroupDashboard
