import { gql } from 'graphql.macro'

export const EXPORT_TEST_ASSESSMENTS_SCORE = gql`
  mutation ExportTestAssessmentsScore($testAssessmentId: String!) {
    exportTestAssessmentsScore(testAssessmentId: $testAssessmentId) {
      link
    }
  }
`

export const EXPORT_GROUP_SCORES = gql`
  mutation ExportGroupScore($groupId: String!, $options: [ExportGroupScoreOption]) {
    exportGroupScore(groupId: $groupId, options: $options) {
      link
    }
  }
`
