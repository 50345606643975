import React, { ReactElement } from 'react'
import {
  Header,
  Title,
  Toolbar,
  FormButtons,
  FormFooter,
  FormFieldsWrapper,
  ModulesList,
} from '../styled-components'

import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { MultipleAction, MultipleActionTooltip } from 'components/common/Grid/styled-components'
import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { CardsLayout } from 'components/common/Cards'
import Modal from 'components/common/Modal'
import VideoPlayer from 'components/common/VideoPlayer'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'
import { useAddModuleToQuestionDrawer } from '../hooks'
import { IAddModuleToQuestionDrawerProps } from './addModuleToQuestionDrawer.interface'

const AddModuleToQuestionDrawer = ({
  title,
  closeDrawer,
  selectedModules,
  setSelectedModules,
}: IAddModuleToQuestionDrawerProps): ReactElement => {
  const {
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    refetch,
    loading,
    modulesData,
    handleVideoModal,
    selectItem,
    selectedModuleIds,
    selectedItems,
    handleResetClick,
    handleSearchSubmit,
    handleSearchChange,
    searchValue,
    handleSubmit,
    handleCloseDrawer,
    filtered,
    setFiltered,
    videoModal,
    t,
  } = useAddModuleToQuestionDrawer({
    closeDrawer,
    selectedModules,
    setSelectedModules,
  })

  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => setFilterDrawerOpened(false)}
        totalWidth='500px'
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchData={refetch}
          onClose={(): void => setFilterDrawerOpened(false)}
          setFiltered={setFiltered}
          type='nestedArrayNotIn'
          setFilterQuery={setFiltered}
          perPage={12}
          t={t}
        />
      </Drawer>
      <CustomDrawerHeader title={title} onClose={handleCloseDrawer} />
      <DrawerContent>
        <ModulesList>
          <FormFieldsWrapper>
            <Header
              style={{
                marginTop: 20,
                borderBottom: '1px solid #ccc',
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Module list</Title>
              </div>
              <Toolbar>
                <div style={{ display: 'flex' }}>
                  <MultipleAction>
                    <IconButton
                      disabled={filtered || searchValue ? false : true}
                      onClick={handleResetClick}
                    >
                      <SyncIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>Reset</MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction>
                    <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                      <FilterListIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>Filter</MultipleActionTooltip>
                  </MultipleAction>
                  <form onSubmit={handleSearchSubmit} style={{ marginRight: 10 }}>
                    <TextInput
                      label='Search'
                      type='text'
                      size='small'
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type='submit'>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </div>
              </Toolbar>
            </Header>
            {loading && <div>Loading...</div>}
            {modulesData && (
              <div style={{ padding: 10 }}>
                <CardsLayout
                  gridStyle={'card'}
                  data={modulesData.allModules}
                  noRecordsText='No Records found'
                  handleVideoModal={handleVideoModal}
                  selectItem={selectItem}
                  selectedModuleIds={selectedModuleIds}
                  selectedItems={selectedItems}
                  refetch={refetch}
                  cols={2}
                  t={t}
                />
              </div>
            )}
          </FormFieldsWrapper>
          <FormFooter>
            <FormButtons>
              <Button text='Cancel' type='small' onClick={handleCloseDrawer}></Button>
              <Button
                text='Add'
                type='small'
                color='secondary'
                onClick={handleSubmit}
                isDisabled={selectedItems.length === 0}
              ></Button>
            </FormButtons>
          </FormFooter>
        </ModulesList>
      </DrawerContent>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal as () => void}>
        <VideoPlayer url={videoModal.data} />
      </Modal>
    </>
  )
}

export default AddModuleToQuestionDrawer
