import { useMutation } from '@apollo/client'
import {
  IExportGroupScoresServiceParams,
  IExportGroupScoresServiceResponse,
  IExportGroupScoresServiceReturn,
} from './exportData.interface'
import { EXPORT_GROUP_SCORES } from 'gql/analytics/exportData.query'

export const exportGroupScoresService = (): IExportGroupScoresServiceReturn => {
  const [exportGroupScoreMutation, { loading, error }] = useMutation<
    IExportGroupScoresServiceResponse,
    IExportGroupScoresServiceParams
  >(EXPORT_GROUP_SCORES)

  return {
    exportGroupScores: async (
      variables: IExportGroupScoresServiceParams,
    ): Promise<string | null> => {
      const response = await exportGroupScoreMutation({ variables })
      return response.data?.exportGroupScore.link || null
    },
    loading,
    error: error?.message,
  }
}
