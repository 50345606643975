import React, { ChangeEvent } from 'react'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import { IEditAdminProps } from './editAdmin.interface'
import useAdminDrawer from 'hooks/admins/useAdminDrawer'
import { ISelectFilterOption } from 'interfaces/common'
import MyLoader from 'loading/loading'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const EditAdmin = ({ id, role }: IEditAdminProps): React.JSX.Element => {
  const {
    editUserLoading,
    state,
    t,
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    onDateChange,
    currentDate,
    handleCloseDrawerEdit,
    genderOptions,
  } = useAdminDrawer({ adminId: id, role })

  return (
    <>
      {editUserLoading && <MyLoader />}
      <CustomDrawerHeader
        title={`${t('actions.edit')} ${
          role === 'SUPER_ADMIN'
            ? `${state.selectedCompany ? 'Company ' : ''}Super Admin`
            : t('general.admin')
        }`}
        onClose={handleCloseDrawerEdit}
      />
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.first_name')}
                  required
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size='small'
                  placeholder={t('user_details.first_name')}
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.last_name')}
                  required
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size='small'
                  placeholder={t('user_details.last_name')}
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  error={!!errors.email}
                  errorMessage={errors.email || ''}
                  touched={touched.email}
                  title={t('user_details.email')}
                  required
                  size='small'
                  placeholder={t('user_details.email')}
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields || null}
                  selectChange={(e: ISelectFilterOption | null): void =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel='Phone'
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={!!(errors.phone || errors.phoneFields)}
                  errorMessage={errors.phone || errors.phoneFields || ''}
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  // error={errors.gender && touched.gender ? true : false}
                  // errorMessage={errors.gender ? errors.gender : ''}
                  // touched={touched.gender}
                  value={values.gender}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker name='birthDate' value={currentDate} onChange={onDateChange} />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size='small'
                  placeholder={`${t('form_fields.job_title')}`}
                  name='jobTitle'
                  type='text'
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size='small'
                  placeholder={`${t('form_fields.location')}`}
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  size='small'
                  placeholder={`${t('quiz_details.type_here')}`}
                  name='biography'
                  type='text'
                  multiline
                  rows='3'
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size='small'
                  name='note'
                  type='text'
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows='3'
                />
              </FormField>
              <FormField width='100%'>
                <CustomSwitcher
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('isTutor', e?.target?.checked)
                  }}
                  label={t('users_layout.is_tutor')}
                  name='isTutor'
                  value={values.isTutor}
                  on={t('general.yes')}
                  off={t('general.no')}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawerEdit}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditAdmin
