import React, { ChangeEvent } from 'react'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
  InputGroup,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import SelectAsync from 'components/common/Select/SelectAsync'
import Uploader from 'components/common/FileUploader/Uploader'
import { StyledFlex } from 'pages/courses/AddCourseDrawer/styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { Title } from 'pages/tests/addTestDrawer/styled-components'
import { GapStyles, StyledColumnFlexContainer } from '../styled-components'
import { StyledHLSButton } from './EditLessonDrawer.styles'
import { IFormData } from 'hooks/lessons/useAddLessonDrawer/useAddLesson.interface'
import { useEditLessonDrawer } from 'hooks/lessons/useEditLessons/useEditLessonDrawer'
import { IEditDrawerProps } from './editLessonDrawer.interface'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const EditLessonDrawer = ({ data, onClose, moduleId, lesson }: IEditDrawerProps): JSX.Element => {
  const {
    handleChange,
    handleCloseDrawer,
    handleContentChange,
    handleFileChange,
    handleImageChange,
    handleReHLSConversion,
    handleSelectChange,
    handleSubmit,
    t,
    topicDisabled,
    touched,
    submitDisabled,
    fileExtension,
    fileLoading,
    values,
    setFieldValue,
    imageLoading,
    uploadVideoProgress,
    fileTypes,
    deleteContentFile,
    conversionLoading,
    displayReConversionButton,
    currentLesson,
    getTopicsLoading,
    topicsList,
    errors,
    combinedConstants,
  } = useEditLessonDrawer({ onClose, moduleId, lesson, data })

  return (
    <>
      <CustomDrawerHeader title={t('lessons_layout.edit_lesson')} onClose={handleCloseDrawer} />
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormGroupItem>
              <Title>{`${t('general.name')}*`}</Title>
              <Input
                error={!!errors.name}
                errorMessage={errors.name ? errors.name : ''}
                touched={touched.name}
                label={``}
                name='name'
                size='small'
                type='text'
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>
            <FormGroupItem>
              <Title>{`${t('general.topic')}*`}</Title>
              <SelectAsync
                error={!!errors.topic}
                errorMessage={errors.topic ? (errors.topic as string) : ''}
                touched={!!touched.topic}
                onInputChange={handleSelectChange}
                onChange={(e): void => setFieldValue('topic', e as string)}
                data={(topicsList && topicsList) || []}
                value={values.topic}
                label={''}
                loading={getTopicsLoading}
                isClearable
                disabled={topicDisabled}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Title>{t('lesson_details.lesson_overview')}</Title>
              <TextEditor
                text={currentLesson?.description}
                isVisible={true}
                hasControls={false}
                contentHeight='200px'
                handleChange={(editorState: string): void => {
                  setFieldValue('description', editorState)
                }}
                canEdit={true}
                defaultText={t('lesson_details.lesson_overview')}
              />
            </FormGroupItem>
            <FormGroupItem>
              <Title>{t('form_fields.transcript')}</Title>
              <TextEditor
                text={currentLesson?.transcript}
                isVisible={true}
                hasControls={false}
                handleChange={(editorState: string): void => {
                  setFieldValue('transcript', editorState)
                }}
                canEdit={true}
                defaultText={t('form_fields.transcript')}
              />
            </FormGroupItem>
            {displayReConversionButton && (
              <StyledHLSButton onClick={handleReHLSConversion} disabled={conversionLoading}>
                ReRun HLS Conversion
              </StyledHLSButton>
            )}
            <FormGroupItem>
              <InputGroup>
                <StyledFlex>
                  <Title>{`${t('actions.upload_content')}*`}</Title>
                  <Uploader
                    // label={`${t('actions.upload_content')}*`}
                    // accept="video/*"
                    onFileChange={handleContentChange}
                    deleteFile={deleteContentFile}
                    type={fileTypes as FileTypes}
                    accept={combinedConstants}
                    inputValue={
                      (values[fileTypes as keyof IFormData] &&
                        values[fileTypes as keyof IFormData].name) ||
                      ''
                    }
                    onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setFieldValue(
                        fileTypes === 'video' ? 'video.title' : `${fileTypes}.name`,
                        e.target.value,
                      )
                    }
                    disabled={!values[fileTypes as keyof IFormData]}
                    uploadedFile={values[fileTypes as keyof IFormData]}
                    fileExtension={fileExtension}
                    loading={
                      ['video', 'audio'].includes(fileTypes)
                        ? uploadVideoProgress > 0
                          ? uploadVideoProgress
                          : false
                        : fileLoading === 'content'
                    }
                    id='content'
                  />
                </StyledFlex>
              </InputGroup>
            </FormGroupItem>
            <FormGroupItem>
              <InputGroup style={GapStyles}>
                {values.video && (
                  <StyledColumnFlexContainer>
                    <Title>{t('actions.add_subtitle')}</Title>
                    <Uploader
                      // label={t('actions.add_subtitle')}
                      // accept="video/*"
                      onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        handleFileChange(e, 'subtitle')
                      }
                      deleteFile={(): void => {
                        setFieldValue('subtitle', null)
                      }}
                      type={FileTypes.FILE}
                      inputValue={(values.subtitle && values.subtitle.name) || ''}
                      onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        setFieldValue('subtitle.name', e.target.value)
                      }
                      disabled={!values.subtitle}
                      uploadedFile={values.subtitle}
                      accept='.vtt, .srt'
                      fileExtension={(values.subtitle && values.subtitle.fileType) || ''}
                      loading={fileLoading === 'subtitle'}
                      id='subtitle'
                    />
                  </StyledColumnFlexContainer>
                )}

                {(values.video || values.contentDocument) && (
                  <StyledColumnFlexContainer>
                    <Title>{t('actions.add_thumbnail')}</Title>

                    <Uploader
                      // label={t('actions.add_thumbnail')}
                      onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        handleImageChange(e, 'thumbnail')
                      }
                      deleteFile={(): void => {
                        setFieldValue('thumbnail', null)
                      }}
                      type={FileTypes.IMAGE}
                      inputValue={(values.thumbnail && values.thumbnail.name) || ''}
                      onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        setFieldValue('thumbnail', e.target.value)
                      }
                      disabled={!values.thumbnail}
                      uploadedFile={values.thumbnail}
                      fileExtension={(values.thumbnail && values.thumbnail.fileType) || ''}
                      accept='image/*'
                      loading={!!imageLoading}
                      id='thumbnail'
                    />
                  </StyledColumnFlexContainer>
                )}
              </InputGroup>
            </FormGroupItem>
            <FormGroupItem>
              <StyledColumnFlexContainer>
                <Title>{t('lesson_details.resource')}</Title>
                <Uploader
                  accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain'
                  onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    handleFileChange(e, 'attachment')
                  }
                  deleteFile={(): void => {
                    setFieldValue('attachment', null)
                  }}
                  type={FileTypes.FILE}
                  inputValue={(values.attachment && values.attachment.name) || ''}
                  onInputChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={(values.attachment && values.attachment.fileType) || ''}
                  loading={fileLoading === 'attachment'}
                  id='attachment'
                />
              </StyledColumnFlexContainer>
            </FormGroupItem>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                textColor='#06C68F'
                background='#f5f5f5'
                outline='1px solid #06C68F'
              />
              <Button
                className='save-button'
                text={t('actions.save')}
                type='small'
                color='secondary'
                onClick={handleSubmit}
                isDisabled={submitDisabled}
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditLessonDrawer
