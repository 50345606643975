import React from 'react'

// hooks
import { useTranslation } from 'react-i18next'

// components
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { ISkillTestVideoReviewCriteria } from '../skillTestVideoCriteria.interface'
import {
  CheckedAt,
  ContentContainer,
  CriteriaContainer,
  CriteriaTitle,
  HeaderContainer,
  ViewSkillTestVideoCriteriasContainer,
} from './styled-components'

// icons
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { CriteriaItem } from '../styled-components'
import moment from 'moment'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const ViewSkillTestVideoCriteriasDrawer = ({
  title,
  criterias,
  onClose,
}: {
  title: string
  criterias: ISkillTestVideoReviewCriteria[]
  onClose: () => void
}): React.JSX.Element => {
  // hooks
  const { t } = useTranslation()

  if (!criterias)
    return (
      <ViewSkillTestVideoCriteriasContainer>
        <CustomDrawerHeader title={title} onClose={onClose} />
        <DrawerContent headerHeight={68}>
          <div>No criterias found</div>
        </DrawerContent>
      </ViewSkillTestVideoCriteriasContainer>
    )

  return (
    <ViewSkillTestVideoCriteriasContainer>
      <CustomDrawerHeader title={title} onClose={onClose} />
      <DrawerContent headerHeight={68}>
        <HeaderContainer>{t('skillTestVideoCriteria_layout.covered')}</HeaderContainer>
        <ContentContainer>
          {criterias.map(skillTestVideoCriteria => {
            const { criteriaId: id, criteria, checked, checkedAt } = skillTestVideoCriteria
            return (
              <CriteriaItem key={id}>
                {checked ? <img src='/img/check.svg' /> : <HighlightOffRoundedIcon />}
                <CriteriaContainer>
                  {checkedAt && <CheckedAt>{moment(checkedAt * 1000).format('mm:ss')}</CheckedAt>}
                  <CriteriaTitle checked={checked || false}>{criteria}</CriteriaTitle>
                </CriteriaContainer>
              </CriteriaItem>
            )
          })}
        </ContentContainer>
      </DrawerContent>
    </ViewSkillTestVideoCriteriasContainer>
  )
}

export default ViewSkillTestVideoCriteriasDrawer
