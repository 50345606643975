import React, { useState, useEffect } from 'react'
import { useFetchCompany } from 'hooks/companies/useCompanies'
import { useUserValue } from 'context/UserContext'
import { SET_SELECTED_COMPANY } from 'store/types'
import { useTranslation } from 'react-i18next'

import CompanyInformation from './CompanyInformation'
import CompanyChart from './CompanyChart'
import CompanyTabs from './CompanyTabs'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { useParams } from 'react-router-dom'
import { ICompanyDetailsTabFilterVariables, ICompanyVariablesFilterCompanyId } from './types'

const CompanyDetails = (): React.JSX.Element | undefined => {
  const { t } = useTranslation()
  const [, dispatch] = useUserValue()
  const { id: companyId } = useParams<{
    id: string
  }>()

  const initialVariables = {
    filter: {
      companyId: {
        type: 'EXACT',
        value: companyId,
      },
      isBought: {
        type: 'EXACT',
        value: true,
      },
    },
  }

  if (!companyId) return

  const { company, loading } = useFetchCompany(companyId)
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [variables, setVariables] = useState<
    ICompanyDetailsTabFilterVariables | ICompanyVariablesFilterCompanyId
  >(initialVariables)
  const [currentPage, setCurrentPage] = useState<number>(1)

  useEffect(() => {
    if (company && company.id) {
      const companyObj = {
        id: company.id,
        name: company.name,
      }
      dispatch({
        type: SET_SELECTED_COMPANY,
        payload: companyObj,
      })

      setSelectedCompanyInLocalStorage(companyObj)
    }
  }, [company])

  const handleTabChange = (newValue: number | string): void => {
    setCurrentTab(newValue as number)
    setCurrentPage(1)
    if (newValue === 0) {
      setVariables({
        ...initialVariables,
      })
    } else if (newValue === 3 || newValue === 1 || newValue === 7) {
      setVariables({
        companyId,
      })
    } else if (newValue === 2) {
      setVariables({
        ...initialVariables,
        filter: {
          ...initialVariables.filter,
          isBought: {
            type: 'EXACT',
            value: false,
          },
        },
      })
    } else if (newValue === 4) {
      setVariables({
        role: 'SUPER_ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    } else if (newValue === 5) {
      setVariables({
        role: 'ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    } else if (newValue === 6) {
      setVariables({
        filter: {
          company: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    }
  }

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <CompanyInformation company={company} t={t} />
      <CompanyChart company={company} handleTabChange={handleTabChange} t={t} />
      <CompanyTabs
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        variables={variables}
        addCoachIds={(): void => undefined}
        defCurrentPage={currentPage}
        company={company}
        t={t}
      />
    </div>
  )
}
export default CompanyDetails
