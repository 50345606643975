import React, { ChangeEvent, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IGroup } from 'interfaces/groups'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { debounce } from 'lodash'
import { useFetchTestAssessmentsByGroup } from 'services/tests/GetTestAssessmentsByCompanyIdService'
import { GetTestsByGroupFiltersByEnums } from 'services/tests/getTestsService.interface'
import { Button } from 'components/common/Button'
import { DrawerEventEmitter } from 'helpers/drawer'
import AddCircleIcon from '@mui/icons-material/Add'
import { useRemoveTestsFromGroupService } from 'services/tests/removeTestFromGroupService'
import { useSwal } from 'hooks/useSwal'
import { IUseGroupTestAssessmentsReturn } from './useGroupTestAssessment.interface'

export const useGroupTestAssessments = ({
  group,
}: {
  group: IGroup
}): IUseGroupTestAssessmentsReturn => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(12)
  const [searchText, setSearchText] = useState('')
  const { fireSwal } = useSwal()
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()
  const defaultFilter = group.testAssessments?.length
    ? {
        _id: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: group.testAssessments?.length ? group.testAssessments : null,
        },
      }
    : {}

  const { tests, totalPages, loading, refetch } = useFetchTestAssessmentsByGroup({
    searchValue: '',
    filterBy: GetTestsByGroupFiltersByEnums.IN_GROUP,
    groupId: group.id,
    currentPage,
    perPage,
  })

  const { removeTestsFromGroup } = useRemoveTestsFromGroupService({ groupId: group.id })

  const handleDeleteTestFromGroup = async (testId: string): Promise<void> => {
    fireSwal({
      title: t('popups.delete_test_from_group'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: async () => {
        await removeTestsFromGroup({ testIds: [testId], groupId: group.id })
        await refetch()
        if (tests.length === 1 && currentPage > 1) {
          const newPage = currentPage - 1
          setCurrentPage(newPage)
          refetch({
            currentPage: newPage,
            perPage,
            companyId: group?.company?.id,
            searchValue,
            filter: defaultFilter,
          })
        }
      },
    })
  }

  const openAddDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addTestToGroup', true, {
      groupData: group,
      onTestAdded: refetch,
    })
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('tests_layout.new_test')}
      color='secondary'
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background='#06C68F'
    />
  )

  const handlePaginationClick = (_: ChangeEvent<unknown> | null, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
      refetch({
        currentPage: value,
        perPage,
        companyId: group?.company?.id,
        searchValue,
        filter: defaultFilter,
      })
    }
  }

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    setCurrentPage(1)
    const nameFilter = {
      name: {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      },
    }
    refetch({
      currentPage: 1,
      perPage,
      companyId: group?.company?.id,
      searchValue: searchText,
      filter: {
        ...defaultFilter,
        ...nameFilter,
      },
    })
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const nameFilter = {
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value,
        },
      }
      refetch({
        currentPage: 1,
        perPage,
        companyId: group?.company?.id,
        searchValue: value,
        filter: {
          ...defaultFilter,
          ...nameFilter,
        },
      })
      setIsSearchType(value !== '')
    }, 500),
    [defaultFilter, perPage, group, refetch],
  )

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setSearchText(value)
    setSearchValue(value)
    setCurrentPage(1)
    debouncedSearch(value)
    setIsSearchType(true)
  }

  return {
    t,
    searchText,
    onSearchChange,
    tests,
    handleDeleteTestFromGroup,
    navigate,
    loading,
    handlePaginationClick,
    isSearchType,
    searchValue,
    createButton,
    initialLoad,
    onSearchSubmit,
    totalPages,
    currentPage,
  }
}

export default useGroupTestAssessments
