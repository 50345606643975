import React, { ReactElement, useEffect } from 'react'
import { TFunction } from 'interfaces/TFunction'

import { activeTabClassName, TabNavigation, TabPanel } from 'components/common/Tabs/Tabs'
import CourseSkillTestVideos from '../../CourseSkillTestVideos/CourseSkillTestVideos'
import CourseQuizzes from '../../CourseQuizzes'
import { AppBar, Tab, Tabs } from '@mui/material'
import { ModulesTabWrapper } from './styled-components'
import { useQuery } from '@apollo/client'
import { GET_GROUPS_BY_IDS } from 'gql/group/group.query'
import CourseStudents from '../CourseStudents/CourseStudents'

interface Props {
  courseId: string
  groupId?: string
  groupIds?: string[]
  t: TFunction<'translation', undefined>
  setNumberOfStudents?: React.Dispatch<React.SetStateAction<string>>
  tabValue: number
  handleTabChange: (event: React.ChangeEvent<object>, newValue: number) => void
  companyId: string
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
    marginTop: 20,
  },
  btn: {
    color: '#000',
    maxWidth: 'max-content',
  },
}

const GroupProgressTab = ({
  courseId,
  groupId,
  groupIds,
  t,
  setNumberOfStudents,
  tabValue,
  handleTabChange,
  companyId,
}: Props): ReactElement => {
  const { data: groupsData } = useQuery(GET_GROUPS_BY_IDS, {
    variables: {
      ids: groupId ? [groupId] : groupIds,
    },
  })

  useEffect(() => {
    if (groupsData?.getGroupsByIds?.numberOfStudents) {
      const numberOfStudents: number = groupsData.getGroupsByIds.numberOfStudents
      if (setNumberOfStudents) setNumberOfStudents(`${numberOfStudents}`)
    }
  }, [groupsData])

  return (
    <ModulesTabWrapper $withoutFlex={true}>
      <TabNavigation>
        <AppBar position='static' style={styles.bar}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label='simple tabs example'>
            {courseId && groupsData?.getGroupsByIds && (
              <Tab
                label={t('general.students')}
                {...activeTabClassName(1)}
                style={{
                  textTransform: 'capitalize',
                  color: '#000',
                  maxWidth: 'max-content',
                }}
              />
            )}
            {courseId && groupsData?.getGroupsByIds && (
              <Tab
                label={t('general.skill_test_videos')}
                {...activeTabClassName(2)}
                style={{
                  textTransform: 'capitalize',
                  color: '#000',
                  maxWidth: 'max-content',
                }}
              />
            )}
            {courseId && groupsData?.getGroupsByIds && (
              <Tab
                label={t('general.quizzes')}
                {...activeTabClassName(3)}
                style={{
                  textTransform: 'capitalize',
                  color: '#000',
                  maxWidth: 'max-content',
                }}
              />
            )}
            {courseId && groupsData?.getGroupsByIds && (
              <Tab
                label={t('general.iq_tests')}
                {...activeTabClassName(4)}
                style={{
                  textTransform: 'capitalize',
                  color: '#000',
                  maxWidth: 'max-content',
                }}
              />
            )}
          </Tabs>
        </AppBar>
        {courseId && groupsData && (
          <TabPanel value={tabValue} index={0} padding={0}>
            <CourseStudents courseId={courseId} groupId={groupId} groupIds={groupIds} />
          </TabPanel>
        )}
        {courseId && groupsData && (
          <TabPanel value={tabValue} index={1} padding={0}>
            <CourseSkillTestVideos companyId={companyId} courseId={courseId} groupId={groupId} />
          </TabPanel>
        )}
        {courseId && groupsData && (
          <TabPanel value={tabValue} index={2} padding={0}>
            <CourseQuizzes type='quiz' groupId={groupId} courseId={courseId} groupsIds={groupIds} />
          </TabPanel>
        )}
        {courseId && groupsData && (
          <TabPanel value={tabValue} index={3} padding={0}>
            <CourseQuizzes
              type='iq_test'
              groupId={groupId}
              courseId={courseId}
              groupsIds={groupIds}
            />
          </TabPanel>
        )}
      </TabNavigation>
    </ModulesTabWrapper>
  )
}

export default GroupProgressTab
