import { useMutation } from '@apollo/client'
import {
  IExportTestAssessmentsScoreServiceParams,
  IExportTestAssessmentsScoreServiceResponse,
  IExportTestAssessmentsScoreServiceReturn,
} from './exportData.interface'
import { EXPORT_TEST_ASSESSMENTS_SCORE } from 'gql/analytics/exportData.query'

export const exportTestAssessmentsScoreService = (): IExportTestAssessmentsScoreServiceReturn => {
  const [exportTestAssessmentsScore, { loading, error }] = useMutation<
    IExportTestAssessmentsScoreServiceResponse,
    IExportTestAssessmentsScoreServiceParams
  >(EXPORT_TEST_ASSESSMENTS_SCORE)

  return {
    exportTestAssessmentsScore: async (testAssessmentId: string): Promise<string | null> => {
      const response = await exportTestAssessmentsScore({ variables: { testAssessmentId } })
      return response.data?.exportTestAssessmentsScore.link || null
    },
    loading,
    error: error?.message,
  }
}
