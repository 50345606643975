import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAddTestToGroupService } from 'services/tests/addTestToGroupService'
import {
  GetTestsByGroupFiltersByEnums,
  ITestToGroupDto,
} from 'services/tests/getTestsService.interface'
import { useFetchTestAssessmentsByGroup } from 'services/tests/GetTestAssessmentsByCompanyIdService'
import {
  IGroupTestAssessmentProps,
  IUseAddTestToGroupReturnType,
} from './useAddTestToGroup.interface'

const useAddTestToGroup = ({
  groupData,
  closeDrawer,
  onTestAdded,
}: IGroupTestAssessmentProps): IUseAddTestToGroupReturnType => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const groupId = groupData.id

  const { addTest } = useAddTestToGroupService({ groupId })

  const handleButtonClick = async (): Promise<void> => {
    if (selectedItems.length > 0) {
      const testToGroupData: ITestToGroupDto = {
        testIds: selectedItems,
        groupId: groupData.id,
      }
      await addTest(testToGroupData)
      if (onTestAdded) {
        onTestAdded()
      }
      closeDrawer()
    }
  }

  const { tests, refetch } = useFetchTestAssessmentsByGroup({
    searchValue: '',
    filterBy: GetTestsByGroupFiltersByEnums.NOT_IN_GROUP,
    groupId: groupData.id,
  })

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      refetch({
        searchValue: newSearchValue,
        groupId,
      })
    }, 500),
    [groupId, refetch],
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.target.value
      setSearchValue(newSearchValue)
      debouncedRefetch(newSearchValue)
    },
    [debouncedRefetch],
  )

  const selectItem = (id: string): void => {
    const newSelected = selectedItems.includes(id)
      ? selectedItems.filter(itemId => itemId !== id)
      : [...selectedItems, id]

    // Update the state with the new selection
    setSelectedItems(newSelected)
    setIsAllSelected(newSelected.length === tests.length)
  }

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setIsSearchType(true)
    refetch({
      searchValue,
      groupId,
    })
  }

  const selectAllItem = (): void => {
    if (!isAllSelected) {
      setSelectedItems(tests.map(test => test.id).filter((id): id is string => id !== undefined))
    } else {
      setSelectedItems([])
    }
    setIsAllSelected(!isAllSelected)
  }

  const handleSelectItem = (id: string): void => {
    let updatedSelectedItems = []
    if (selectedItems.includes(id)) {
      updatedSelectedItems = selectedItems.filter(item => item !== id)
    } else {
      updatedSelectedItems = [...selectedItems, id]
    }
    setSelectedItems(updatedSelectedItems)
    setIsAllSelected(updatedSelectedItems.length === tests.length)
  }

  return {
    t,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    selectAllItem,
    isAllSelected,
    tests,
    handleButtonClick,
    selectedItems,
    handleSelectItem,
    selectItem,
    isSearchType,
    setIsSearchType,
  }
}

export default useAddTestToGroup
