import useSnackbarAlert from 'hooks/useSnackbar'
import { ILesson } from 'interfaces/modules'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import editLessonService, { IEditLessonServiceVariables } from 'services/lessons/editLessonService'

const useEditLessonService = (
  params: IEditLessonServiceVariables,
): {
  loading: boolean
  editLesson: (topicId: string, lessonId: string, input: ILesson, fun?: () => void) => void
} => {
  const { t } = useTranslation()
  const { editLessonMutation, loading } = editLessonService(params)
  const { setSnackbar } = useSnackbarAlert()

  const editLesson = async (
    topicId: string,
    lessonId: string,
    input: ILesson,
    fun?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editLessonMutation({
      variables: {
        topicId,
        lessonId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    fun && fun()
  }

  return {
    loading,
    editLesson,
  }
}

export default useEditLessonService
