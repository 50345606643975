import React, { ReactElement } from 'react'
import {
  Subtitles,
  InfoWrapper,
  TitleContainer,
  Tracker,
  StyledFlex,
  ProfileBackground,
  DetailsHeader,
  InformationBackground,
} from './styled-components'
import { t, TFunction } from 'i18next'
import { useQuery } from '@apollo/client'
import { GET_USER_ACTIVITIES } from 'gql/activity/activity.query'
import { useParams } from 'react-router-dom'
import { IUser } from 'interfaces/users'
import TimeLine from '../../activityV2/Timeline'
import { ActivityIndicator } from './activity.interface'

export const ActivityComponent = (): ReactElement => {
  const { id } = useParams<{ id: string }>()
  const { data } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      userId: id,
    },
  })
  const userActivities = (data && data.getUserActivities) || []
  const user = userActivities && userActivities.user

  const renderIndicator = (indicator: string): ReactElement => {
    switch (indicator) {
      case ActivityIndicator.PROGRESS:
        return <InfoWrapper className='progress'>{t('activity.progress')} ↑</InfoWrapper>
      case ActivityIndicator.STABLE:
        return <InfoWrapper className='stable'>{t('activity.stable')} - </InfoWrapper>
      case ActivityIndicator.REGRESS:
        return <InfoWrapper className='regress'>{t('activity.regress')} ↓</InfoWrapper>
      default:
        return <InfoWrapper className='stable'>{t('activity.stable')} - </InfoWrapper>
    }
  }

  const getLeaderboardInfo = (
    user: IUser,
    type: 'companyPosition' | 'globalPosition' | 'indicator',
    t: TFunction,
  ): string | React.JSX.Element => {
    if (!user || !user.leaderboard) return t('n/a')

    switch (type) {
      case 'companyPosition':
        return user.leaderboard.companyPosition ?? t('n/a')
      case 'globalPosition':
        return user.leaderboard.globalPosition ?? t('n/a')
      case 'indicator':
        return renderIndicator(user.leaderboard.indicator ?? '')
      default:
        return t('n/a')
    }
  }

  return (
    <StyledFlex>
      <ProfileBackground>
        <DetailsHeader>{t('general.leaderboard')}</DetailsHeader>
        <Subtitles $hasLargeTopPadding={true}>
          <TitleContainer>{t('user_details.company_leaderboard')}</TitleContainer>
          <InfoWrapper>{getLeaderboardInfo(user, 'companyPosition', t)}</InfoWrapper>
        </Subtitles>
        <Subtitles>
          <TitleContainer>{t('user_details.global_leaderboard')}</TitleContainer>
          <InfoWrapper>{getLeaderboardInfo(user, 'globalPosition', t)}</InfoWrapper>
        </Subtitles>
        <Subtitles>
          <TitleContainer>{t('user_details.current_status')}</TitleContainer>
          {getLeaderboardInfo(user, 'indicator', t)}
        </Subtitles>
      </ProfileBackground>
      <InformationBackground>
        <DetailsHeader>{t('actions.activity')}</DetailsHeader>
        <Tracker>
          <TimeLine list={userActivities.activity} />
        </Tracker>
      </InformationBackground>
    </StyledFlex>
  )
}
