import React from 'react'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { AsyncSelect } from 'components/common/SelectField'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { IAsyncSelectOption } from 'components/common/SelectField/select.interface'
import useStudentForm from 'hooks/useStudentsForm/useStudentsForm'
import { role } from 'hooks/useStudentsForm/useStudentsForm.interface'
import { ISelectFilterOption } from 'interfaces/common'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const EditStudent = ({ id, role }: { id?: string; role: role }): JSX.Element => {
  const {
    loading,
    handleChange,
    handleCloseEditDrawer,
    handleSelectChange,
    handleSubmit,
    t,
    touched,
    values,
    setFieldValue,
    errors,
    groupLoading,
    groupData,
    onDateChange,
    generalError,
  } = useStudentForm({ id, role })
  return (
    <>
      {loading && <Loader />}
      <CustomDrawerHeader
        title={`${t('actions.edit')} ${t('general.student')}`}
        onClose={handleCloseEditDrawer}
      />
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.first_name')}
                  required
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size='small'
                  placeholder={t('user_details.first_name')}
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.last_name')}
                  required
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size='small'
                  placeholder={t('user_details.last_name')}
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={errors.email || generalError ? errors.email || generalError : ''}
                  touched={touched.email}
                  title={t('user_details.email')}
                  required
                  size='small'
                  placeholder={t('user_details.email')}
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields || null}
                  selectChange={(e: ISelectFilterOption | null): void =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel='Phone'
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: string): void => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  // title={t('user_details.birthday')}
                  // label={t('user_details.birthday')}
                  touched={!!touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate as string | undefined}
                  name='birthDate'
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>

              <FormField style={{ position: 'relative' }}>
                <Title $required>{t('groups_layout.select_group')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={handleSelectChange}
                  onChange={(e: IAsyncSelectOption): void => setFieldValue('group', e)}
                  data={groupData?.getAllGroups?.data || []}
                  value={values.group}
                  label={t('groups_layout.select_group')}
                  loading={groupLoading}
                  labelDataKey='name'
                  valueDataKey='id'
                  error={errors.group as string}
                  errorMessage={errors.group as string}
                  touched={!!touched.group}
                />
              </FormField>

              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size='small'
                  placeholder={`${t('form_fields.job_title')}`}
                  name='jobTitle'
                  type='text'
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size='small'
                  placeholder={`${t('form_fields.location')}`}
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  placeholder={`${t('quiz_details.type_here')}`}
                  size='small'
                  name='biography'
                  type='text'
                  multiline
                  rows='3'
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.note')}`}
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  placeholder={`${t('user_details.note')}`}
                  size='small'
                  name='note'
                  type='text'
                  multiline
                  rows='3'
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseEditDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditStudent
