import React, { ChangeEvent } from 'react'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { Button } from 'components/common/Button'
import GeneralInformation from './GeneralInformation'
import { ICompaniesEditProps } from './companiesEdit.interface'
import { useCompaniesEdit } from 'hooks/companies/useEditCompany'
import { ImageCropper, Modal } from 'components/common'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const CompaniesEdit = ({ companyId }: ICompaniesEditProps): JSX.Element => {
  const {
    fetched,
    handleCloseDrawer,
    handleSubmit,
    handleValueChange,
    values,
    setFieldValue,
    errors,
    handleImageChange,
    deleteImage,
    imageLoading,
    file,
    imageType,
    cropperOpen,
    handleCropSave,
    cropperModalToggle,
    loading,
    generalError,
    t,
    touched,
  } = useCompaniesEdit({ companyId })
  return (
    <>
      {!fetched && <Loader withBackground={true} />}
      <CustomDrawerHeader title={t('companies_layout.edit_company')} onClose={handleCloseDrawer} />

      <FormContainer
        onSubmit={(e: ChangeEvent<HTMLFormElement>): void => e.preventDefault()}
        headerHeight='65px'
      >
        <FormGroupWrapper>
          <GeneralInformation
            values={values}
            errors={errors}
            generalError={generalError}
            touched={touched}
            handleChange={handleValueChange}
            setFieldValue={setFieldValue}
            handleImageChange={handleImageChange}
            deleteImage={deleteImage}
            imageLoading={imageLoading}
            t={t}
          />
        </FormGroupWrapper>
        <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
          <ImageCropper
            file={file}
            hideCropper={cropperModalToggle}
            getCroppedFile={(_: unknown, file): void => handleCropSave(imageType, file)}
          />
        </Modal>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.cancel')}
              type='small'
              onClick={handleCloseDrawer}
              background='#E0E1E2'
              textColor='#414141'
            />
            <Button
              text={t('actions.save')}
              type='small'
              color='secondary'
              onClick={handleSubmit}
              isDisabled={loading}
              background='#06C68F'
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </>
  )
}

export default CompaniesEdit
