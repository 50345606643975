import React from 'react'
import {
  Wrapper,
  ColorInputContainer,
  ErrorMessage,
  ModalContainer,
  ModalContent,
  ModalButtons,
  UploadInput,
  Label,
  InputLabel,
  ImagePlaceholder,
  CloseIcon,
  ColorPlaceholder,
  AudioUploader,
} from '../AddFlashCardDrawer/styled-components'
import CancelIcon from '@mui/icons-material/Cancel'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import Uploader from 'components/common/FileUploader/Uploader'
import Modal from 'components/common/Modal'
import ColorPicker from 'components/common/ColorPicker'
import ImagePicker from 'components/common/ImagePicker'
import Preview from '../Preview'
import { Images, IFlashCardDrawerParams } from 'pages/modules/FlashCards/interfaces'
import { FlashCardsIconsTabs } from '../staticData'
import useEditFlashCardDrawer from 'hooks/modules/EditFlashCardDrawer/useEditFlashCardDrawer'
import { IAudio } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const EditFlashCardDrawer = ({ onClose, data }: IFlashCardDrawerParams): React.JSX.Element => {
  const {
    loading,
    editFlashCardLoading,
    t,
    handleCloseDrawer,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    uploadedImage,
    setOpenedModal,
    imageLoading,
    setImage,
    setUploadedImage,
    handleImageChange,
    handleAudioChange,
    setAudioProgress,
    audioProgress,
    openedModal,
    handleModalClose,
    color,
    setColor,
    currentTab,
    images,
    handleTabChange,
    image,
    handleModalSubmit,
  } = useEditFlashCardDrawer({ onClose, data })

  return (
    <>
      {loading && <Loader withBackground />}
      {editFlashCardLoading && <Loader />}
      <CustomDrawerHeader
        title={t('flashcards_layout.edit_flashcard')}
        onClose={handleCloseDrawer}
      />
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  title={`${t('flashcards_layout.question')}*`}
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  placeholder={t('flashcards_layout.name')}
                  size='small'
                  name='question'
                  type='text'
                  value={values.question}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  maxCharacter={50}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('flashcards_layout.answer')}*`}
                  error={errors.answer ? true : false}
                  errorMessage={errors.answer ? errors.answer : ''}
                  touched={touched.answer}
                  placeholder={t('flashcards_layout.type_answer_here')}
                  size='small'
                  name='answer'
                  type='text'
                  value={values.answer}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </FormField>
              <FormField width='30%'>
                <Title>{t('actions.upload_cover_image')}*</Title>
                <ColorInputContainer className={`${errors.image && touched.image ? 'error' : ''}`}>
                  <Label className={`${errors.image && touched.image ? 'error' : ''}`}></Label>
                  <Wrapper>
                    {values.image && !uploadedImage ? (
                      <div style={{ position: 'relative', width: '50% ' }}>
                        <ImagePlaceholder>
                          <img src={values.image} />
                          <CloseIcon
                            onClick={(): void => {
                              setFieldValue('image', '')
                            }}
                          >
                            <CancelIcon color='secondary' />
                          </CloseIcon>
                        </ImagePlaceholder>
                      </div>
                    ) : (
                      <Button
                        text={t('actions.pick')}
                        size='small'
                        onClick={(): void => setOpenedModal('image')}
                        isDisabled={imageLoading || uploadedImage ? true : false}
                        background='#06C68F'
                      />
                    )}
                    <div style={{ position: 'relative', width: '50% ' }}>
                      {imageLoading && <Loader width={20} height={20} />}
                      {uploadedImage ? (
                        <ImagePlaceholder>
                          <img src={uploadedImage} />
                          <CloseIcon
                            onClick={(): void => {
                              setImage('')
                              setUploadedImage('')
                              setFieldValue('image', '')
                            }}
                          >
                            <CancelIcon color='secondary' />
                          </CloseIcon>
                        </ImagePlaceholder>
                      ) : (
                        <>
                          <UploadInput
                            type='file'
                            id='image'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                              handleImageChange(e)
                            }
                            disabled={values.image && !uploadedImage ? true : false}
                          />
                          <InputLabel
                            htmlFor='image'
                            className={`${values.image && !uploadedImage ? 'disabled' : ''}`}
                          >
                            {t('general.upload')}
                          </InputLabel>
                        </>
                      )}
                    </div>
                  </Wrapper>
                  {errors.image && touched.image && <ErrorMessage>{errors.image}</ErrorMessage>}
                </ColorInputContainer>
              </FormField>
              <FormField width='30%'>
                <Title>{t('actions.upload_audio_file')}</Title>
                <AudioUploader>
                  <Uploader
                    accept='.mp3, .m4a, .wav'
                    onFileChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleAudioChange(e)
                    }
                    deleteFile={(): void => {
                      setFieldValue('audio', '')
                      setAudioProgress(0)
                    }}
                    type={FileTypes.AUDIO}
                    inputValue={(values.audio as IAudio)?.name}
                    onInputChange={(): void => undefined}
                    disabled
                    uploadedFile={values.audio as IAudio}
                    fileExtension={(values.audio as IAudio)?.fileType}
                    loading={audioProgress ? audioProgress : false}
                    id='audio'
                  />
                </AudioUploader>
              </FormField>
              <FormField width='30%'>
                <Title>{t('actions.pick_color')}*</Title>
                <ColorInputContainer className={`${errors.color && touched.color ? 'error' : ''}`}>
                  <Label className={`${errors.color && touched.color ? 'error' : ''}`}></Label>
                  {values.color ? (
                    <ColorPlaceholder
                      background={values.color}
                      onClick={(): void => setOpenedModal('color')}
                    />
                  ) : (
                    <Button
                      text={t('actions.pick')}
                      size='small'
                      onClick={(): void => setOpenedModal('color')}
                      background='#06C68F'
                    />
                  )}
                  {errors.color && touched.color && <ErrorMessage>{errors.color}</ErrorMessage>}
                </ColorInputContainer>
              </FormField>
            </FormFields>
            <Preview
              backgroundColor={values.color}
              question={values.question}
              image={values.image}
              audio={values.audio as IAudio}
              answer={values.answer}
              t={t}
            />
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={openedModal ? true : false} onClose={handleModalClose}>
        <ModalContainer>
          <ModalContent>
            {openedModal === 'color' && (
              <ColorPicker
                selectedColor={color ? color : values.color}
                setSelectedColor={setColor}
              />
            )}
            {openedModal === 'image' && (
              <ImagePicker
                images={images[currentTab as keyof Images]}
                selectedImage={image}
                setSelectedImage={setImage}
                tabs={{
                  withTabs: true,
                  currentTab,
                  handleTabChange,
                  tabsList: FlashCardsIconsTabs,
                }}
              />
            )}
          </ModalContent>
          <ModalButtons>
            <Button
              text={t('actions.cancel')}
              size='small'
              color='secondary'
              onClick={handleModalClose}
              background='#E0E1E2'
              textColor='#414141'
            />
            <Button
              text={t('actions.select')}
              size='small'
              isDisabled={openedModal === 'image' ? !image : !color}
              onClick={handleModalSubmit}
              background='#06C68F'
            />
          </ModalButtons>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default EditFlashCardDrawer
