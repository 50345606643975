import React from 'react'
import { ReactComponent as HorizontalSVG } from 'assets/horizontal-view.svg'
import { ReactComponent as GridSVG } from 'assets/grid-view.svg'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Player from 'components/common/VideoPlayer/Player'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import {
  ModalRef,
  ToolbarItemWrapper,
  ContentWrapper,
  Root,
  toolbarStyle,
  styles,
  stylesOut,
  StyledPagination,
} from './styled-components'
import { Grid } from 'components/common/Cards/styled-components'
import { ReactComponent as FilterAltOffIcon } from 'assets/remove-filter.svg'
import CourseList from './CourseList'
import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { FilterOptionsType } from './FilterDrawer/filterDrawer.interface'
import Tooltip from '@mui/material/Tooltip'
import { useCourseLayout } from './hooks/useCourseLayout'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

export const initialValues: FilterOptionsType = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const CourseLayout = (): React.JSX.Element => {
  const {
    breadCrumbData,
    state,
    handleNewCourse,
    currentUser,
    currentCompany,
    isGridView,
    setIsGridView,
    filterDrawer,
    isFiltered,
    resetFilter,
    t,
    onSearchSubmit,
    searchText,
    handleSearchChange,
    handleTabChange,
    value,
    error,
    loading,
    courses,
    companyMode,
    isSearchType,
    createButton,
    actions,
    actionHandler,
    listActions,
    handlePaginationClick,
    filterIsOpen,
    filterData,
    setFilterData,
    refetch,
    setVideoModal,
    setCurrentPage,
    videoModal,
    setFilterIsOpen,
    perPage,
    handleChangeRowsPerPage,
  } = useCourseLayout()

  return (
    <Root>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>
      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createCourse && (
              <Button
                text={`${
                  state.selectedCompany?.id
                    ? t('courses_layout.create_course')
                    : t('courses_layout.create_suada_course')
                }`}
                color='secondary'
                icon={<AddCircleRoundedIcon />}
                onClick={handleNewCourse}
                isDisabled={!!currentUser.companyId && !currentCompany.platformService}
                background='#06C68F'
              />
            )}
          </div>

          <div className='wrapper'>
            <ToolbarItem>
              {isGridView ? (
                <IconButton onClick={(): void => setIsGridView(false)}>
                  <GridSVG />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsGridView(true)}>
                  <HorizontalSVG />
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem $noGap>
              <IconButton onClick={filterDrawer}>
                <Tooltip title={t('actions.filter')} arrow>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton onClick={resetFilter}>
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <FilterAltOffIcon />
                  </Tooltip>
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>
      <div>
        <TabNavigation transparent>
          <AppBar position='static' style={styles.bar}>
            <Tabs value={value} onChange={handleTabChange} aria-label='simple tabs example'>
              <Tab
                label={
                  state.currentUser.companyId || state.selectedCompany?.id
                    ? t('general.purchased_courses')
                    : t('general.suada_courses')
                }
                {...activeTabClassName(0)}
                style={styles.btn}
              />
              {(state.currentUser.companyId || state.selectedCompany?.id) &&
                state?.userPermission?.createCourse && (
                  <Tab
                    label={t('general.created_courses')}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                )}
            </Tabs>
          </AppBar>
        </TabNavigation>
      </div>
      <ContentWrapper $isGrid={isGridView}>
        {error && error}
        {loading ? (
          <MyLoader />
        ) : (
          <>
            {!loading &&
            courses &&
            companyMode &&
            searchText === '' &&
            !isSearchType &&
            !isFiltered &&
            courses?.data.length === 0 ? (
              value === 0 ? (
                <SplashScreen subTitle={t('courses_layout.no_purchased_courses')} />
              ) : (
                <SplashScreen
                  title={t('courses_layout.no_courses')}
                  subTitle={t('courses_layout.create_courses')}
                  createButton={createButton}
                />
              )
            ) : (
              <>
                {courses.data.length === 0 && (searchText !== '' || isFiltered) ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.try_adjusting_your_search_or_filter')}
                    textMarginTop='40px'
                    isSearchOrFilter={true}
                  />
                ) : (
                  <>
                    {courses.data.length > 0 ? (
                      <>
                        <Grid className={'card'} style={{ position: 'relative' }}>
                          <CourseList
                            isGridView={isGridView}
                            companyMode={!!companyMode}
                            activeTab={value}
                            state={state}
                            actionHandler={actionHandler}
                            data={courses?.data}
                            gridStyle={'card'}
                            withTooltips={false}
                            actions={actions}
                            courseListActions={listActions}
                          />
                        </Grid>

                        <StyledPagination>
                          {!isGridView && (
                            <PerPageDropdown
                              value={perPage}
                              onChange={(value): void =>
                                handleChangeRowsPerPage({
                                  target: {
                                    value: value.toString(),
                                  } as HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                              options={[10, 20, 50]}
                            />
                          )}
                          <PaginationV2
                            currentPage={courses ? courses.currentPage : 1}
                            totalPages={courses ? courses.totalPages : 0}
                            handleChange={handlePaginationClick}
                          />
                        </StyledPagination>
                      </>
                    ) : (
                      <MyLoader />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentWrapper>
      <Drawer opened={filterIsOpen} toggleDrawer={(): void => undefined} totalWidth='700px'>
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={(): void => setFilterIsOpen(false)}
          tabValue={value}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={stylesOut.modal}
        open={videoModal.modal}
        onClose={(): void => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ModalRef>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </ModalRef>
      </Modal>
    </Root>
  )
}

export default CourseLayout
