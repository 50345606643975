import { useQuery } from '@apollo/client'
import { GET_TAGS } from 'gql/skilss.query'
import { IGetTagsResponse, IUseFetchTags } from './getTasksService.interface'
import { TagCategoryEnums } from 'enums/tagCategoryEnums'

export const useFetchTagsByCompanyId = (
  companyId: string | null,
  category?: TagCategoryEnums | null,
): IUseFetchTags => {
  const { data, loading, error } = useQuery<IGetTagsResponse>(GET_TAGS, {
    variables: { companyId, category },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    data: data?.getAllTags,
    loading,
    error: errorMessage,
  }
}
