import { styled } from 'styled-components'

export const InfoWrapper = styled.div`
  padding-left: 32px;
  font-weight: 600;
  font-size: 16px;
`

export const Subtitle = styled.div`
  width: 140px;
  padding: 0px 16px 16px 16px;
  font-size: 16px;
  color: #68707a;
  font-weight: 400;
  line-height: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  flex-direction: row;
  padding-bottom: 5px;
  padding-top: 5px;
`
