import { useLazyQuery } from '@apollo/client'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_COMPANIES } from 'gql/companies.query'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ISuperAdminsFilterFormValues,
  ISuperAdminsFilterOptions,
  IUseFilterModeratorProps,
  IUseFilterModeratorReturnType,
} from '../filterModerators.interface'
import { FilterUserSchema } from 'helpers/validationSchemas'
import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

const useFilterModerator = ({
  filterOptions,
  setFilterOptions,
  filter,
}: IUseFilterModeratorProps): IUseFilterModeratorReturnType => {
  const { t } = useTranslation()
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()
  const [companyOptions, setCompanyOptions] = useState([])
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [state] = useUserValue()
  const isCompanySelected = Boolean(state?.selectedCompany?.id)

  const [fetchCompany, { data: companyData, loading: companyLoading }] = useLazyQuery(GET_COMPANIES)

  setFilterRole('SUPER_ADMINS')
  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: (filterOptions as ISuperAdminsFilterOptions) || {},
      validationSchema: FilterUserSchema,
      onSubmit(values) {
        const data: ISuperAdminsFilterOptions = { ...values }
        const formValues: ISuperAdminsFilterFormValues = {}

        if (values.firstName) {
          formValues.firstName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.firstName,
          }
        }

        if (values.lastName) {
          formValues.lastName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.lastName,
          }
        }

        if (values.note) {
          formValues.note = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.note,
          }
        }

        if (values.location) {
          formValues.location = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.location,
          }
        }

        if (data?.status?.value && data?.status?.value !== 'all') {
          formValues.status = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: data?.status?.value,
          }
        }

        if (values.company && values.company.length > 0) {
          const companyIds = values.company.map((i: { value: string | null }) =>
            i.value === '-1' ? (i.value = null) : i.value,
          )
          const query = { companyId: { $in: companyIds } }
          formValues.query = {
            type: LowerCaseFilterableFieldType.QUERY,
            value: JSON.stringify(query),
          }
        }

        if (values.age && values.age.length === 2 && values.age.some(item => item !== 0)) {
          formValues.age = {
            type: LowerCaseFilterableFieldType.RANGE,
            value: `${values.age[0]}-${values.age[1]}`,
          }
        }

        if (values?.gender?.value && values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        const safeCreateDate = createDate || [undefined, undefined]

        safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
        safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

        const isSameDate =
          safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

        if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

        const formattedData = isSameDate
          ? safeCreateDate[0]
          : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
              safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
            }`

        if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : LowerCaseFilterableFieldType.DATE_RANGE,
            value: formattedData as string,
          }
        }

        filter(formValues)
        setFilterOptions(values as IFilterQueryType)
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'SUPER_ADMINS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as ISuperAdminsFilterOptions)
    }
  }, [filterOptions, setValues])

  const loadDataOptions = (e: string, action: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e

      if (action === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, action: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e, action)
    }
  }

  const handleRangeChange = (range: number[] | number): void => {
    setFieldValue('age', range)
  }

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return {
    handleSubmit,
    t,
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    handleSelectChange,
    handleRangeChange,
    isCompanySelected,
    companyOptions,
    companyLoading,
    createDate,
    setCreateDate,
  }
}

export default useFilterModerator
