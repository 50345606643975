import React, { ReactElement, useState } from 'react'
import { StyledWrapper } from './styled-components'

import TestAssessmentsScoreExport from './TestAssessmentsScoreExport/TestAssessmentsScoreExport'
import GroupScoreExport from './GroupScoreExport/GroupScoreExport'
import { IExportAnalyticsProps } from './exportAnalytics.interface'
import MyLoader from 'loading/loading'

const ExportAnalytics = ({
  testOptions,
  testsLoading,
  companyId,
}: IExportAnalyticsProps): ReactElement => {
  const [loading, setLoading] = useState(false)
  return (
    <StyledWrapper>
      {loading && <MyLoader />}
      <TestAssessmentsScoreExport
        testOptions={testOptions}
        testsLoading={testsLoading}
        companyId={companyId}
        setLoading={setLoading}
      />
      <GroupScoreExport companyId={companyId} setLoading={setLoading} />
    </StyledWrapper>
  )
}

export default ExportAnalytics
