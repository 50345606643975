import React, { ReactElement, useEffect } from 'react'
import { useFormik } from 'formik'
import { SkilTestDetailsFieldTitle } from '../AddSkillTestVideoDrawer/styled-components'
import { SkillTestVideoSchema } from 'helpers/validationSchemas'
import useUpdateSkillTestVideoService from 'hooks/modules/useUpdateSkillTestVideo'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { ISkillTestVideo } from '../SkillTestVideos.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const initialValues = {
  title: '',
  description: '',
  tips: '',
}

export interface SkillTestVideoData {
  moduleId: string
  skillTestVideo: ISkillTestVideo
  refetch: () => void
}

const EditSkillTestVideoDrawer = ({
  onClose,
  data,
}: {
  onClose: () => void
  data: SkillTestVideoData
}): ReactElement => {
  const { updateSkillTestVideo, updateSkillTestVideoLoading } = useUpdateSkillTestVideoService({
    moduleId: data.moduleId,
  })
  const { skillTestVideo } = data
  const { handleSubmit, setValues, setFieldValue, values, handleChange, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: SkillTestVideoSchema,
      onSubmit(values) {
        updateSkillTestVideo(data.skillTestVideo.id!, values, () => {
          onClose()
          data.refetch()
        })
      },
    })

  useEffect(() => {
    if (skillTestVideo) {
      setValues({
        title: skillTestVideo.title,
        description: skillTestVideo.description || '',
        tips: skillTestVideo.tips || '',
      })
    }
  }, [skillTestVideo])

  return (
    <>
      {updateSkillTestVideoLoading && <Loader />}
      <CustomDrawerHeader title={'Edit Skill Test Video Template'} onClose={onClose} />
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  label='Title'
                  name='title'
                  type='text'
                  value={values.title}
                  onChange={handleChange}
                  error={errors.title ? true : false}
                  errorMessage={errors.title}
                  touched={touched.title}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <TextInput
                  label='Skill Test Video Overview'
                  name='description'
                  aria-label='minimum height'
                  multiline
                  rows='3'
                  value={values.description}
                  onChange={handleChange}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <SkilTestDetailsFieldTitle>Skill Test Video Tips</SkilTestDetailsFieldTitle>
                <TextEditor
                  text={skillTestVideo.tips}
                  editorTitle='Skill Test Video Overview Text'
                  handleChange={(value: string): void => {
                    setFieldValue('tips', value)
                  }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text='Cancel'
                type='small'
                onClick={onClose}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text='Save'
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditSkillTestVideoDrawer
