import { IModule } from 'pages/modules/ModulesList.interface'

export enum QuestionTypeEnums {
  BOOLEAN = 'boolean',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface ICreateQuestionProps {
  title: string
  currentModule: IModule
}
