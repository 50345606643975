import { useMutation } from '@apollo/client'
import { REMOVE_GROUPS_TO_TASK, GET_TASKS_BY_GROUP } from 'gql/tasks.query'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import {
  GetTasksByGroupFiltersByEnums,
  IRemoveGroupsFromTaskResponse,
  IFetchTasksByCompanyIdProps,
  IGroupsToTaskDto,
} from './getTasksService.interface'

export const useRemoveGroupsFromTaskService = ({
  taskId,
}: IFetchTasksByCompanyIdProps): IRemoveGroupsFromTaskResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [removeGroups, { data, error, loading }] = useMutation(REMOVE_GROUPS_TO_TASK, {
    refetchQueries: [
      {
        query: GET_TASKS_BY_GROUP,
        variables: {
          taskId,
          groupId: '',
          searchValue: '',
          currentPage: 1,
          filterBy: GetTasksByGroupFiltersByEnums.IN_GROUP,
          perPage: 10,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleRemoveGroupsFromTask = async ({
    groupIds,
    taskId,
  }: IGroupsToTaskDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await removeGroups({
        variables: {
          groupIds,
          taskId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('tasks_layout.groups_removed')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    removeGroups: (removeGroupsDto: IGroupsToTaskDto) =>
      handleRemoveGroupsFromTask(removeGroupsDto),
    tasks: (data && data.removeGroupsFromTask) || [],
    loading,
    error: errorMessage,
  }
}
