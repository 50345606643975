import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'
import {
  AnswersWrapper,
  AnswerContainer,
  CorrectAnswerTitle,
  BorderStyle,
} from './styled-components'
import { useTranslation } from 'react-i18next'
import { IAnswer, IQuestionAnswersProps, QuestionBoolAnswer } from './questionsLayout.interface'

const QuestionAnswers = ({
  type,
  answers,
  boolAnswer,
}: IQuestionAnswersProps): React.JSX.Element => {
  const boolean = boolAnswer === QuestionBoolAnswer.TRUE
  const { t } = useTranslation()

  const renderBool = (): React.JSX.Element => (
    <AnswerContainer>
      <CorrectAnswerTitle>
        <BorderStyle isCorrect={boolean}>
          <FormControlLabel
            control={<Radio disabled checked={boolean} />}
            label={<span>True</span>}
          />
        </BorderStyle>
        <BorderStyle isCorrect={!boolean}>
          <FormControlLabel
            control={<Radio disabled checked={!boolean} />}
            label={<span>False</span>}
          />
        </BorderStyle>
      </CorrectAnswerTitle>
    </AnswerContainer>
  )

  const renderSingle = (title?: string): React.JSX.Element => (
    <>
      <CorrectAnswerTitle>{title}</CorrectAnswerTitle>
      <AnswersWrapper>
        {answers &&
          answers.map((answer: IAnswer) => (
            <BorderStyle isCorrect={answer.isCorrect}>
              <FormControlLabel
                value={answer.value}
                key={answer.value}
                control={<Radio disabled checked={answer.isCorrect} />}
                label={answer.value}
              />
            </BorderStyle>
          ))}
      </AnswersWrapper>
    </>
  )

  const renderMultiple = (title?: string): React.JSX.Element => (
    <>
      <CorrectAnswerTitle>{title}</CorrectAnswerTitle>
      <AnswersWrapper>
        {answers &&
          answers.map((answer: IAnswer) => (
            <BorderStyle isCorrect={answer.isCorrect}>
              <FormControlLabel
                value={answer.value}
                control={<Checkbox disabled checked={answer.isCorrect} />}
                label={answer.value}
                key={answer.value}
              />
            </BorderStyle>
          ))}
      </AnswersWrapper>
    </>
  )

  switch (type) {
    case 'boolean':
      return renderBool()
    case 'single':
      return renderSingle()
    case 'multiple':
      return renderMultiple()
    default:
      return <>{t('general.invalid_type')}</>
  }
}

export default QuestionAnswers
