import React, { ReactElement } from 'react'
import moment from 'moment'
import { Action, GroupsWrapper, GroupItem, GroupTitle } from './styled-components'
import strCutter from 'utils/strCutter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import LockIcon from '@mui/icons-material/Lock'
import SchoolIcon from '@mui/icons-material/School'
import SendIcon from '@mui/icons-material/Send'
import Checkbox from 'components/common/Checkbox'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import { Timeline } from '@mui/icons-material'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { TranslationFunction } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { IUserActionConfigReturn } from './config.interface'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IAction } from 'components/common/GridV2'
import { IUser, IUserCompany } from 'interfaces/users'
import { IGroup } from 'interfaces/groups'
import { CheckBoxAndAvatarContainer } from 'components/UsersLayout/styled-components'

export const userActionConfig = (
  handleEditClick: (id: string) => void,
  handleDeleteClick: (item: IUser) => void,
  handleActivityClick: (id: string) => void,
  handleResendPasswordClick: (id: string) => void,
  handleChangeStatusClick: (id: string[], status: string) => void,
  permissions: IPermissionsBool,
  t: TranslationFunction,
  field?: string,
  userRole?: string,
  setResetPasswordOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setUserId?: React.Dispatch<React.SetStateAction<string>>,
): IUserActionConfigReturn[] => {
  const action = [
    {
      // hide: !permissions[`edit${field}`],
      render: (item: IUser) => (
        <Action onClick={(): void => handleEditClick(item.id as string)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions[`delete${field}`],
      render: (item: IUser) => (
        <Action onClick={(): void => handleDeleteClick(item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions[`delete${field}`],
      render: (item: IUser) => (
        <Action onClick={(): void => handleActivityClick(item.id as string)}>
          <Timeline />
          <span>{t('actions.activity')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions.resendUserPassword,
      admin: !!(field === 'Admin' && userRole === 'ADMIN'),
      render: (item: IUser) => (
        <Action onClick={(): void => handleResendPasswordClick(item.id as string)}>
          <SendIcon />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.resetPassword,
      render: (item: IUser) => (
        <Action
          onClick={(): void => {
            if (setResetPasswordOpen) {
              setResetPasswordOpen(true)
            }
            if (setUserId) setUserId(item.id as string)
          }}
        >
          <LockIcon />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions.activateUser,
      render: (item: IUser) => (
        <Action
          onClick={(): void =>
            handleChangeStatusClick(
              [item.id] as string[],
              item.status === 'ACTIVE' ? 'suspend' : 'active',
            )
          }
        >
          {item.status === 'ACTIVE' ? (
            <>
              <BackspaceRoundedIcon />
              <span>{t('actions.suspend')}</span>
            </>
          ) : (
            <>
              <CheckCircleIcon />
              <span>{t('actions.activate')}</span>
            </>
          )}
        </Action>
      ),
    },
  ].filter(i => !i.admin)

  return action.filter(i => !i.hide)
}

export const columnConfig = (
  role: string,
  handelClick: (id: string) => void,
  selectItem: (id: string) => void,
  onGroupClick: (groupId: string) => void,
  t: TranslationFunction,
  isCheckboxHidden?: boolean,
  redirectToProfile?: (id: string) => void,
): IAction[] => {
  let grid: IAction[] = []

  if (!isCheckboxHidden) {
    grid.push({
      id: isCheckboxHidden ? '' : 'isChecked',
      render: (item: IUser) => (
        <CheckBoxAndAvatarContainer>
          {!isCheckboxHidden && (
            <Checkbox
              checked={item.isChecked}
              onChange={(): void => selectItem(item.id as string)}
            />
          )}

          <img
            src={item.avatarThumbnail || item.avatar || AvatarIcon}
            onClick={(): void | object =>
              redirectToProfile ? redirectToProfile(item.id as string) : {}
            }
            style={{
              paddingLeft: isCheckboxHidden ? 8 : 0,
              width: isCheckboxHidden ? 47 : 40,
              marginRight: isCheckboxHidden ? 0 : 10,
            }}
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    })
  }

  grid = [
    ...grid,
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: (
        <div style={{ paddingLeft: isCheckboxHidden ? 30 : 0 }}>{t('user_details.first_name')}</div>
      ),
      render: (item: IUser) => (
        <div
          onClick={(): void => handelClick(item.id as string)}
          style={{
            cursor: redirectToProfile ? 'pointer' : 'default',
            paddingLeft: isCheckboxHidden ? 30 : 0,
          }}
        >
          {item.firstName}
        </div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: IUser) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: IUser) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: IUser) => (
        <div>
          {item.phoneFields && item.phoneFields.code && `(${item.phoneFields.code})`}
          {item.phone}
        </div>
      ),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('general.status'),
      render: (item: IUser) => <StatusIndicator status={item.status} />,
    },
  ]

  if (role === 'STUDENT' || role === 'ADMIN') {
    grid = [
      ...grid,
      {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: t('general.groups'),
        render: (item: IUser): ReactElement => {
          const groups = item.group || []
          const firstTwoGroups = groups.slice(0, 2)
          const exGroups = groups.slice(2, groups.length)
          return (
            <div>
              {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
              <GroupsWrapper>
                {firstTwoGroups.map(
                  (group: IGroup, index: number) =>
                    index < 3 && (
                      <GroupItem key={group.groupId}>
                        <IconButton onClick={(): void => onGroupClick(group.groupId as string)}>
                          <span className='material-icons'>groups</span>
                        </IconButton>
                        <GroupTitle className='group-item-title'>{group.name}</GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exGroups.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exGroups.map((exGroup: IGroup) => (
                          <div key={exGroup.groupId}>{exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  if ((role === 'COACH' && !isCheckboxHidden) || role === 'STUDENT') {
    grid = [
      ...grid,
      {
        id: 'courses',
        numeric: false,
        disablePadding: false,
        label: t('general.courses'),
        render: (item: IUser): ReactElement => {
          const courses = item.courses || []
          const firstTwoCourses = courses.slice(0, 2)
          const exCourses = courses.slice(2, courses.length)
          return (
            <div>
              {firstTwoCourses.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
              <GroupsWrapper>
                {courses.map(
                  (course, index: number) =>
                    index < 3 && (
                      <GroupItem key={course.courseId}>
                        <IconButton onClick={(): void => onGroupClick(course.courseId)}>
                          <SchoolIcon fontSize='small' />
                        </IconButton>
                        <GroupTitle className='group-item-title'>{course.name}</GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exCourses.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exCourses.map(exGroup => (
                          <div key={exGroup.groupId}>{exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  grid = [
    ...grid,
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: IUser) => (
        <div>
          {item.companyId ? strCutter((item.companyId as IUserCompany).name, 25) : <>N/A</>}
        </div>
      ),
    },
  ]
  grid = [
    ...grid,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: IUser) => (
        <>{moment(parseInt(item.createDate as string)).format('DD-MM-YYYY')}</>
      ),
    },
  ]

  return grid
}
