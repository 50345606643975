import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'

import CustomInput from 'components/common/CustomInput'
import UIBallLoading from 'components/common/UIBallLoading'
import useAIAssistantChat from 'hooks/assistant/useAIAssistantChat'

import {
  FileIcon,
  FileName,
  RemoveButton,
  StyledAssistantUpdateButton,
  StyledAssistantUpdateMsg,
  StyledAssistantUpdateMsgWrapper,
  StyledFileItem,
  StyledFileList,
  StyledFileUploadButton,
  StyledInnerWrapper,
  StyledPlaceholder,
  StyledResponseText,
  StyledWrapper,
} from './AIAssistantChat.styles'

const AIAssistantChat = (): React.JSX.Element => {
  const {
    response,
    responseLoading,
    message,
    messagesLoading,
    setMessage,
    messages,
    userMessageStyles,
    assistantMessageStyles,
    scrollRef,
    handleFileSelect,
    selectedFiles,
    removeFile,
    assistant,
    handleConfirmUpdate,
  } = useAIAssistantChat()

  return (
    <StyledWrapper>
      <StyledInnerWrapper ref={scrollRef}>
        {messagesLoading ? (
          <UIBallLoading loaderName='grid' />
        ) : (
          messages?.map(({ _id, role, content }) => (
            <StyledResponseText
              key={_id}
              style={role === 'user' ? userMessageStyles : assistantMessageStyles}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </StyledResponseText>
          ))
        )}
        {response && (
          <StyledResponseText style={assistantMessageStyles}>
            <ReactMarkdown>{response}</ReactMarkdown>
          </StyledResponseText>
        )}
        {responseLoading && <UIBallLoading loaderName='grid' />}
        {!responseLoading && !response && (
          <StyledPlaceholder>
            <p>Type a prompt to start the conversation.</p>
            <p>Press Shift + Enter to add a new line.</p>
            <p>Press Enter to send the prompt.</p>
            {assistant?.isUserNotifiedAboutReTraining === false && (
              <>
                <StyledAssistantUpdateMsgWrapper>
                  <StyledAssistantUpdateMsg>
                    Important Update: Our Assistant has been retrained to improve its performance
                    and offer enhanced capabilities. To access the latest version and benefit from
                    the updated data, please confirm to upgrade. Note that future messages may not
                    include previous context from old messages. Thank you for your understanding and
                    continued support.
                  </StyledAssistantUpdateMsg>
                </StyledAssistantUpdateMsgWrapper>
                <StyledAssistantUpdateButton onClick={handleConfirmUpdate}>
                  Upgrade
                </StyledAssistantUpdateButton>
              </>
            )}
          </StyledPlaceholder>
        )}
        <StyledFileUploadButton
          onClick={(): void => {
            document.getElementById('fileInput')?.click()
          }}
        >
          Upload Files
        </StyledFileUploadButton>
        <input
          id='fileInput'
          type='file'
          multiple
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          accept='.pdf, .docx, .txt, .md, .xlsx, .xls, .csv, .jpeg, .jpg, .png, .gif, .webp'
        />
        <StyledFileList>
          {Array.from(selectedFiles || []).map((file: File, index: number) => (
            <StyledFileItem key={index}>
              <FileIcon>📄</FileIcon>
              <FileName>
                {file.name} ({(file.size / 1024).toFixed(2)} KB)
              </FileName>
              <RemoveButton
                onClick={(): void => {
                  removeFile(index)
                }}
              >
                x
              </RemoveButton>
            </StyledFileItem>
          ))}
        </StyledFileList>
        <CustomInput
          value={message}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
            setMessage(e.target.value)
          }
          placeholder='Type your prompt...'
          rows={4}
          type='textarea'
          customStyles={{ width: '1200px', maxWidth: '100%' }}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default memo(AIAssistantChat)
