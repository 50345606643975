import { useQuery } from '@apollo/client'
import { GET_TEST_ASSESSMENTS_BY_GROUP_ID } from 'gql/tests/tests.query'
import {
  IUseFetchTestAssessmentsByGroupProps,
  IUseFetchTestAssessmentsResponse,
} from './getTestsService.interface'

export const useFetchTestAssessmentsByGroup = ({
  searchValue,
  currentPage = 1,
  perPage = 12,
  filterBy,
  groupId,
}: IUseFetchTestAssessmentsByGroupProps): IUseFetchTestAssessmentsResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_TEST_ASSESSMENTS_BY_GROUP_ID, {
    variables: {
      searchValue,
      currentPage,
      perPage,
      filterBy,
      groupId,
    },
    skip: !groupId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''
  const { tests = [], totalPages = 1 } = (data && data.getTestAssessmentsByGroupId) || {}

  return {
    tests,
    totalPages,
    loading,
    error: errorMessage,
    refetch,
    fetchMore: variables => fetchMore({ variables }),
  }
}
