import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import {
  IFilterData,
  IUseQuizLayoutProps,
  IUseQuizLayoutReturn,
  QuizValue,
} from 'pages/quizzes/quiz.interface'
import { ISelectAll, ISelectedItem } from 'pages/tests/testLayout/testLayout.interface'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DrawerEventEmitter } from 'helpers/drawer'
import { actionConfig, gridActionConfig, quizListConfig } from 'pages/quizzes/gridConfig'
import React from 'react'
import { Button } from 'components/common'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { IUserCompany } from 'interfaces/users'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

const useQuizLayout = ({
  type,
  id,
  IQTest,
  fromQuestions,
  setOpenCreateQuizDrawer,
  canCreate,
  companyCourse,
}: IUseQuizLayoutProps): IUseQuizLayoutReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const companyId =
    (state?.currentUser?.companyId as IUserCompany)?.id || state?.selectedCompany?.id || null
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')
  const stateRef = useRef(state)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterData, setFilterData] = useState<IFilterData>({
    filterOptions: null,
    values: null,
  })
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isFiltered, setIsFiltered] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isGridView, setIsGridView] = useState(true)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const [fetchQuizByType, { data, refetch: refetchQuizByType, loading: quizListLoading }] =
    useLazyQuery(GET_QUIZ_BY_TYPE)
  const { deleteQuiz } = useDeleteQuizService()

  const fromList = !!(type && id)
  const defaultFilter = companyId
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      }
    : {}

  const runQuery = (): void => {
    if (!state) return
    setSelectedItem({})
    if (fromList) {
      fetchQuizByType({
        variables: {
          type: type.toLowerCase(),
          actionId: id,
          currentPage,
          perPage,
          filter: {
            ...filterData.filterOptions,
            IQTestType: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: IQTest ? 'initial' : null,
            },
          },
        },
      })
    } else {
      fetchQuizByType({
        variables: {
          type: '',
          actionId: '',
          currentPage,
          perPage,
          filter: {
            ...filterData.filterOptions,
            ...defaultFilter,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (quizListLoading) {
      setInitialLoad(true)
    } else {
      setInitialLoad(false)
    }
  }, [quizListLoading])

  useEffect(() => {
    runQuery()
  }, [type, id, filterData, state])

  // Select Item handler across pages
  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []

    // Toggle selection of the item
    const newSelected = selectedItemsOnPage.includes(id)
      ? selectedItemsOnPage.filter(itemId => itemId !== id)
      : [...selectedItemsOnPage, id]

    updateSelectedItems(newSelected)
  }

  // Selects or deselects all items on the current page
  const selectAllItem = (allItemIds: string[]): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []

    // Toggle selection of all items
    const newSelectedItems = selectedItemsOnPage.length === allItemIds.length ? [] : allItemIds

    updateSelectedItems(newSelectedItems)
  }

  // Helper function to update selected items
  const updateSelectedItems = (newSelected: string[]): void => {
    const updatedSelectedItem = {
      ...selectedItem,
      [currentPage]: newSelected,
    }

    setSelectedItem(updatedSelectedItem)
    setSelectedItems(Object.values(updatedSelectedItem).flat())
  }

  const quizListConfiguration = quizListConfig(t, selectItem)

  // Navigates to the quiz details page for a specific quiz ID with router state
  const redirectToTest = (quizId: string): void => {
    navigate(`/quizzes/${quizId}`)
  }

  const openAddQuizDrawer = (): void => {
    refetchQuizByType &&
      DrawerEventEmitter.emit('openDrawer', 'addQuiz', true, {
        type: type,
        id: id,
        data: {
          name: name,
          quizzes: quizList,
          companyId: companyId,
          refetch: () => refetchQuizByType({}),
          IQTest: IQTest,
        },
      })
  }
  const quizList = fromList
    ? (data && data.getQuizzesByType) || []
    : (data && data.getQuizzesByType) || []

  const deleteQuizzes = (ids: string[]): void => {
    const title: string = IQTest
      ? ids.length > 1
        ? t('popups.delete_iq_test_many')
        : t('popups.delete_iq_test_single')
      : ids.length > 1
      ? t('popups.delete_quiz_many')
      : t('popups.delete_quiz_single')

    fireSwal({
      title,
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => deleteQuiz(ids, runQuery, type),
    })
  }

  const handlePaginationClick = (_: ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetchQuizByType({
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handleEditDrawer = (val: QuizValue): void => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: type || '',
      id: id || '',
      data: {
        quizId: val.id,
        quizzes: quizList,
        IQTest: IQTest,
        companyId: companyId,
      },
    })
  }

  const actionHandlers = (
    action: string,
    value: QuizValue,
  ): Promise<void> | void | (() => void) => {
    switch (action) {
      case 'view':
        return navigate(`/quizzes/${value.id}`)
      case 'edit':
        return handleEditDrawer(value)
      case 'delete':
        return deleteQuizzes([value.id])
      default:
        return () => console.log('1')
    }
  }

  useEffect(() => {
    stateRef.current = state
  }, [state])

  useEffect(() => {
    const filtered = checkIfFiltered(filterData.filterOptions as IFilterQueryType)
    setIsFiltered(filtered)
  }, [filterData])

  const debouncedSearch = useRef(
    debounce((searchText: string) => {
      const currentSelectedCompanyId = stateRef.current.selectedCompany?.id
      const searchValue = {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      }
      setCurrentPage(1)
      refetchQuizByType({
        filter: {
          ...filterData.filterOptions,
          name: searchValue,
          companyId: {
            type: UpperCaseFilterableFieldType.EXACT,
            value: currentSelectedCompanyId,
          },
        },
      })
      setIsSearchType(searchText !== '')
    }, 500),
  ).current

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSearchType(true)
      const newSearchText = e.target.value
      setSearchText(newSearchText)
      debouncedSearch(newSearchText)
    },
    [debouncedSearch],
  )

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    setCurrentPage(1)
    refetchQuizByType({
      filter: {
        ...filterData.filterOptions,
        ...defaultFilter,
      },
      currentPage: 1,
      perPage: newPerPage,
    })
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()

    if (!searchText.trim()) {
      return
    }

    const filter = {
      name: {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      },
      companyId: {
        type: UpperCaseFilterableFieldType.EXACT,
        value: companyId,
      },
      ...filterData.filterOptions,
    }

    refetchQuizByType({
      filter,
      currentPage: 1,
      perPage,
    })
  }

  useEffect(() => {
    if (fromQuestions) {
      openAddQuizDrawer()
    }

    return (): void => {
      setOpenCreateQuizDrawer && setOpenCreateQuizDrawer(false)
    }
  }, [fromQuestions])

  const currentUser = state.currentUser

  const actions = actionConfig(t, actionHandlers, state.userPermission)
  const gridActions = gridActionConfig(t, actionHandlers, state.userPermission)
  const createIqTestBtnDisabled = IQTest && quizList.data && quizList.data.length > 0 ? true : false
  const createButtonDisabled = !(canCreate > 0) || createIqTestBtnDisabled

  const CreateAssessmentButton = (): React.JSX.Element | null =>
    companyCourse && currentUser.companyId ? null : (
      <Tooltip
        title={createButtonDisabled && !IQTest ? t('quizzes_layout.create_quiz_tooltip') : ''}
        arrow
      >
        <span>
          <Button
            text={IQTest ? t('quizzes_layout.create_iq_test') : t('quizzes_layout.create_quiz')}
            color='secondary'
            onClick={openAddQuizDrawer}
            isDisabled={createButtonDisabled}
            icon={<AddCircleRoundedIcon />}
            background='#06C68F'
          />
        </span>
      </Tooltip>
    )
  const createButton = (): React.JSX.Element => <CreateAssessmentButton />

  return {
    state,
    companyId,
    searchText,
    setSearchText,
    filterData,
    setFilterData,
    filterDrawer,
    setFilterDrawer,
    selectedItem,
    setSelectedItem,
    selectAll,
    setSelectAll,
    currentPage,
    setCurrentPage,
    fetchQuizByType,
    data,
    refetchQuizByType,
    deleteQuiz,
    selectItem,
    handleSearchChange,
    onSearchSubmit,
    createButton,
    t,
    handlePaginationClick,
    quizList,
    quizListLoading,
    actions,
    fromList,
    deleteQuizzes,
    currentUser,
    CreateAssessmentButton,
    createIqTestBtnDisabled,
    isSearchType,
    isFiltered,
    initialLoad,
    isGridView,
    setIsGridView,
    quizListConfiguration,
    selectedItems,
    selectAllItem,
    redirectToTest,
    gridActions,
    perPage,
    handleChangeRowsPerPage,
  }
}

export default useQuizLayout
