import { useMutation } from '@apollo/client'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import {
  ADD_TEST_ASSESSMENTS_TO_GROUP_BY_ID,
  GET_TEST_ASSESSMENTS_BY_GROUP_ID,
} from 'gql/tests/tests.query'
import {
  GetTestsByGroupFiltersByEnums,
  IAddTestToGroupResponse,
  IFetchTestsByCompanyIdProps,
  ITestToGroupDto,
} from 'services/tests/getTestsService.interface'

export const useAddTestToGroupService = ({
  groupId,
}: IFetchTestsByCompanyIdProps): IAddTestToGroupResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [addTest, { data, error, loading }] = useMutation(ADD_TEST_ASSESSMENTS_TO_GROUP_BY_ID, {
    refetchQueries: [
      {
        query: GET_TEST_ASSESSMENTS_BY_GROUP_ID,
        variables: {
          groupId,
          searchValue: '',
          currentPage: 1,
          filterBy: GetTestsByGroupFiltersByEnums.IN_GROUP,
          perPage: 12,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleAddTestToGroup = async ({ testIds, groupId }: ITestToGroupDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await addTest({
        variables: {
          testAssessmentIds: testIds,
          groupId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('tests_layout.test_added')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    addTest: (createTestDto: ITestToGroupDto) => handleAddTestToGroup(createTestDto),
    tests: (data && data.createTest) || [],
    loading,
    error: errorMessage,
  }
}
