import React, { ReactElement } from 'react'
import {
  ExportButtonContainer,
  StyledExportContainer,
  StyledExportContent,
  StyledExportHeader,
} from '../styled-components'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { useTestAssessmentsScoreExport } from 'hooks/analytics/exportData'
import { IExportTestScoresAnalyticsProps } from '../exportAnalytics.interface'

const TestAssessmentsScoreExport = ({
  testOptions,
  companyId,
  testsLoading,
  setLoading,
}: IExportTestScoresAnalyticsProps): ReactElement => {
  const { t, selectedTest, handleTestChange, handleTestExport, exportLoading } =
    useTestAssessmentsScoreExport({ setLoading, companyId })

  return (
    <StyledExportContainer>
      <StyledExportHeader>
        <h3>{t('analytics.export_tests')} </h3>
        <Learning />
      </StyledExportHeader>
      <StyledExportContent>
        <SelectField
          placeholder={t('analytics.select_test')}
          value={selectedTest}
          options={testOptions}
          onChange={handleTestChange}
          isClearable
          isLoading={testsLoading}
        />
      </StyledExportContent>
      <ExportButtonContainer>
        <Button
          text={t('actions.export')}
          color='secondary'
          onClick={handleTestExport}
          background='#06C68F'
          isDisabled={!selectedTest || exportLoading}
        />
      </ExportButtonContainer>
    </StyledExportContainer>
  )
}

export default TestAssessmentsScoreExport
