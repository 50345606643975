import styled, { css } from 'styled-components'

// AddStudentsInGroupDrawer

export const Container = styled.div`
  padding: 0 20px;
  overflow-y: auto;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const fullHeightStyles = {
  height: '100%',
}

export const flexEndStyles = {
  justifyContent: 'flex-end',
}

export const FullHeightContainer = styled.div`
  height: 100%;
`

// gridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 0px;
    transition: 0.3s ease;
    .MuiTypography-body1 {
      font-size: 0.875rem;
    }
  }
`

// GroupStudents

export const StudentContainer = styled.div<{ $loading: boolean }>`
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;

  ${({ $loading }): ReturnType<typeof css> =>
    $loading
      ? css`
          padding: 0;
        `
      : css``}
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`
export const StudentAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// TO BE MOVED

export const PushNotificationContainer = styled.div`
  display: flex;
  gap: 40px;
  background-color: white;
  border-radius: 15px;
  padding-top: 40px;
  padding-inline: 20px;
  align-items: center;
  width: 40vw;
  justify-content: space-between;
  overflow: hidden;
`

export const InputContainer = styled.div`
  width: 40%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledLabel = styled.label`
  font-size: 16px;
  margin-bottom: 20px;
`

export const NotificationPreview = styled.div`
  position: relative;

  img {
    margin-bottom: -10px;
  }
`

export const NotificationContainer = styled.div`
  background-color: rgba(244, 244, 244, 0.5);
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
  width: 73%;
  display: flex;
  align-items: center;
  gap: 20px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .heading {
      font-weight: bold;
      font-size: 15px;
    }
    .body {
      font-size: 15px;
    }

    .body,
    .heading {
      margin: 0;
      width: 230px;
      inline-size: 230px;
      overflow-wrap: break-word;
      line-height: 1.1;
    }
  }

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    background-color: #fff;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
`

// ImportStudent

export const ImportContainer = styled.div`
  box-sizing: border-box;
  padding: 25px;
  height: 100%;
`

export const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
  padding-bottom: 100px;
`

export const TemplateButton = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-bottom: 20px;
  div {
    color: #555;
    padding: 0;
    line-height: 40px;
    width: 300px;
    margin: auto;
    display: block;
    border: 2px solid #555;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      color: lighten(#555, 20%);
      border-color: lighten(#555, 20%);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .rotate {
      transform: rotate(180deg);
    }
    span {
      padding: 0 10px;
    }
  }
`
