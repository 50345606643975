import styled from 'styled-components'

export const StyledTaskDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
`

export const StyledBottomBorder = styled.div`
  border-bottom: 1px solid #e7e9ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const StyledOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 16px;
  overflow-wrap: break-word;
  border-right: 1px solid #e7e9ed;
`

export const StyledDetailsContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const StyledConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`
export const StyledInDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`
export const StyledFlexRowContainer = styled.div`
  display: flex;
  gap: 30px;
`

export const StyledTableContainer = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 10px;
`

export const ActionsWrapper = styled.div`
  /* position: absolute; */
  right: 0;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: #68707a;
  }

  :hover {
    background-color: #e7e9ed;
    border-radius: 4px;
  }
`

export const StyledAttachmentContainer = styled.div`
  display: flex;
  gap: 2px;
  svg {
    width: 16px;
    height: 16px;
  }
`

export const StyledTabNavigationWrapper = styled.div`
  margin-block: 20px;
`

export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;

  cursor: pointer;
  transition: 0.3s ease;
  color: #31313d;
  .MuiButtonBase-root {
    border-radius: 0 !important;
    width: 248px;
    display: flex;
    justify-content: flex-start;
    padding: 0 !important;
  }

  &:hover {
    ${({ hoverColor }: { hoverColor?: string }): string =>
      `svg path {
        fill: ${hoverColor};
         }`}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
    width: 40px;
    height: 40px;
  }
  svg path {
    fill: #31313d;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const styles = {
  title: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '26px',
    color: '#020210',
  },

  overview: {
    fontSize: '16px',
    fontStyle: ' normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  detailTitle: {
    color: '#68707A',
    fontSize: '14px',
    fontStyle: ' normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: '4px',
  },
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  fileTitle: {
    color: '#68707A',
    fontSize: '14px',
    fontStyle: ' normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '0',
  },

  content: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0',
    margin: '0',
  },

  description: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  actionSpan: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#31313d',
    marginLeft: '0',
  },
  border: {
    borderLeft: '1px solid #020210',
    borderBottom: '1px solid #020210',
    width: '16px',
    height: '16px',
  },
  student: {
    marginTop: '24px',
    fontWeight: '600',
  },
}
