import { useMutation } from '@apollo/client'
import { ADD_GROUPS_TO_TASK } from 'gql/tasks.query'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import {
  IAddGroupsToTaskResponse,
  IFetchTasksByCompanyIdProps,
  IGroupsToTaskDto,
} from './getTasksService.interface'

export const useAddGroupsToTaskService =
  ({}: IFetchTasksByCompanyIdProps): IAddGroupsToTaskResponse => {
    const { t } = useTranslation()
    const { setSnackbar } = useSnackbarAlert()

    // mutation initialization
    const [addGroups, { data, error, loading }] = useMutation(ADD_GROUPS_TO_TASK)
    const errorMessage = error ? error?.message.split(':')[1] : ''

    // mutationUsage
    const handleAddGroupsToTask = async ({ groupIds, taskId }: IGroupsToTaskDto): Promise<void> => {
      try {
        let { message, variant }: ISnackbarProps = {
          message: '',
          variant: undefined,
        }
        const { errors } = await addGroups({
          variables: {
            groupIds,
            taskId,
          },
        })
        if (errors) {
          message = errors[0].message
          variant = SnackbarVariants.ERROR
        } else {
          message = t('tasks_layout.groups_added')
          variant = SnackbarVariants.SUCCESS
        }

        setSnackbar({ message, variant })
      } catch (error) {
        console.log(SnackbarVariants.ERROR, error)
      }
    }
    return {
      addGroups: (createGroupsDto: IGroupsToTaskDto) => handleAddGroupsToTask(createGroupsDto),
      tasks: (data && data.addGroupsToTask) || [],
      loading,
      error: errorMessage,
    }
  }
