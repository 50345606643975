import { Button } from 'components/common'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { useUserValue } from 'context/UserContext'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { DrawerEventEmitter } from 'helpers/drawer'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import { useFetchCourses } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { useSwal } from 'hooks/useSwal'
import { debounce } from 'lodash'
import { initialValues } from 'pages/courses/CourseLayout'
import {
  filterData,
  IField,
  IUseCourseLayoutReturnType,
  IVideoData,
} from 'pages/courses/courseList.interface'
import { actionConfig, courseListActions } from 'pages/courses/gridConfig'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import React from 'react'

const useCourseLayout = (): IUseCourseLayoutReturnType => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isGridView, setIsGridView] = useState(true)

  const [state] = useUserValue()
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<IGroupsFilterDrawer>({
    values: initialValues,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [isFiltered, setIsFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [value, setValue] = useState(0)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)

  const companyMode = state.selectedCompany?.id || state.currentUser.companyId
  const defaultFilter = companyMode
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: companyMode,
        },
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: value === 0,
        },
      }
    : value === 0
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: null,
        },
      }
    : {
        companyId: {
          type: UpperCaseFilterableFieldType.NOT_EQUAL,
          value: null,
        },
        originalId: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: [null],
        },
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: false,
        },
      }

  const { courses, loading, refetch, error } = useFetchCourses(
    { ...defaultFilter, ...filterQuery, ...filterData.filterOptions },
    currentPage,
    perPage,
  )

  const { publishedCourse } = usePublishedCourse()

  const handleClick = (courseId: string): void => {
    navigate(`/courses/${courseId}`)
  }

  const handleEditClick = (field: IField): void =>
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      id: field.id,
    })

  const handleNewCourse = (): void => {
    const companyId = state.selectedCompany?.id || state.currentUser.companyId || null
    const isBought = companyId
      ? {
          type: UpperCaseFilterableFieldType.EXACT,
          value: value === 0,
        }
      : {}
    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        hasSearchByCompany: false,
        companyId,
        onSuccess: () => {
          refetch({
            filter: {
              companyId: {
                type: UpperCaseFilterableFieldType.EXACT,
                value: companyId,
              },
              isBought,
            },
            currentPage: 1,
            perPage,
          })
        },
        pagination: {
          currentPage,
          perPage,
        },
      },
    })
  }

  const handleDeleteCourse = (ids: string[]): void => {
    if (ids.length <= 0) return

    const params = {
      title: `${
        ids.length > 1 ? t('popups.delete_course_many') : t('popups.delete_course_single')
      }`,
      onConfirm: (): void => {
        deleteCourse(ids, refetch)
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  useEffect(() => {
    setIsFiltered(
      checkIfFiltered(
        (filterData.filterOptions as IFilterQueryType) || ({} as IGroupFilterDrawerOptions),
      ),
    )
  }, [filterData.filterOptions])

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  //reset to tab 0, when we don't have companyId
  useEffect(() => {
    if (!state.currentUser.companyId && !state.selectedCompany?.id) {
      setValue(0)
    }
  }, [state.currentUser.companyId, state.selectedCompany?.id])

  const debouncedRefetch = useCallback(
    debounce((newSearchText: string) => {
      const formValue = {
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value: newSearchText,
        },
      }
      setFilterQuery(prevFilterQuery => ({
        ...prevFilterQuery,
        ...formValue,
      }))
      refetch({
        currentPage: 1,
        perPage,
        filter: {
          ...defaultFilter,
          ...formValue,
        },
      })
      setIsSearchType(newSearchText !== '')
    }, 500),
    [refetch, perPage, defaultFilter],
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
    setIsSearchType(true)
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    debouncedRefetch(searchText)
    setIsSearchType(true)
  }

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch])

  const handlePublishedCourse = (courseId: string[], action: string): void => {
    let title = ''
    let confirmText = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${t('popups.unpublish_course')}</span>`
      confirmText = t('popups.confirm_unpublish')
    } else {
      title = `<span style="color: green">${t('popups.publish_course')}</span>`
      confirmText = t('popups.confirm_publish')
    }

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action),
      confirmText,
    })
    //
  }

  const handleVideoModal = (data: IVideoData): void => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (_: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const handleTabChange = (
    event: React.ChangeEvent<object> | undefined,
    newValue: number,
  ): void => {
    setFilterData({} as filterData)
    setValue(newValue)
  }

  const actionHandler = (
    event: string,
    field: IField | string | IVideoData,
    action: string = '',
  ): void | (() => void) => {
    switch (event) {
      case 'viewCourse':
        return handleClick(field as string)
      case 'deleteCourse':
        return handleDeleteCourse([field as string])
      case 'publishedUnPublished':
        return handlePublishedCourse([field as string], action)
      case 'editCourse':
        return handleEditClick(field as IField)
      case 'playIntro':
        return handleVideoModal(field as IVideoData)
      default:
        return () => console.log('')
    }
  }

  const actions = actionConfig(actionHandler, state.userPermission, t)

  const listActions = courseListActions(actionHandler, t, state.userPermission, value)
  const { currentCompany, currentUser } = state

  const createButton = (): React.JSX.Element => (
    <>
      {state.userPermission.createCourse && (
        <Button
          text={`${
            state.selectedCompany?.id
              ? t('courses_layout.create_course')
              : t('courses_layout.create_suada_course')
          }`}
          color='secondary'
          icon={<AddCircleRoundedIcon />}
          onClick={handleNewCourse}
          isDisabled={!!currentUser.companyId && !currentCompany.platformService}
          background='#06C68F'
        />
      )}
    </>
  )

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    setCurrentPage(1)
    refetch({
      filter: {
        ...defaultFilter,
        ...filterQuery,
        ...filterData.filterOptions,
      },
      currentPage: 1,
      perPage: newPerPage,
    })
  }

  const breadCrumbData = [
    {
      label: 'Courses',
      icon: <Learning />,
    },
  ]
  return {
    breadCrumbData,
    state,
    handleNewCourse,
    currentUser,
    currentCompany,
    isGridView,
    setIsGridView,
    filterDrawer,
    isFiltered,
    resetFilter,
    t,
    onSearchSubmit,
    searchText,
    handleSearchChange,
    handleTabChange,
    value,
    error,
    loading,
    courses,
    companyMode,
    isSearchType,
    createButton,
    actions,
    actionHandler,
    listActions,
    handlePaginationClick,
    filterIsOpen,
    filterData,
    setFilterData,
    refetch,
    setVideoModal,
    setCurrentPage,
    videoModal,
    setFilterIsOpen,
    perPage,
    handleChangeRowsPerPage,
  }
}

export default useCourseLayout
