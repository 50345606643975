import React, { ReactElement } from 'react'
import _ from 'lodash'
import {
  GridToolbar,
  GridLayoutActions,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/GridV2'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import { FlexComponent, LayoutWrapper, StyledPagination } from './styled-components'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ToolbarItem } from 'components/common/Toolbar'
import PaginationV2 from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import useUsersLayout from 'hooks/usersLayout/useUsersLayout'
import { SplashScreen } from 'components/common'
import { roles } from 'utils/permission'
import { IUsersProps } from './usersLayout.interface'
import MyLoader from 'loading/loading'
import { ReactComponent as FilterAltOffIcon } from 'assets/remove-filter.svg'
import Tooltip from '@mui/material/Tooltip'

const UsersLayout = ({
  role,
  addText,
  addUserDrawer,
  editUserDrawer,
  showBreadCrumb = true,
  groupId,
}: IUsersProps): ReactElement => {
  const {
    breadCrumbData,
    filterDrawerOpened,
    renderFilterComponent,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    setFilterDrawerOpened,
    isFiltered,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoading,
    resetVisible,
    handleClick,
    config,
    users,
    actions,
    selectAllItem,
    selectItem,
    t,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    userId,
    setResetPasswordOpen,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    companyId,
    isSearchType,
    loading,
    resetFilter,
  } = useUsersLayout({
    role,
    addText,
    addUserDrawer,
    editUserDrawer,
    groupId,
  })

  if (
    !loading &&
    users &&
    companyId &&
    !searchValue &&
    !isSearchType &&
    !isFiltered &&
    users?.data.length === 0
  ) {
    let title, subTitle
    if (role === roles.super_admin) {
      title = t('user_details.no_super_admins')
      subTitle = t('user_details.create_super_admins')
    } else if (role === roles.admin) {
      title = t('user_details.no_admins')
      subTitle = t('user_details.create_admins')
    }
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen title={title} subTitle={subTitle} createButton={createButton} />
      </>
    )
  }

  return (
    <>
      {showBreadCrumb && <Breadcrumb data={breadCrumbData} />}
      <LayoutWrapper>
        <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='600px'>
          {renderFilterComponent()}
        </Drawer>
        <GridToolbar>
          <GridLayoutActions>
            <div>{createButton()}</div>
            <GridMultipleActions
              selectedItems={_.sumBy(Object.values(selectedItem), 'length')}
              actions={multiActions()}
            />
          </GridLayoutActions>
          <FlexComponent>
            <ToolbarItem $noGap>
              <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                <Tooltip title={t('actions.filter')} arrow>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton onClick={resetFilter}>
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <FilterAltOffIcon />
                  </Tooltip>
                </IconButton>
              )}
            </ToolbarItem>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
              name='search'
            />
          </FlexComponent>
        </GridToolbar>
        <div>
          {(deleteUserLoading || updateUserStatusLoading) && <Loader />}
          {loading && !users ? (
            <MyLoader />
          ) : (
            users && (
              <>
                {users.data.length === 0 && (searchValue !== '' || isFiltered) ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.try_adjusting_your_search_or_filter')}
                    textMarginTop='40px'
                    isSearchOrFilter={true}
                  />
                ) : (
                  <>
                    {users.data.length > 0 ? (
                      <>
                        <Grid
                          resetVisible={resetVisible}
                          redirectToDetailsHandler={handleClick}
                          config={config}
                          actionTilesLength={3}
                          data={users.data}
                          actionConfig={actions}
                          selected={_.size(selectedItem[currentPage])}
                          selectAllItem={selectAllItem}
                          selectItem={selectItem}
                          selectedItems={selectedItem[currentPage] || []}
                          ActionsContainer={StudentsActionsContainer}
                        />
                        {users && users.data?.length > 0 && (
                          <StyledPagination>
                            <PerPageDropdown
                              value={perPage}
                              onChange={(value): void =>
                                handleChangeRowsPerPage({
                                  target: {
                                    value: value.toString(),
                                  } as HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                              options={[10, 20, 50]}
                            />

                            <PaginationV2
                              currentPage={currentPage}
                              totalPages={users?.totalPages}
                              handleChange={handlePaginationClick}
                            />
                          </StyledPagination>
                        )}
                      </>
                    ) : (
                      <MyLoader />
                    )}
                  </>
                )}
              </>
            )
          )}
          {isResetPasswordOpen && (
            <ResetUsersPassword
              isModalOpen={isResetPasswordOpen}
              setModalOpen={setResetPasswordOpen}
              userId={userId}
            />
          )}
        </div>
        <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
          <div>
            <SendPushNotification
              messageData={messageData}
              setMessageData={setMessageData}
              onClose={handleModalClose}
              onSend={handlePushNotificationSend}
            />
          </div>
        </Modal>
      </LayoutWrapper>
    </>
  )
}
export default UsersLayout
