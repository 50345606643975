import React, { useState, useEffect, ReactElement } from 'react'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { StyledH1, Container, ColumnContainer } from './styled-components'
import { useUserValue } from 'context/UserContext'
import { useEditProfile } from 'hooks/users/useEditProfileService'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { TOGGLE_COMPANY_SETTINGS } from 'gql/companies.query'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import {
  HandleCompanySettingsFields,
  HandleCompanySettingsFieldsType,
  HandleSwitchChange,
  CompanyPolicyEnums,
} from './main.interface'
import { useFetchCompany } from 'hooks/companies/useCompanies'
import { useSwal } from 'hooks/useSwal'

const Settings = (): ReactElement => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [state] = useUserValue()
  const {
    currentUser: { settings },
    selectedCompany,
  } = state

  const companyId = selectedCompany?.id || state.currentUser.companyId || null
  const { company } = useFetchCompany(companyId as string)

  const [values, setValues] = useState({
    askPassword: false,
    exploreCourses: false,
    showLeaderboardByGroup: false,
    showNewsFeedByGroup: true,
    showDetailedQuizResults: false,
  })

  const { editProfile } = useEditProfile(state.currentUser)
  const [toggleCompanySettings] = useMutation(TOGGLE_COMPANY_SETTINGS)

  const confirmSwitchChange = (
    setting: HandleSwitchChange,
    callback: () => void,
    confirmTextKeyOn: string,
    confirmTextKeyOff: string,
    textKeyOn: string,
    textKeyOff: string,
    isCurrentlyOn: boolean,
  ): void => {
    const confirmTextKey = isCurrentlyOn ? confirmTextKeyOff : confirmTextKeyOn
    const textKey = isCurrentlyOn ? textKeyOff : textKeyOn
    fireSwal({
      title: t(confirmTextKey),
      subText: t(textKey),
      confirmText: t('popups.confirm'),
      onConfirm: callback,
    })
  }

  const handleSwitchChange = (setting: HandleSwitchChange): void => {
    let confirmTextKeyOn = ''
    let confirmTextKeyOff = ''
    let textKeyOn = ''
    let textKeyOff = ''
    let isCurrentlyOn = false

    switch (setting) {
      case HandleSwitchChange.exploreCourses:
        confirmTextKeyOn = 'popups.confirm_toggle_explore_courses_on'
        confirmTextKeyOff = 'popups.confirm_toggle_explore_courses_off'
        textKeyOn = 'popups.text_explore_courses_on'
        textKeyOff = 'popups.text_explore_courses_off'
        isCurrentlyOn = values.exploreCourses
        break
      case HandleSwitchChange.showLeaderboardByGroup:
        confirmTextKeyOn = 'popups.confirm_toggle_show_leaderboard_by_on'
        confirmTextKeyOff = 'popups.confirm_toggle_show_leaderboard_by_off'
        textKeyOn = 'popups.text_show_leaderboard_by_on'
        textKeyOff = 'popups.text_show_leaderboard_by_off'
        isCurrentlyOn = values.showLeaderboardByGroup
        break
      case HandleSwitchChange.showNewsFeedByGroup:
        confirmTextKeyOn = 'popups.confirm_toggle_show_news_feed_by_on'
        confirmTextKeyOff = 'popups.confirm_toggle_show_news_feed_by_off'
        textKeyOn = 'popups.text_show_news_feed_by_on'
        textKeyOff = 'popups.text_show_news_feed_by_off'
        isCurrentlyOn = values.showNewsFeedByGroup
        break
      case HandleSwitchChange.showDetailedQuizResults:
        confirmTextKeyOn = 'popups.confirm_toggle_show_detailed_quiz_results_on'
        confirmTextKeyOff = 'popups.confirm_toggle_show_detailed_quiz_results_off'
        textKeyOn = 'popups.text_show_detailed_quiz_results_on'
        textKeyOff = 'popups.text_show_detailed_quiz_results_off'
        isCurrentlyOn = values.showDetailedQuizResults
        break
      case HandleSwitchChange.askPassword:
        editProfile({
          settings: {
            askPassword: !values.askPassword,
          },
        })
        setValues({
          ...values,
          askPassword: !values.askPassword,
        })
        return
    }

    confirmSwitchChange(
      setting,
      () => {
        let variables: HandleCompanySettingsFieldsType = {
          companyId: companyId as string,
        }
        switch (setting) {
          case HandleSwitchChange.exploreCourses:
            variables = {
              ...variables,
              field: HandleCompanySettingsFields.exploreCourses,
              boolValue: !values.exploreCourses,
            }
            break
          case HandleSwitchChange.showLeaderboardByGroup:
            variables = {
              ...variables,
              field: HandleCompanySettingsFields.showLeaderboardBy,
              value: !values.showLeaderboardByGroup
                ? CompanyPolicyEnums.GROUP
                : CompanyPolicyEnums.COMPANY,
            }
            break
          case HandleSwitchChange.showNewsFeedByGroup:
            variables = {
              ...variables,
              field: HandleCompanySettingsFields.showNewsFeedBy,
              value: !values.showNewsFeedByGroup
                ? CompanyPolicyEnums.GROUP
                : CompanyPolicyEnums.COMPANY,
            }
            break
          case HandleSwitchChange.showDetailedQuizResults:
            variables = {
              ...variables,
              field: HandleCompanySettingsFields.showDetailedQuizResults,
              boolValue: !values.showDetailedQuizResults,
            }
            break
        }

        toggleCompanySettings({
          variables,
        })

        setValues({
          ...values,
          [setting]: !values[setting],
        })
      },
      confirmTextKeyOn,
      confirmTextKeyOff,
      textKeyOn,
      textKeyOff,
      isCurrentlyOn,
    )
  }

  useEffect(() => {
    if (settings) {
      setValues({
        ...values,
        askPassword: settings.askPassword,
      })
    }
  }, [settings])

  useEffect(() => {
    if (company) {
      setValues({
        ...values,
        exploreCourses: company.exploreCourses,
        showLeaderboardByGroup: company.showLeaderboardBy === CompanyPolicyEnums.GROUP,
        showNewsFeedByGroup: company.showNewsFeedBy === CompanyPolicyEnums.GROUP,
        showDetailedQuizResults: company.showDetailedQuizResults || false,
      })
    }
  }, [company])

  return (
    <Container>
      <StyledH1>{t('settings_layout.title')}</StyledH1>
      <ColumnContainer>
        {companyId ? (
          <>
            <CustomSwitcher
              onChange={(): void => handleSwitchChange(HandleSwitchChange.exploreCourses)}
              label={t('settings_layout.explore_courses')}
              name='exploreCourses'
              value={values.exploreCourses}
              on={t('general.on')}
              off={t('general.off')}
              tooltip={t('company_details.explore_courses_helper')}
            />
            <CustomSwitcher
              onChange={(): void => handleSwitchChange(HandleSwitchChange.showLeaderboardByGroup)}
              label={t('settings.show_leaderboard_by')}
              name='showLeaderboardBy'
              value={values.showLeaderboardByGroup}
              on={t('navigation.groups')}
              off={t('navigation.company')}
              tooltip={t('settings.show_leaderboard_by_helper')}
            />
            <CustomSwitcher
              onChange={(): void => handleSwitchChange(HandleSwitchChange.showNewsFeedByGroup)}
              label={t('settings.news_feed_scope')}
              name='showNewsFeedBy'
              value={values.showNewsFeedByGroup}
              on={t('navigation.groups')}
              off={t('navigation.company')}
              tooltip={t('settings.news_feed_scope_helper')}
            />
            <CustomSwitcher
              onChange={(): void => handleSwitchChange(HandleSwitchChange.showDetailedQuizResults)}
              label={t('settings.show_detailed_quiz_results')}
              name='showDetailedQuizResults'
              value={values.showDetailedQuizResults}
              on={t('general.on')}
              off={t('general.off')}
              tooltip={t('settings.show_detailed_quiz_results_helper')}
            />
          </>
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={values.askPassword}
                onChange={(): void => handleSwitchChange(HandleSwitchChange.askPassword)}
                value='checkedB'
                style={{ color: '#06C68F' }}
              />
            }
            label={t('settings_layout.aks_new_user_for_password_change')}
            labelPlacement='start'
          />
        )}
      </ColumnContainer>
    </Container>
  )
}

export default Settings
