import React from 'react'
import { IFilterQueryType, ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { useUserValue } from 'context/UserContext'
import { PER_PAGE, useFetchStudents } from 'hooks/users/useFetchStudents'
import { useSwal } from 'hooks/useSwal'
import { ISelectAll } from 'pages/quizzes/quiz.interface'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { DrawerEventEmitter } from 'helpers/drawer'
import { IUser } from 'interfaces/users'
import { IKebabAction } from 'components/common/GridV2/GridActionMenu/gridActionMenu.interface'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArchiveIcon from '@mui/icons-material/Archive'
import CircleNotificationsIcon from '@mui/icons-material/Notifications'
import { columnConfig, userActionConfig } from '../../CompanyTabs/config'
import { ICompanyStudentsReturnType, IUseCompanyStudentsParams } from '../companyStudents.interface'

const useCompanyStudents = ({
  variables,
  currentTab,
}: IUseCompanyStudentsParams): ICompanyStudentsReturnType => {
  const ROLE = 'STUDENT'
  const { t } = useTranslation()
  const { companyId } = variables
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { users, loading, refetch } = useFetchStudents({}, 1, PER_PAGE, companyId)
  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()
  const handleModalClose = (): void => {
    setPushNotificationModal(false)
    setTimeout(() => {
      setMessageData({
        heading: '',
        body: '',
      })
    }, 500)
  }

  const handlePushNotificationSend = async (): Promise<false | undefined> => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const { deleteUser } = useUserDeleteService()

  const { updateUserStatus } = useUpdateStatusUserService({})

  const { updateUserPassword } = useResendUserService()

  const { exportData } = useExportDataService()

  const [selectedItem, setSelectedItem] = useState<ISelectPage>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [filterOptions, setFilterOptions] = useState<IFilterQueryType | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [userId, setUserId] = useState('')

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users?.data.map(n => n.id) as string[]
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const confirmDeleteMany = (ids: string[]): void => {
    deleteUser(ids, ROLE, () =>
      refetch({
        filter: filterQuery,
        currentPage,
        perPage,
        companyId,
      }),
    )
    setSelectedItem([])
  }

  const confirmChangeStatusMany = (ids: string[], newStatus: string): void => {
    updateUserStatus(ids.length > 0 ? ids : selectedItem[currentPage] || [], newStatus)
  }

  const handleEditClick = (userId: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editStudent', true, {
      id: userId,
    })
  }

  const handleDeleteAllClick = (): void => {
    const roleText = 'Students'
    fireSwal({
      title: `Are you sure you want to delete selected ${roleText}?`,
      onConfirm: () => confirmDeleteMany(selectedItem[currentPage]),
      confirmText: 'Yes, delete!',
    })
  }

  const handleDeleteClick = (user: IUser): void => {
    const params = {
      title: `Are you sure you want to delete this Student?`,
      onConfirm: (): void => {
        confirmDeleteMany([user.id as string])
      },
      confirmText: 'Yes, delete!',
    }

    fireSwal(params)
  }

  const handleChangeStatusAllClick = (ids: string[], action: string, hasMany?: boolean): void => {
    let title = ''
    let newStatus = ''
    let confirmText = ''

    if (action === 'suspend') {
      title = hasMany
        ? `Are you sure you want to Suspend selected Students?`
        : `Are you sure you want to Suspend this Student?`
      newStatus = 'SUSPENDED'
      confirmText = 'Yes, Suspend!'
    } else {
      title = hasMany
        ? `Are you sure you want to Activate selected Students?`
        : `Are you sure you want to Activate this Student?`
      newStatus = 'ACTIVE'
      confirmText = 'Yes, Activate!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(ids, newStatus),
      confirmText,
    })
  }

  const handleExportData = (): void => {
    if (selectedItem[currentPage].length > 0) {
      exportData(ROLE, selectedItem[currentPage], null, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleResendPasswordClick = (userId: string): void => {
    fireSwal({
      title: `Are you sure you want to resend password for this Student?`,
      onConfirm: () => {
        updateUserPassword(userId)
      },
      confirmText: 'Yes, Resend Password!',
    })
  }

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      filter: { ...filterQuery },
      currentPage: newPage + 1,
      perPage,
      companyId,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      filter: { ...filterQuery },
      currentPage,
      perPage: parseInt(event.target.value),
      companyId,
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const handleSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      filter: { ...formData },
      currentPage: 1,
      perPage,
      companyId,
    })
    setFilterQuery(formData)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSelectedItem({})
      setSelectAll({})
      setSearchValue('')
      setFilterQuery({})
    }
  }, [currentTab])

  const multiActions = (): IKebabAction[] => [
    {
      id: 0,
      color: 'secondary',
      tooltipText: t('actions.delete'),
      disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
      onClick: () => handleDeleteAllClick(),
      component: <DeleteIcon fontSize='small' />,
    },
    {
      id: 1,
      color: 'secondary',
      tooltipText: t('actions.suspend'),
      disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
      onClick: () => handleChangeStatusAllClick([], 'suspend', true),
      component: <BackspaceRoundedIcon fontSize='small' />,
    },
    {
      id: 2,
      color: 'primary',
      tooltipText: t('actions.activate'),
      disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
      onClick: () => handleChangeStatusAllClick([], 'activate', true),
      component: <CheckCircleIcon fontSize='small' />,
    },
    {
      id: 10,
      color: 'primary',
      tooltipText: t('actions.export'),
      disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
      onClick: () => handleExportData(),
      component: <ArchiveIcon fontSize='small' />,
    },
    {
      id: 3,
      color: 'primary',
      tooltipText: `${t('actions.send_push_notification')}`,
      disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
      onClick: () => setPushNotificationModal(true),
      component: <CircleNotificationsIcon fontSize='small' />,
    },
  ]

  const handleClick = (): void => undefined
  const onGroupClick = (): void => undefined

  const handleResetClick = (): void => {
    refetch({
      filter: {},
      currentPage,
      perPage,
      companyId,
    })
    setSearchValue('')
    setFilterQuery({})
    setFilterOptions(null)
  }

  const filterData = (filters: IFilterQueryType): void => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      filter: { ...filters, ...formValue },
      currentPage: 1,
      perPage,
      companyId,
    })

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue })
      setSelectedItem({})
    }
  }

  const redirectToProfile = (id: string): void => {
    navigate(`/profile/${id}`)
  }

  const handleActivityClick = (id: string): void => {
    const url = `/profile/${id}/activity`
    window.open(url, '_blank')
  }

  const groupAdminPolicy =
    state.currentUser.role !== 'ADMIN' ||
    (state.currentUser.role === 'ADMIN' && [6, 7].includes(currentTab))

  const config = columnConfig(
    ROLE,
    handleClick,
    selectItem,
    onGroupClick,
    t,
    !groupAdminPolicy,
    redirectToProfile,
  )
  const actions = userActionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusAllClick,
    state.userPermission,
    t,
    ROLE,
    state.currentUser.role,
    setResetPasswordOpen,
    setUserId,
  )

  return {
    filterData,
    filterOptions,
    setFilterOptions,
    loading,
    users,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleResetClick,
    handlePushNotificationSend,
    handleModalClose,
    pushNotificationModal,
    messageData,
    isResetPasswordOpen,
    setResetPasswordOpen,
    userId,
    selectedItem,
    selectAllItem,
    redirectToProfile,
    config,
    actions,
    perPage,
    searchValue,
    filterDrawerOpened,
    currentPage,
    setFilterDrawerOpened,
    multiActions,
    groupAdminPolicy,
    setMessageData,
  }
}

export default useCompanyStudents
