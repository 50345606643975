import React from 'react'
import { Ul, Li, Top, Time, Content, TimelineLabel } from './styled-components'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import moment from 'moment'
import { ActivityAction } from './activity.interface'

interface IAllUserProfileList {
  user: string
  document: string
  title: string
  action: string
  createDate: number
}

const TimeLine = ({
  list,
}: {
  list: {
    user: string
    document: string
    title: string
    action: string
    createDate: number
  }[]
}): React.JSX.Element => {
  const timeBuilder = (time: number): string => {
    const date = new Date(time)
    const clock = moment(time).format('h:mm A')
    const m = date.toLocaleString('en-US', { month: 'long', day: 'numeric' })
    return `${m} - ${clock}`
  }
  const reverseArray = (array: IAllUserProfileList[]): IAllUserProfileList[] => {
    const newArray = []
    for (let i = array.length - 1; i >= 0; i--) {
      newArray.push(array[i])
    }
    return newArray
  }

  const renderLabel = (action: string): string => {
    return (
      ActivityAction[action as keyof typeof ActivityAction] ||
      ActivityAction.UNKNOWN
    )
  }

  if (list && list.length > 0) {
    return (
      <Ul>
        <Top>
          <FiberManualRecordIcon />
        </Top>
        {reverseArray(list).map((element, index) => (
          <>
            <Li key={index}>
              <FiberManualRecordIcon />
              <Time>{timeBuilder(element.createDate)}</Time>
              <br></br>
              <TimelineLabel>{renderLabel(element.action)}</TimelineLabel>
              <br></br>
              <Content>{element.title}</Content>
            </Li>
            <br></br>
          </>
        ))}
      </Ul>
    )
  }
  return <div>User has no Activities</div>
}
export default TimeLine
