import React from 'react'
import { DrawerHeader, DrawerTitle } from 'components/DrawerLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ICustomDrawerHeaderProps } from './customDrawerHeader.interface'

const CustomDrawerHeader: React.FC<ICustomDrawerHeaderProps> = ({ title, onClose }) => (
  <DrawerHeader>
    <DrawerTitle>{title}</DrawerTitle>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </DrawerHeader>
)

export default CustomDrawerHeader
