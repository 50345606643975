import { useMutation } from '@apollo/client'

import {
  GET_COMPANIES,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  CHANGE_COMPANY_PLATFORM_SERVICE,
  CHECK_COMPANY_EMAIL,
  REMOVE_COMPANY_AVATAR,
} from 'gql/companies.query'

import {
  IAddCompanyResponse,
  IAddCompanyVariables,
  IChangeCompanyPlatformServiceResponse,
  IChangeCompanyPlatformServiceVariables,
  ICheckCompanyEmailResponse,
  ICheckCompanyEmailVariables,
  ICompaniesServiceResponse,
  IDeleteCompanyResponse,
  IDeleteCompanyVariables,
  IRemoveCompanyAvatarResponse,
  IRemoveCompanyAvatarVariables,
  IUpdateCompanyResponse,
  IUpdateCompanyVariables,
} from './companiesService.interface'

const companiesService = (): ICompaniesServiceResponse => {
  const [addCompanyMutation] = useMutation<IAddCompanyResponse, IAddCompanyVariables>(ADD_COMPANY, {
    refetchQueries: [
      {
        query: GET_COMPANIES,
        variables: {
          currentPage: 1,
          perPage: 12,
          filter: {},
        },
      },
    ],
  })

  const [removeCompanyAvatarMutation] = useMutation<
    IRemoveCompanyAvatarResponse,
    IRemoveCompanyAvatarVariables
  >(REMOVE_COMPANY_AVATAR)

  const [changeCompanyPlatformServiceMutation] = useMutation<
    IChangeCompanyPlatformServiceResponse,
    IChangeCompanyPlatformServiceVariables
  >(CHANGE_COMPANY_PLATFORM_SERVICE)

  const [updateCompanyMutation] = useMutation<IUpdateCompanyResponse, IUpdateCompanyVariables>(
    UPDATE_COMPANY,
  )

  const [deleteCompanyMutation] = useMutation<IDeleteCompanyResponse, IDeleteCompanyVariables>(
    DELETE_COMPANY,
  )

  const [checkCompanyEmailMutation] = useMutation<
    ICheckCompanyEmailResponse,
    ICheckCompanyEmailVariables
  >(CHECK_COMPANY_EMAIL)

  return {
    addCompanyMutation,
    changeCompanyPlatformServiceMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    checkCompanyEmailMutation,
    removeCompanyAvatarMutation,
  }
}

export default companiesService
