import { useTranslation } from 'react-i18next'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useMutation } from '@apollo/client'
import { CHANGE_ORDER } from 'gql/modules.query'
import {
  CourseCompany,
  Group,
  ICourse,
  ICourseListProps,
  IUseCourseListReturnType,
} from 'pages/courses/courseList.interface'
import update from 'immutability-helper'
import { SET_SELECTED_COMPANY } from 'store/types'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { courseListConfig } from 'pages/courses/gridConfig'
import {
  CardActionBar,
  CourseStatusContainer,
  GridItemLessons,
  ItemBlock,
  StyledActionItem,
  StyledActionsContainer,
  StyledCardBottomContainer,
  StyledChecked,
  StyledCourseCardContent,
  StyledItemWrapper,
  StyledToolTipContainer,
  StylesCardWrapper,
  TooltipContent,
} from 'pages/courses/styled-components'
import { Checkbox, IconButton } from '@mui/material'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import {
  GridItem,
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemTitle,
} from 'components/common/Cards/styled-components'
import { ItemWrapper } from 'components/styled-components'
import TooltipHeight from 'components/common/Tooltip/TooltipHeight'
import { formatDuration } from 'helpers/formatDuration'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as PlayButton } from 'assets/unfill-play.svg'
import { ReactComponent as UnlockIcon } from 'assets/unlock.svg'
import { Tooltip } from '@mui/material'
import { GroupOutlined, GroupsOutlined, RecordVoiceOverOutlined } from '@mui/icons-material'
import { IUser } from 'interfaces/users'
import { useNavigate } from 'react-router-dom'

const useCourseList = ({
  groupId,
  user,
  openInExternalTab,
  data,
  $canOpen = true,
  fromCertificateBuilder,
  fromWizard,
  selectedItem,
  gridStyle,
  actions,
  selectItem,
  actionHandler,
  companyMode,
  activeTab,
  withTooltips,
}: ICourseListProps): IUseCourseListReturnType => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()

  const [changeCoursesOrder] = useMutation(CHANGE_ORDER)

  const navigate = useNavigate()

  const [cards, setCards] = useState<ICourse[]>(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const RedirectToCourse = useCallback(
    (item: ICourse) => {
      if (!!groupId || user) {
        const currentCompany = state.selectedCompany
        if (!currentCompany) {
          let companyObj = null
          if (item.companyId) {
            const { id, name } = item.companyId as CourseCompany
            companyObj = { id, name }
          }
          dispatch({
            type: SET_SELECTED_COMPANY,
            payload: companyObj,
          })

          setSelectedCompanyInLocalStorage(companyObj)
        }
        navigate(`/`, { state: { courseId: item.id, groupId, user } })
      } else if (openInExternalTab) {
        window.open(`/courses/${item.id}`, '_blank')
      } else {
        navigate(`/courses/${item.id}`)
      }
    },
    [openInExternalTab],
  )

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string | undefined,
  ): Promise<void> => {
    await changeCoursesOrder({
      variables: { draggedId, droppedId },
    })
  }

  const getCourseState = (state?: string): string | undefined => {
    let courseState = state
    switch (state) {
      case 'published':
        courseState = t('courses_layout.published')
        break
      case 'unpublished':
        courseState = t('courses_layout.unpublished')
    }

    return courseState
  }

  const getCourseLevel = (level: string): string => {
    let courseLevel = level
    switch (level) {
      case 'beginner':
        courseLevel = t('courses_layout.beginner')
        break
      case 'intermediate':
        courseLevel = t('courses_layout.intermediate')
        break
      case 'advanced':
        courseLevel = t('courses_layout.advanced')
    }

    return courseLevel
  }

  useEffect(() => {
    setCards(data)
  }, [data])

  const courseList = courseListConfig(t)

  const renderContent = (item: ICourse): ReactElement => {
    const courseoriginalid =
      item.isBought && !state.currentUser.companyId ? item.originalId : item.id

    return (
      <StylesCardWrapper
        className='wrapper course-card'
        key={item.id}
        $canOpen={$canOpen}
        onClick={(): void => {
          if ($canOpen) {
            fromCertificateBuilder
              ? navigate(`/certificate-builder/${courseoriginalid}`)
              : RedirectToCourse(item)
          }
        }}
      >
        {fromWizard && selectedItem && selectedItem.includes(item.id) && <StyledChecked />}
        <CardActionBar $switched={!!(gridStyle === 'card')}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                zIndex: 3,
                alignItems: fromWizard ? 'center' : '',
                transform: fromWizard ? 'none' : 'scale(1)',
                display: fromCertificateBuilder ? 'none' : fromWizard ? 'flex' : 'block',
              }}
            >
              {fromWizard && (
                <Checkbox
                  onChange={(): void => selectItem && selectItem(item.id)}
                  checked={selectedItem && selectedItem.includes(item.id)}
                />
              )}
              <CourseStatusContainer $status={item.state === 'published'}>
                {item.finished === 3 ? getCourseState(item.state) : t('courses_layout.draft')}
              </CourseStatusContainer>
            </div>
            {actions && actions.length > 0 && (
              <div className='action_block'>
                <GridActionMenu actionConfig={actions} row={item} />
              </div>
            )}
          </div>
        </CardActionBar>
        <GridItem $isFromUser={Boolean(groupId || user)}>
          <GridItemImage>
            <img
              src={
                (item.avatar && item.avatar.link) ||
                'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
              }
            />
          </GridItemImage>
          <GridItemContent>
            <ItemWrapper style={{ justifyContent: 'space-between' }}>
              <ItemBlock>
                {item.level && (
                  <>
                    {t('course_details.level')}: {getCourseLevel(item.level as string)}
                  </>
                )}
              </ItemBlock>
              <ItemBlock style={{ color: 'green' }}>
                {item.currency}
                {item.price
                  ? item.price.includes('.')
                    ? item.price
                    : `${item.price}.00`
                  : t('courses_layout.free')}
              </ItemBlock>
            </ItemWrapper>
            <GridItemContentHeader>
              <GridItemTitle style={{ marginTop: '8px' }}>
                <TooltipHeight text={item.name} lines={3} />
              </GridItemTitle>
            </GridItemContentHeader>
            <StyledItemWrapper></StyledItemWrapper>
          </GridItemContent>
        </GridItem>
        <StyledCardBottomContainer style={{ textTransform: 'capitalize' }}>
          <>
            <GridItemLessons
              checked={fromWizard && selectedItem && selectedItem.includes(item.id) ? true : false}
            >
              <StyledCourseCardContent>
                {item.numberOfModules} {t('general.modules')}
              </StyledCourseCardContent>
              <StyledCourseCardContent>
                {item.numberOfLessons} {t('general.lessons')}
              </StyledCourseCardContent>
              <StyledCourseCardContent>
                {formatDuration(item.duration || 0)}
              </StyledCourseCardContent>
            </GridItemLessons>

            {actionHandler && !item.isBought && (
              <StyledActionsContainer>
                <StyledActionItem
                  color='#20a8c6'
                  onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                    if (typeof actionHandler === 'function') {
                      actionHandler('editCourse', item)
                    }
                    e.stopPropagation()
                  }}
                  style={{ borderRight: '1px solid #D6DAE0' }}
                >
                  <EditRoundedIcon /> {t('actions.edit')}
                </StyledActionItem>
                <StyledActionItem
                  color='#d0524b'
                  disabled={!item.video}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                    if (item.video && typeof actionHandler === 'function') {
                      actionHandler('playIntro', item)
                      e.stopPropagation()
                    }
                    e.stopPropagation()
                  }}
                >
                  <PlayButton /> {t('actions.play_intro')}
                </StyledActionItem>

                {companyMode && activeTab != 0 && (
                  <StyledActionItem
                    disabled={state.userPermission?.publishCourse === false}
                    color={item.state === 'published' ? ' #EA382A' : '#00905E'}
                    style={{ borderLeft: '1px solid #D6DAE0' }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                      actionHandler &&
                        actionHandler(
                          'publishedUnPublished',
                          item.id,
                          item.state === 'unpublished' ? 'published' : 'unpublished',
                        )
                      e.stopPropagation()
                    }}
                  >
                    <UnlockIcon />
                    {item.state === 'published' ? t('actions.unpublish') : t('actions.publish')}
                  </StyledActionItem>
                )}
              </StyledActionsContainer>
            )}
          </>
        </StyledCardBottomContainer>
        {withTooltips && (
          <StyledToolTipContainer>
            <hr style={{ opacity: '.1', margin: 0 }} />
            <ItemWrapper style={{ justifyContent: 'space-around', flexDirection: 'row' }}>
              <Tooltip
                arrow
                title={
                  <TooltipContent>
                    <span>
                      {(item.coaches?.length && t('general.coaches')) || t('general.no_coaches')}
                    </span>
                    <div>
                      {(item.coaches &&
                        (item.coaches as IUser[]).map(
                          (i, index: number) =>
                            `${i.firstName} ${i.lastName} ${(index !== 0 && `,`) || ''} `,
                        )) ||
                        0}
                    </div>
                  </TooltipContent>
                }
              >
                <IconButton style={{ padding: '9px' }}>
                  <RecordVoiceOverOutlined fontSize='small' />
                </IconButton>
              </Tooltip>

              <Tooltip
                arrow
                title={
                  <TooltipContent>
                    <span>
                      {item.groups?.length || 0} {t('general.groups')}
                    </span>
                    <div>
                      {item.groups &&
                        item.groups.map(
                          (group: Group, index: number) => `
                            ${group.name}
                            ${index === item.groups.length - 1 ? '' : ','}
                          `,
                        )}
                      {$canOpen && item.originalId && (
                        <span className='tooltip-text'>
                          {t('general.purchased_course_groups_tooltip')}
                        </span>
                      )}
                    </div>
                  </TooltipContent>
                }
              >
                <IconButton style={{ padding: '9px' }}>
                  <GroupsOutlined fontSize='small' />
                </IconButton>
              </Tooltip>

              <Tooltip
                arrow
                title={
                  <TooltipContent>
                    <div>
                      <span>
                        {item.numberOfStudents || 0} {t('general.students')}
                      </span>
                      {$canOpen && item.originalId && (
                        <span className='tooltip-text'>
                          {t('general.purchased_course_students_tooltip')}
                        </span>
                      )}
                    </div>
                  </TooltipContent>
                }
              >
                <IconButton style={{ padding: '9px' }}>
                  <GroupOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            </ItemWrapper>
          </StyledToolTipContainer>
        )}
      </StylesCardWrapper>
    )
  }
  return {
    moveCard,
    cards,
    handleChangeRequest,
    renderContent,
    RedirectToCourse,
    courseList,
  }
}

export default useCourseList
