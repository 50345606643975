import React, { ReactElement } from 'react'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ReactSelect from 'react-select'
import Slider from '@mui/material/Slider'
import { filterDefault } from 'pages/quizzes/staticData'
import { FormItemContainer } from 'pages/courses/FilterDrawer/styled-components'
import { FilterDropdownProps, FilterTestDrawerProps } from './filter.interface'
import useFilterTestLogic from 'hooks/tests/useFilterTestLogic'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const FilterTestDrawer = (props: FilterTestDrawerProps): ReactElement => {
  const {
    handleFieldChange,
    filterDataValues,
    passRate,
    tagList,
    onFormSubmit,
    t,
    setFilterData,
    onClose,
    setPassRate,
  } = useFilterTestLogic(props)

  return (
    <>
      <CustomDrawerHeader title={t('general.advanced_filters')} onClose={onClose} />
      <br />
      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Title>{t('form_fields.name')}</Title>
              <Input
                placeholder={t('form_fields.name')}
                name='name'
                size='small'
                type='text'
                fullWidth
                value={filterDataValues.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFieldChange(e, 'name')
                }
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>
                  {t('general.quiz')} {t('quiz_details.participation')}
                  {t('general.type')}
                </Title>
                <ReactSelect
                  className='select-participation'
                  onChange={(e): void =>
                    handleFieldChange(e as FilterDropdownProps, 'quizParticipationType', 'dropdown')
                  }
                  options={filterDefault.quizParticipationType}
                  value={filterDataValues.quizParticipationType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('quiz_details.time_type')}</Title>
                <ReactSelect
                  className='select-time-type'
                  onChange={(e): void =>
                    handleFieldChange(e as FilterDropdownProps, 'timeType', 'dropdown')
                  }
                  options={filterDefault.timeType}
                  value={filterDataValues.timeType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('general.tags')}</Title>
                <ReactSelect
                  className='select-tag'
                  isMulti
                  onChange={(e): void =>
                    handleFieldChange(e as FilterDropdownProps, 'tags', 'dropdown')
                  }
                  options={tagList}
                  value={filterDataValues.tags}
                />
              </FormItemContainer>
            </FormGroupItem>
            <FormGroupItem>
              <Title id='range-slider'>{t('quiz_details.pass_rate')}</Title>
              <Slider
                value={passRate}
                onChange={(_: Event, value: number | number[]): void =>
                  setPassRate(value as number[])
                }
                valueLabelDisplay='auto'
                aria-labelledby='range-slider'
                min={0}
                max={100}
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={(): void => {
                  setFilterData({
                    filterOptions: null,
                    values: null,
                  })
                  onClose()
                }}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text={t('actions.filter')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterTestDrawer
