import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { useData } from 'context/DataContext'
import { roles } from 'utils/permission'
import { useLazyQuery } from '@apollo/client'
import { GET_USERS } from 'gql/users.query'
import { GET_COURSES_OPTIONS } from 'gql/course/course.query'
import { CLEAR_FORMDATA } from 'store/types'
import { DataOptionFieldEnums } from 'pages/group/AddGroupDrawer/addGroupDrawer.interface'
import _ from 'lodash'
import { IGroupDrawerProps, IUseGroupDrawerReturn } from './useGroupDrawer.interface'
import { IGroupFormFormValues } from '../useGroupForm/useGroupForm.interface'

export const initialFormData: IGroupFormFormValues = {
  name: '',
  note: '',
  location: '',
  admin: null,
  courses: [],
  isNewsFeedAvailable: true,
}

const useGroupDrawer = ({ companyId }: IGroupDrawerProps): IUseGroupDrawerReturn => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const [drawer, setDrawer] = useState({
    addAdmin: false,
    args: {} as object,
  })

  const ref = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [, dataDispatch] = useData()

  const [formData, setFormData] = useState(initialFormData)

  const variables = { role: roles.admin }
  const [fetchAdmin, { data: adminData, loading: adminLoading }] = useLazyQuery(GET_USERS)

  const [fetchCourses, { data: courses, loading: coursesLoading }] = useLazyQuery(
    GET_COURSES_OPTIONS,
    { variables: { perPage: 0 } },
  )

  const handleInnerDrawerClose = (): void => {
    dataDispatch({
      type: CLEAR_FORMDATA,
      payload: true,
    })
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (isEmpty) {
        setDrawer({ addAdmin: false, args: {} })
        dataDispatch({
          type: CLEAR_FORMDATA,
          payload: true,
        })
      } else {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            setDrawer({ addAdmin: false, args: {} })
            dataDispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          onClose: () => {
            setFormData(initialFormData)
            dataDispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          confirmText: 'Yes, Cancel',
          cancelText: 'No',
        })
      }
    }
  }, [formData])

  const loadDataOptions = (field: DataOptionFieldEnums, e: string): void => {
    if (field === DataOptionFieldEnums.ADMINS)
      setTimeout(() => {
        const value = e
        fetchAdmin({
          variables: {
            ...variables,
            filter: {
              search: {
                type: 'search',
                value,
                fields: ['firstName', 'lastName', 'email', 'phone'],
              },
              companyId: {
                type: LowerCaseFilterableFieldType.EXACT,
                value: companyId,
              },
            },
          },
        })
      }, 200)
    else
      setTimeout(() => {
        const value = e
        fetchCourses({
          variables: {
            filter: {
              search: {
                type: LowerCaseFilterableFieldType.MATCH,
                value,
              },
              companyId: {
                type: UpperCaseFilterableFieldType.EXACT,
                value: companyId,
              },
            },
          },
        })
      }, 200)
  }

  useEffect(() => {
    loadDataOptions(DataOptionFieldEnums.ADMINS, '')
    loadDataOptions(DataOptionFieldEnums.COURSES, '')
  }, [])

  const handleSelectChange = (field: DataOptionFieldEnums, e: string): void => {
    clearTimeout(ref.current as ReturnType<typeof setTimeout>)
    if (e) {
      ref.current = loadDataOptions(field, e) as unknown as ReturnType<typeof setTimeout>
    }
  }

  return {
    t,
    handleSelectChange,
    courses,
    coursesLoading,
    adminData,
    adminLoading,
    handleInnerDrawerClose,
    drawer,
    setDrawer,
  }
}

export default useGroupDrawer
