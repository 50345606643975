import React, { ReactElement } from 'react'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import { ReactComponent as AttachmentIcon } from 'assets/document-attachment.svg'
import {
  StyledBottomBorder,
  StyledConditionsContainer,
  StyledDetailsContainer,
  StyledFlexRowContainer,
  StyledInDetailContainer,
  StyledOverviewContainer,
  StyledTaskDetailContainer,
  StyledTableContainer,
  styles,
  ActionsWrapper,
  StyledAttachmentContainer,
  StyledTabNavigationWrapper,
} from '../TaskLayout/styled-component'
import AppBar from '@mui/material/AppBar'
import {
  StyledConditionContainer,
  StyledConditionSpan,
  StyledConditionTypeNameSpan,
  StyledConditionTypeSpan,
} from '../styled-components'
import moment from 'moment'
import Grid from 'components/common/GridV2/Grid'
import BreadCrumbV2 from 'components/common/BreadCrumbV2'
import { Link } from 'react-router-dom'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Drawer from 'components/common/Drawer'
import TasksDrawer from '../TasksDrawer/TaskDrawer'
import TaskCommentsDrawer from '../CommentsDrawer/TaskCommentsDrawer'
import { ICourse } from 'pages/courses/courseList.interface'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { activeTabClassName, TabNavigation } from 'components/common/Tabs/Tabs'
import GroupsLayout from './groupsLayout/TaskGroupsLayout'
import useTaskDetails from 'hooks/tasks/useTaskDetails'
import { SplashScreen } from 'components/common'

const TaskDetails = (): ReactElement => {
  const {
    t,
    breadCrumbData,
    name,
    actions,
    description,
    courseId,
    moduleId,
    conditionType,
    trigger,
    triggerType,
    createDate,
    attachment,
    tabs,
    tab,
    handleTabChange,
    taskId,
    groups,
    totalCount,
    refetch,
    loading,
    companyId,
    students,
    config,
    studentAction,
    taskDrawerOpened,
    setTaskDrawerOpened,
    taskRefetch,
    mappedCourses,
    mappedGroups,
    loadingCourses,
    groupsLoading,
    setCurrentTaskId,
    taskCommentsDrawerOpened,
    setTaskCommentsDrawerOpened,
    taskComments,
    taskCommentsLoading,
  } = useTaskDetails()

  return (
    <>
      <BreadCrumbV2 data={breadCrumbData} />

      <StyledTaskDetailContainer>
        <StyledBottomBorder>
          <h1 style={styles.title}>{name}</h1>
          <ActionsWrapper>
            <GridActionMenu actionConfig={actions} button={<MoreIcon />} />
          </ActionsWrapper>
        </StyledBottomBorder>

        <StyledDetailsContainer>
          <StyledOverviewContainer>
            <h4>{t('general.overview')}</h4>
            <p style={styles.description}>{description}</p>
          </StyledOverviewContainer>
          <StyledConditionsContainer>
            <h4>{t('general.details')}</h4>
            <StyledFlexRowContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.conditionType')}:</p>
                {courseId ? (
                  <StyledConditionTypeSpan>Course</StyledConditionTypeSpan>
                ) : moduleId ? (
                  <StyledConditionTypeSpan>Module</StyledConditionTypeSpan>
                ) : (
                  <>Off</>
                )}
              </StyledInDetailContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.condition')}:</p>
                {conditionType ? (
                  <StyledConditionContainer>
                    <StyledConditionSpan>
                      {trigger?.startsWith('BEFORE') ? 'Before' : 'After'}
                    </StyledConditionSpan>
                    <span>-</span>
                    <StyledConditionTypeNameSpan>
                      {(conditionType as ICourse).name}
                    </StyledConditionTypeNameSpan>
                    <span>-</span> <StyledConditionSpan>{triggerType}</StyledConditionSpan>{' '}
                  </StyledConditionContainer>
                ) : (
                  '-'
                )}
              </StyledInDetailContainer>
            </StyledFlexRowContainer>
            <StyledFlexRowContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.create_date')}:</p>
                <p style={styles.content}> {moment(createDate).format('DD-MM-YYYY')} </p>
              </StyledInDetailContainer>
              <StyledInDetailContainer>
                {attachment && (
                  <>
                    <p style={styles.detailTitle}>{t('general.fileAttached')}:</p>
                    <StyledAttachmentContainer>
                      <AttachmentIcon />
                      <Link to={attachment?.link || ''} target='_blank' style={styles.fileTitle}>
                        {attachment.name}
                      </Link>
                    </StyledAttachmentContainer>
                  </>
                )}
              </StyledInDetailContainer>
            </StyledFlexRowContainer>
          </StyledConditionsContainer>
        </StyledDetailsContainer>
      </StyledTaskDetailContainer>

      <StyledTabNavigationWrapper>
        <TabNavigation>
          <AppBar position='static' style={styles.bar}>
            <Tabs value={tab} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} {...activeTabClassName(index)} />
              ))}
            </Tabs>
          </AppBar>
        </TabNavigation>
      </StyledTabNavigationWrapper>

      {tab === 0 && (
        <GroupsLayout
          taskId={taskId as string}
          groups={groups}
          totalGroupCount={totalCount}
          refetchGroups={refetch}
          groupsLoading={loading}
          companyId={companyId as string}
        />
      )}

      {tab === 1 &&
        (students?.length === 0 ? (
          <SplashScreen
            marginTop={20}
            subTitle={t('tasks_layout.no_students')}
            textMarginTop='40px'
            isSearchOrFilter={true}
          />
        ) : (
          <StyledTableContainer>
            <Grid
              data={students}
              config={config}
              actionConfig={studentAction}
              gridActionLabel={t('actions.activity')}
            />
          </StyledTableContainer>
        ))}

      <Drawer opened={taskDrawerOpened} totalWidth='630px'>
        <TasksDrawer
          taskId={taskId || ''}
          setTaskDrawerOpen={setTaskDrawerOpened}
          companyId={companyId}
          onTaskAdded={async (): Promise<void> => {
            await taskRefetch()
          }}
          mappedCourses={mappedCourses}
          mappedGroups={mappedGroups}
          coursesLoading={loadingCourses}
          groupsLoading={groupsLoading}
          setTaskId={setCurrentTaskId}
        />
      </Drawer>

      <Drawer opened={taskCommentsDrawerOpened} totalWidth='630px'>
        <TaskCommentsDrawer
          setDrawerOpened={setTaskCommentsDrawerOpened}
          data={taskComments}
          taskCommentsLoading={taskCommentsLoading}
        />
      </Drawer>
    </>
  )
}

export default TaskDetails
