import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import companiesService from '../services/companies/companiesService'
import useSnackbarAlert from '../hooks/useSnackbar'
import { ICompany } from 'pages/companies/CompanyDetails/types'

type SnackbarVariant = 'default' | 'error' | 'warning' | 'success' | 'info' | undefined

const useCompaniesService = (): {
  deleteCompany: (ids: string[]) => Promise<void>
  addCompany: (input: ICompany, callback?: () => void) => Promise<void>
  changeCompanyPlatformService: (id: string, platformService: boolean) => Promise<void>
  updateCompany: (
    companyId: string,
    company: ICompany,
    callback?: (company: ICompany) => void,
  ) => Promise<void>
  company: ICompany | null
  error: string
  checkCompanyEmail: (email: string) => Promise<string | null>
  removeCompanyAvatar: (avatar: string) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState<ICompany | null>(null)
  const { setSnackbar } = useSnackbarAlert()
  const {
    addCompanyMutation,
    changeCompanyPlatformServiceMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    checkCompanyEmailMutation,
    removeCompanyAvatarMutation,
  } = companiesService()

  const removeCompanyAvatar = async (avatar: string): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await removeCompanyAvatarMutation({
      variables: { avatar },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
      setError(errors[0].message)
    } else {
      message = t('messages.draft_company_image_deleted')
      variant = 'success'
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const addCompany = async (input: ICompany, callback?: () => void): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await addCompanyMutation({
      variables: { input },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.added')}`
      variant = 'success'

      if (callback) callback()
    }
    setLoading(false)
    setSnackbar({ message, variant })
  }

  const checkCompanyEmail = async (email: string): Promise<string | null> => {
    const res = await checkCompanyEmailMutation({
      variables: { email },
    })

    return res.errors ? res.errors[0].message : null
  }

  const changeCompanyPlatformService = async (
    id: string,
    platformService: boolean,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await changeCompanyPlatformServiceMutation({
      variables: { id, platformService },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('companies_layout.platform_service')} ${t(
        'messages.changed',
      )}`
      variant = 'success'
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const updateCompany = async (
    companyId: string,
    company: ICompany,
    callback?: (company: ICompany) => void,
  ): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { data, errors } = await updateCompanyMutation({
      variables: { id: companyId, input: company },
    })
    if (errors) {
      const error = errors[0]?.message
      message = error?.includes('already registered') ? error : t('messages.something_wrong')
      variant = 'error'
    } else if (data) {
      setCompany(data.updateCompany)
      message = `${t('general.company')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback(data.updateCompany)
    }

    setLoading(false)
    setSnackbar({ message, variant })
  }

  const deleteCompany = async (ids: string[]): Promise<void> => {
    let message = ''
    let variant: SnackbarVariant
    setLoading(true)

    const { errors } = await deleteCompanyMutation({
      variables: { ids },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.deleted')}`
      variant = 'success'
    }
    setLoading(false)
    setSnackbar({ message, variant })
  }

  return {
    deleteCompany,
    addCompany,
    changeCompanyPlatformService,
    updateCompany,
    company,
    error,
    checkCompanyEmail,
    removeCompanyAvatar,
    loading,
  }
}

export default useCompaniesService
