import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  width: 100%;
  gap: 20px;

  .selectField {
    width: 400px;
  }
`
export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderTop: '1px solid rgba(0,0,0,.2)',
  },
  border: {
    borderLeft: '1px solid #020210',
    borderBottom: '1px solid #020210',
    width: '16px',
    height: '16px',
  },
  paragraph: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#68707A',
  },
  header: {
    fontWeight: '700',
    lineHeight: '36px',
    color: '#020210',
  },
  btn: {
    color: '#000',
  },
}
