import React, { ReactElement } from 'react'
import _ from 'lodash'
import { ApolloQueryResult } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveIcon from '@mui/icons-material/Archive'
import { Wrapper } from '../../styled-components'
import Grid from 'components/common/GridV2'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import AddGroupDrawer from 'pages/group/AddGroupDrawer/AddGroupDrawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import { GroupData, ICompanyGroupsParams } from './companyGroups.interface'
import { useCompanyGroups } from './hooks'

const CompanyGroups = ({ variables, t, companyId }: ICompanyGroupsParams): ReactElement => {
  const {
    loading,
    selectedItem,
    currentPage,
    selectItem,
    selectAllItem,
    handleDeleteGroup,
    handleExportData,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleClick,
    state,
    searchValue,
    setDrawerOpened,
    drawerOpened,
    setFilterDrawer,
    filterDrawer,
    perPage,
    refetch,
    groups,
    config,
    actions,
  } = useCompanyGroups({ variables })

  if (loading) return <div>Loading...</div>
  return (
    <div style={{ paddingTop: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify='between' align='center'>
          <Wrapper align='center'>
            <GridMultipleActions
              selectedItems={selectedItem[currentPage] ? selectedItem[currentPage].length : 0}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
                  onClick: (): void => handleDeleteGroup(),
                  component: <DeleteIcon fontSize='small' />,
                },
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.export'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
                  onClick: (): void => handleExportData(),
                  component: <ArchiveIcon fontSize='small' />,
                },
              ].filter(i => !i.hide)}
            />
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              icon={
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              }
              value={searchValue}
              onChange={handleSearchChange}
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          data={groups.data}
          config={config}
          redirectToDetailsHandler={handleClick}
          actionConfig={actions}
          actionTilesLength={3}
          selectItem={selectItem}
          selectAllItem={selectAllItem}
          selected={_.size(selectedItem[currentPage] || [])}
          selectedItems={selectedItem[currentPage] || []}
          resetVisible={!!searchValue || !!filterDrawer?.filterValues}
        />

        {groups && groups.data && (
          <TablePagination
            currentPage={groups ? groups.currentPage : 0}
            rowsPerPage={perPage}
            count={groups ? groups.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      <Drawer
        opened={drawerOpened}
        toggleDrawer={(): void => setDrawerOpened(false)}
        totalWidth='750px'
      >
        <AddGroupDrawer
          afterSave={(): Promise<ApolloQueryResult<GroupData>> => refetch()}
          onClose={(): void => setDrawerOpened(false)}
          companyId={variables.filter.company?.value as string}
        />
      </Drawer>

      <Drawer
        opened={filterDrawer?.opened}
        toggleDrawer={(): void =>
          setFilterDrawer({ ...filterDrawer, opened: !filterDrawer?.opened })
        }
        totalWidth='600px'
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawer({ ...filterDrawer, opened: false })}
          setFilterOptions={setFilterDrawer}
          refetchGroup={(args): void => {
            refetch({
              filter: {
                ...variables.filter,
                ...args.filter,
              },
            })
          }}
          companyId={companyId}
          useCompanyId
          hasPurchasedCourses={true}
        />
      </Drawer>
    </div>
  )
}

export default CompanyGroups
