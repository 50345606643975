import gql from 'graphql-tag'

export const GET_ALL_SKILLS = gql`
  query GetAllSkills {
    getAllSkills {
      label
      id
    }
  }
`
export const GET_ALL_INDUSTRY = gql`
  query getAllIndustry {
    getAllIndustry {
      label
      id
    }
  }
`

export const GET_TAGS = gql`
  query getAllTags($companyId: String, $category: TagCategoryEnums) {
    getAllTags(companyId: $companyId, category: $category) {
      id
      label
    }
  }
`
