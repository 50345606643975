import React, { ReactElement } from 'react'
import { Button } from 'components/common/Button'
import { DrawerTitle, DrawerContent } from 'components/DrawerLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import { FormContainer, FormGroupWrapper } from 'components/common/Form'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import GroupItem from 'components/common/GroupItem/GroupItem'
import {
  CheckBoxFlex,
  CustomPadding,
  Grid,
  GridHeader,
  NoDataDiv,
  Wrapper,
} from './toolbar/styled-components'
import { Input } from 'components/common/TextInput'
import CheckBox from 'components/common/Checkbox'
import useAddGroupDrawer from 'hooks/tests/TestLayout/useAddGroupDrawer'
import { AddGroupsDrawerProps } from './AddGroupsDrawer.interface'
import Pagination from 'components/common/Pagination'
import {
  FormButtons,
  FormFooter,
} from '../questionsLayout/linkQuestionsToTestAssessmentDrawer/styled-components'
import MyLoader from 'loading/loading'
import { IGroup } from 'interfaces/groups'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { SelectedItemIndicator } from 'components/common'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const AddGroupsDrawer = ({
  companyId,
  hasSearchByCompany,
  onClose,
  testAssessmentId,
}: AddGroupsDrawerProps): ReactElement => {
  const {
    selectItem,
    selectedItems,
    isAllSelected,
    selectAllItem,
    searchText,
    onFormSubmit,
    handleCloseDrawer,
    filterDrawer,
    setFilterDrawer,
    groups,
    loading,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    refetch,
    handleSearchChange,
    t,
  } = useAddGroupDrawer({
    hasSearchByCompany,
    onClose,
    testAssessmentId,
  })

  return (
    <>
      <CustomDrawerHeader
        title={`${t('actions.add')} ${t('general.groups')}`}
        onClose={handleCloseDrawer}
      />
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <CustomPadding>
              <GridHeader>
                <DrawerTitle>{t('general.groups')}</DrawerTitle>
                <Wrapper>
                  <SelectedItemIndicator count={selectedItems?.length} />
                  <CheckBoxFlex>
                    <DrawerTitle>{t('general.select_all')}</DrawerTitle>
                    <CheckBox onChange={selectAllItem} checked={isAllSelected} />
                  </CheckBoxFlex>
                  <Input
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchText}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type='submit'>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                  />
                </Wrapper>
              </GridHeader>
              <Grid>
                {groups?.data.length > 0 ? (
                  groups.data.map((group: IGroup, index: number) => (
                    <GroupItem
                      item={group}
                      key={index}
                      selectItem={selectItem}
                      selectedItem={selectedItems}
                      isChecked={selectedItems.includes(group.id as string)}
                    />
                  ))
                ) : loading ? (
                  <MyLoader width={250} />
                ) : (
                  <NoDataDiv>{t('general.no_data')}</NoDataDiv>
                )}
              </Grid>
              <Drawer
                opened={filterDrawer.opened}
                toggleDrawer={(): void => undefined}
                totalWidth='600px'
              >
                <FilterDrawer
                  closeDrawer={(): void =>
                    setFilterDrawer({
                      ...filterDrawer,
                      opened: false,
                    } as IGroupFilterDrawerOptions)
                  }
                  setFilterOptions={setFilterDrawer}
                  refetchGroup={refetch}
                  setCurrentPage={(): void => undefined}
                  defaultFilter={{}}
                  companyId={companyId}
                  useCompanyId={true}
                />
              </Drawer>
            </CustomPadding>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                className='add-groups-button'
                text={t('actions.add')}
                type='small'
                color='secondary'
                onClick={onFormSubmit}
                background='#06C68F'
              />
              <Button
                text={t('actions.close')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
            {groups && (
              <Pagination
                currentPage={groups.currentPage || 1}
                totalPages={groups.totalCount ? Math.ceil(groups.totalCount / perPage || 1) : 0}
                handleChange={handlePaginationClick}
                perPage={perPage}
                handlePerPageChange={handleChangeRowsPerPage}
              />
            )}
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddGroupsDrawer
