import { useMutation } from '@apollo/client'
import { ADD_TASK_TO_GROUP, GET_TASKS_BY_GROUP } from 'gql/tasks.query'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import {
  GetTasksByGroupFiltersByEnums,
  IAddTaskToGroupResponse,
  IFetchTasksByCompanyIdProps,
  ITaskToGroupDto,
} from './getTasksService.interface'

export const useAddTaskToGroupService = ({
  groupId,
}: IFetchTasksByCompanyIdProps): IAddTaskToGroupResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [addTask, { data, error, loading }] = useMutation(ADD_TASK_TO_GROUP, {
    refetchQueries: [
      {
        query: GET_TASKS_BY_GROUP,
        variables: {
          groupId,
          searchValue: '',
          currentPage: 1,
          filterBy: GetTasksByGroupFiltersByEnums.IN_GROUP,
          perPage: 10,
          tags: [],
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleAddTaskToGroup = async ({ taskIds, groupId }: ITaskToGroupDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await addTask({
        variables: {
          taskIds,
          groupId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('tasks_layout.task_added')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    addTask: (createTaskDto: ITaskToGroupDto) => handleAddTaskToGroup(createTaskDto),
    tasks: (data && data.createTask) || [],
    loading,
    error: errorMessage,
  }
}
