import React from 'react'
// import { TriggerEnum } from './tasksDrawer.interface'
import // FormControlLabel,
//  Radio, RadioGroup
'@mui/material'
import {
  Container,
  DrawerContent,
  // FlexWrapper,
  // StyledSelectFieldContainer,
} from './styled-component'
import { FormField, FormFields, FormButtons, FormFooter } from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import Uploader from 'components/common/FileUploaderV2'
import { Button } from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
// import { ItemWrapper } from 'components/styled-components'
// import { SelectField } from 'components/common/SelectField'
// import Checkbox from 'components/common/Checkbox'
import { IUpdateTasksDrawerProps } from './tasksDrawer.interface'
import { Title } from 'components/DrawerLayout/styled-components'
import CreatableSelect from 'react-select/creatable'
import { IAttachment } from 'interfaces/common'
import { useTaskDrawer } from 'hooks/tasks/useTaskDrawer'
import MyLoader from 'loading/loading'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const TasksDrawer = ({
  taskId,
  setTaskDrawerOpen,
  companyId,
  onTaskAdded,
  // mappedCourses,
  // coursesLoading,
  setTaskId,
}: IUpdateTasksDrawerProps): React.ReactElement => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    handleFileChange,
    setFieldValue,
    touched,
    uploadedFile,
    loading,
    clearUploadedFile,
    acceptedTypes,
    errors,
    handleFileInputChange,
    isUpdating,
    values,
    submitDisabled,
    handleCloseDrawer,
    uploadVideoProgress,
    tagList,
    fileTypes,
    // mappedModules,
  } = useTaskDrawer(companyId, setTaskDrawerOpen, onTaskAdded, taskId, setTaskId)
  return (
    <>
      <Container>
        <CustomDrawerHeader
          title={isUpdating ? t('tasks_layout.update_task') : t('tasks_layout.add_task')}
          onClose={(): void => {
            setTaskDrawerOpen(false)
            setTaskId(null)
          }}
        />
        <DrawerContent>
          {loading && <MyLoader width={250} />}
          <FormFields>
            <FormField>
              <TextInput
                title={`${t('tasks_layout.task')} ${t('form_fields.name')}`}
                required
                label={''}
                size='small'
                touched={touched.name}
                error={!!errors.name}
                errorMessage={errors.name as string}
                placeholder={`${t('form_fields.name')}`}
                name='name'
                type='text'
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setFieldValue('name', e.target.value)
                }}
                value={values.name}
              />
            </FormField>
            <FormField>
              <Title>{t('form_fields.select_tags')} </Title>
              <CreatableSelect
                isMulti
                onChange={(value): void => {
                  setFieldValue('tags', value ? [...value] : [])
                }}
                options={tagList}
                value={values.tags}
                placeholder={t('form_fields.select_tags')}
              />
            </FormField>
            <FormField>
              <TextInput
                title={t('general.overview')}
                required
                label=''
                placeholder='Type note here'
                size='small'
                name='description'
                type='text'
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setFieldValue('description', e.target.value)
                }}
                error={!!errors.description}
                errorMessage={errors.description as string}
                touched={touched.description}
                multiline
                rows='4'
                value={values.description}
              />
            </FormField>
            <FormField>
              <Uploader
                label={t('actions.upload_file')}
                id='pdf'
                type={fileTypes as FileTypes}
                placeHolder={t('actions.file_upload_format3')}
                accept={acceptedTypes.join(', ')}
                inputValue={uploadedFile ? ((uploadedFile as IAttachment).name as string) : ''}
                uploadedFile={uploadedFile as IAttachment}
                deleteFile={clearUploadedFile}
                onFileChange={handleFileChange}
                onInputChange={handleFileInputChange}
                loading={
                  ['video', 'audio'].includes(fileTypes) && uploadVideoProgress > 0
                    ? uploadVideoProgress
                    : false
                }
              />
            </FormField>
            {/* <FormField>
              <RadioGroup
                name='moreOptions'
                value={values.radioValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setFieldValue('radioValue', e.target.value)
                }}
              >
                {process.env.REACT_APP_STAGE !== 'production' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.moreOptionsChecked}
                        onChange={(): void =>
                          setFieldValue('moreOptionsChecked', !values.moreOptionsChecked)
                        }
                        name='certificateIncluded'
                      />
                    }
                    label={t('tasks_layout.more_options')}
                  />
                )}

                {values.moreOptionsChecked ? (
                  <FlexWrapper animate={values.moreOptionsChecked}>
                    <ItemWrapper>
                      <FormControlLabel
                        value='onCourse'
                        control={<Radio color='primary' />}
                        label={t('general.onCourse')}
                        onClick={(): void => setFieldValue('radioValue', 'onCourse')}
                      />
                    </ItemWrapper>
                    {values.radioValue === 'onCourse' && (
                      <StyledSelectFieldContainer>
                        <SelectField
                          options={[
                            {
                              value: TriggerEnum.BEFORE_COURSE,
                              label: 'Before start ',
                            },
                            {
                              value: TriggerEnum.AFTER_COURSE,
                              label: 'After finish',
                            },
                          ]}
                          onChange={(value: { value: string; label: string }): void =>
                            setFieldValue('courseConditionSelected', value)
                          }
                          value={values.courseConditionSelected}
                        />
                        <SelectField
                          isLoading={coursesLoading}
                          options={mappedCourses}
                          onChange={(value): void => setFieldValue('courseSelected', value)}
                          value={values.courseSelected}
                        />
                      </StyledSelectFieldContainer>
                    )}

                    <ItemWrapper>
                      <FormControlLabel
                        value='onModule'
                        control={<Radio color='primary' />}
                        label={t('general.onModule')}
                        onClick={(): void => setFieldValue('radioValue', 'onModule')}
                      />
                    </ItemWrapper>
                    {values.radioValue === 'onModule' && (
                      <StyledSelectFieldContainer>
                        <SelectField
                          options={[
                            {
                              value: TriggerEnum.BEFORE_MODULE,
                              label: 'Before start',
                            },
                            {
                              value: TriggerEnum.AFTER_MODULE,
                              label: 'After finish',
                            },
                          ]}
                          onChange={(value: { value: string; label: string }): void =>
                            setFieldValue('moduleConditionSelected', value)
                          }
                          value={values.moduleConditionSelected}
                        />
                        <SelectField
                          options={mappedModules}
                          onChange={(value: { value: string; label: string } | null): void =>
                            setFieldValue('onModuleSelected', value)
                          }
                          value={values.onModuleSelected}
                        />
                      </StyledSelectFieldContainer>
                    )}
                  </FlexWrapper>
                ) : (
                  <></>
                )}
              </RadioGroup>
            </FormField> */}
          </FormFields>
        </DrawerContent>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.save')}
              type='small'
              onClick={handleSubmit}
              background='#06C68F'
            />
            <Button
              text={t('actions.cancel')}
              type='small'
              isDisabled={submitDisabled as boolean}
              onClick={handleCloseDrawer}
              background='#E0E1E2'
              textColor='#414141'
            />
          </FormButtons>
        </FormFooter>
      </Container>
    </>
  )
}

export default TasksDrawer
