import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { IUser } from 'interfaces/users'
import { ICourse, ICourseBriefData } from '../courseList.interface'
import { IGroup } from 'interfaces/groups'
import { IGroupBrief } from '../CourseTutors/tutorsLayout.interface'
import { Dispatch, SetStateAction } from 'react'

export enum ActionTypes {
  EDIT = 'edit',
  MODIFY_STUDENTS = 'modifyStudents',
  ASSIGN_TUTOR = 'assignTutor',
  DELETE = 'delete',
}

export interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin: IUser[]
  created: string
  status: GridStatusEnums
  companyId: string
  courses: ICourse[]
  company: Company
}

export interface Company {
  id: string
  name: string
}

export interface IEditCourseGridItem {
  id: string
  render: (item: IGroup) => JSX.Element
  numeric: boolean
  disablePadding: boolean
  label?: string
}

export interface ICourseGroupTab2Params {
  selectedGroup: IGroupBrief[]
  setSelectedGroup: Dispatch<SetStateAction<IGroupBrief[]>>
  handleDeleteCourse?: (isDelete: boolean) => void
  handleButtonClick?: (type: string) => void
  step?: number
  courseData: ICourseBriefData | ICourseBriefData[]
  editMode?: boolean
  handleChangeStep?: (step: number) => void
  manualCompanyId?: string | null
  hasSearchByCompany?: boolean
  hasPurchasedCourses?: boolean
  formFooterVisible?: boolean
}
