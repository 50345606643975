import { styled } from 'styled-components'

export const Ul = styled.ul`
  border-left: 3px solid rgba(89, 199, 144, 1);
  position: relative;
  top: 2px;
  padding-top: 10px;
`

export const Li = styled.li`
  position: relative;
  padding-top: 12px;
  background-color: white;
  border-radius: 14px;
  font-size: 16px;
  min-width: 140px;
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    width: 17px;
    vertical-align: middle;
    color: rgba(89, 199, 144, 1);
    position: absolute;
    left: -50px;
    top: 14px;
  }
`

export const Time = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #68707a;
  margin-right: 10px;
`

export const Top = styled.div`
  position: absolute;
  left: -13px;
  top: -4px;
  width: 17px;
  height: auto;
  .MuiSvgIcon-root {
    color: rgba(89, 199, 144, 1);
  }
`

export const Content = styled.span`
  font-size: 16px;
`

export const TimelineLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
`

export const Colon = styled.span`
  font-weight: 900;
  margin-right: 10px;
`
