import gql from 'graphql-tag'

export const GENERATE_VIDEO_UPLOAD_LINKS = gql`
  mutation GenerateVideoUploadLinks($videos: [Video], $modelName: String, $isNewVideo: Boolean) {
    generateVideoUploadLinks(videos: $videos, modelName: $modelName, isNewVideo: $isNewVideo) {
      uploadLinks
      video {
        id
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
        duration
      }
    }
  }
`

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: String, $path: String) {
    uploadImageLink(image: $image, path: $path) {
      link
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload, $path: String) {
    uploadFileLink(file: $file, path: $path) {
      link
      fileId
    }
  }
`

export const UPLOAD_CAPTIONS = gql`
  mutation UploadCaptions($file: Upload, $path: String) {
    uploadCaptions(file: $file, path: $path) {
      link
      fileId
    }
  }
`

export const UPLOAD_AUDIO = gql`
  mutation UploadAudio($file: Audio) {
    uploadAudio(file: $file) {
      link
      fileId
      authorizedLink
    }
  }
`
export const EXPORT_DATA = gql`
  mutation ExportData($action: String, $ids: [String], $companyId: String) {
    exportCsv(action: $action, ids: $ids, companyId: $companyId) {
      link
    }
  }
`

export const EXTRACT_TEXT = gql`
  mutation ExtractText($link: String) {
    extractText(link: $link) {
      text
    }
  }
`
