import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  display: inline-block;
  width: 92px;

  &.active {
    background: #e5f4ef;
    color: #00905e;

    div {
      background: #e5f4ef;
    }
  }

  &.suspended {
    color: red;
    background: #fdebea;
    div {
      background: red;
    }
  }

  &.pending {
    color: rgb(207, 207, 9);
    background: #fafae6;

    div {
      background: rgb(207, 207, 9);
    }
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);

    div {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  gap: 15px;

  form {
    width: 40%;
  }
`

export const SelectFieldContainer = styled.div`
  width: 40%;
`
export const StudentDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const StudentDetailsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  div:nth-child(2) {
    color: #68707a;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const LoaderContainer = styled.div`
  position: absolute;
  min-height: 300px;
  left: 50%;
`
