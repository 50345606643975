import { useQuery } from '@apollo/client'
import { GET_GROUPS_BY_TASK_ID } from 'gql/tasks.query'
import { IUseFetchGroupsByTaskProps, IUseFetchGroupsResponse } from './getTasksService.interface'

export const useFetchGroupsByTask = ({
  taskId,
  groupInclusion,
}: IUseFetchGroupsByTaskProps): IUseFetchGroupsResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_GROUPS_BY_TASK_ID, {
    variables: {
      taskId,
      groupInclusion,
    },
    skip: !taskId,
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''
  const {
    data: groups = [],
    totalCount = 0,
    currentPage = 1,
  } = (data && data.getGroupsByTaskId) || {}

  return {
    groups,
    totalCount,
    currentPage,
    loading,
    error: errorMessage,
    refetch,
    fetchMore: (variables: IUseFetchGroupsByTaskProps) => fetchMore({ variables }),
  }
}
