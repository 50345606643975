import React, { useRef } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import { useTranslation } from 'react-i18next'

import { FormButtons, ImageCropperContainer } from './styled-components'

import { Button } from 'components/common/Button'
import { CropperFileType } from 'interfaces/common'
import { IImageCropperParams } from './imageCropper.interface'

const ImageCropper = ({
  file,
  hideCropper,
  getCroppedFile,
}: IImageCropperParams): React.JSX.Element => {
  if (!file) return <></>
  const { t } = useTranslation()
  const cropper = useRef<ReactCropperElement>(null)
  const cropperFile = file as CropperFileType

  const finishCrop = (): void => {
    let croppedFile = null
    croppedFile = cropper?.current?.cropper.getCroppedCanvas().toDataURL()
    getCroppedFile(cropperFile.name || '', croppedFile as string)
    hideCropper()
  }

  return (
    <ImageCropperContainer>
      <Cropper
        ref={cropper}
        src={cropperFile.file}
        style={{ height: 500, width: 600 }}
        guides={false}
      />
      <FormButtons>
        <Button
          text={t('actions.cancel')}
          textColor='#414141'
          background='#e0e1e2'
          type='small'
          onClick={hideCropper}
        />
        <Button
          className='photo-save'
          text={t('actions.save')}
          color='secondary'
          background='#06c68f'
          type='small'
          onClick={(): void => finishCrop()}
        />
      </FormButtons>
    </ImageCropperContainer>
  )
}

export default ImageCropper
