import React, { ReactElement } from 'react'
import { Header, Wrapper, Title } from './styled-components'

import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ViewListIcon from '@mui/icons-material/ViewList'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from 'components/common/Checkbox'

import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Button } from 'components/common/Button'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import TextInput from 'components/common/TextInput/TextInput'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import { MultipleAction, MultipleActionTooltip } from 'components/common/Grid/styled-components'
import { useUserValue } from 'context/UserContext'
import { IAction } from 'components/common/GridV2'
import { IToolBarProps } from './toolbar.interface'

const Toolbar = ({
  fromModule = false,
  currentModule,
  actions,
  searchValue,
  gridStyle,
  setGridStyle,
  dataLength,
  selectedItem,
  selectAllItem,
  handleDeleteClick,
  handleSearchChange,
  handleSearchSubmit,
  openFilterDrawer,
  handleResetClick,
  filtered,
  canModify,
  handleCreateQuizClick,
  resetVisible,
  t,
}: IToolBarProps): ReactElement => {
  const [state] = useUserValue()
  return (
    <Header className={currentModule ? '' : `withBorder noMarginBottom`}>
      <Wrapper>
        {fromModule ? (
          <Wrapper>
            {currentModule && canModify && state.userPermission.addQuestion ? (
              <GridActionMenu
                actionConfig={actions as IAction[]}
                row={currentModule}
                icon={
                  <Button
                    text={t('general.questions')}
                    size='small'
                    color='secondary'
                    icon={<ArrowDropDownIcon />}
                    iconPosition='right'
                    background='#06C68F'
                  />
                }
              />
            ) : (
              <Title style={{ marginRight: 10 }}>{t('questions_layout.title')}</Title>
            )}
            {currentModule && canModify && state?.userPermission?.addQuiz && (
              <div style={{ marginLeft: 10, lineHeight: 0 }}>
                <Button
                  text={t('quizzes_layout.create_quiz')}
                  size='small'
                  color='secondary'
                  background='#06C68F'
                  onClick={handleCreateQuizClick}
                  isDisabled={dataLength === 0 ? true : false}
                />
              </div>
            )}
            {canModify && state?.userPermission?.deleteQuiz && (
              <>
                <GridMultipleActions
                  selectedItems={selectedItem?.length}
                  actions={[
                    {
                      id: 0,
                      color: 'secondary',
                      tooltipText: t('actions.delete'),
                      disabled: selectedItem?.length ? selectedItem.length < 1 : false,
                      onClick: (): void => {
                        if (handleDeleteClick) handleDeleteClick(selectedItem as string[])
                      },
                      component: <DeleteIcon fontSize='small' />,
                    },
                  ]}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dataLength > 0 && dataLength === selectedItem?.length}
                      disabled={dataLength === 0}
                      onChange={selectAllItem}
                    />
                  }
                  label={
                    dataLength === selectedItem?.length
                      ? t('general.diselect_all')
                      : t('general.select_all')
                  }
                />
              </>
            )}
          </Wrapper>
        ) : (
          <Wrapper>
            <Title>{t('questions_layout.title')}</Title>
            <GridMultipleActions
              selectedItems={selectedItem?.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  disabled: (selectedItem?.length && selectedItem.length < 1) || false,
                  onClick: (): void => {
                    if (handleDeleteClick) handleDeleteClick(selectedItem as string[])
                  },
                  component: <DeleteIcon fontSize='small' />,
                },
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataLength === selectedItem?.length}
                  disabled={dataLength === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                dataLength === selectedItem?.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          </Wrapper>
        )}

        <Wrapper>
          <MultipleAction>
            <IconButton
              disabled={resetVisible || (!filtered && !searchValue)}
              onClick={handleResetClick}
            >
              <SyncIcon fontSize='small' />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <MultipleAction>
            <IconButton onClick={openFilterDrawer}>
              <FilterIcon />
            </IconButton>
            <MultipleActionTooltip>{t('actions.filters')}</MultipleActionTooltip>
          </MultipleAction>
          <form style={{ marginLeft: 10, marginRight: 10 }} onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
            />
          </form>
          {setGridStyle && gridStyle && (
            <GridSwitchButtons>
              <button
                className={`${gridStyle === 'card' ? 'active' : ''}`}
                onClick={(): void => setGridStyle('card')}
              >
                <DashboardIcon />
              </button>
              <button
                className={`${gridStyle === 'list' ? 'active' : ''}`}
                onClick={(): void => setGridStyle('list')}
              >
                <ViewListIcon />
              </button>
            </GridSwitchButtons>
          )}
        </Wrapper>
      </Wrapper>
    </Header>
  )
}

export default Toolbar
