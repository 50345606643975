import { styled } from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 60px;
  position: relative;
  margin-top: 30px;
`

export const StyledExportContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 500px;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  transition: all 0.3s ease;
`

export const StyledExportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 18px;
  align-items: center;

  h3 {
    font-size: 18px;
    font-weight: 400;
  }
`

export const StyledExportContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const GroupExportOptions = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
`

export const ExportButtonContainer = styled.div`
  width: 100%;
  display: flex;
`
