import { FormContainer, FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import React, { useEffect, useState } from 'react'
import Drawer from 'components/common/Drawer'
import { useUserValue } from 'context/UserContext'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { useSwal } from 'hooks/useSwal'
import { EditProfileFields } from './EditProfileFields'
import { UserSchema } from 'helpers/validationSchemas'
import { useFormik } from 'formik'
import useEditUserService from 'hooks/users/useEditUserService'
import { Button } from 'components/common/Button'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IPersonalInformation } from './PersonalInformation.interface'
import { IPhoneFields } from 'interfaces/common'
import { IUser } from 'interfaces/users'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

interface FormData {
  firstName: string
}

const formData: FormData = {
  firstName: '',
}

const EditAdminProfile: React.FC<{
  setIsClickedOnEdit: React.Dispatch<React.SetStateAction<boolean>>
  initialValues: IPersonalInformation
}> = ({ setIsClickedOnEdit, initialValues }) => {
  const { t } = useTranslation()
  const [isDrawerOpen] = useState(true)
  const [state, dispatch] = useUserValue()
  const [generalError] = useState('')
  const { updateUser } = useEditUserService()

  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: UserSchema,
      onSubmit(values) {
        const data = { ...values }

        delete data.phoneFieldValue
        if (data.phoneFields && data.phoneFields.value) {
          data.phoneFields = {
            dialCode: data.phoneFields.value,
            code: data.phoneFields.label,
          }
        } else {
          data.phoneFields = null
        }

        if (!data.birthDate) data.birthDate = null
        updateUser(initialValues.id as string, data as IUser)
        handleDrawerClose()
      },
    })

  useEffect(() => {
    if (initialValues) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        birthDate,
        biography,
        note,
        phoneFields,
        phoneFieldValue,
        avatar,
      } = initialValues
      const formData: IPersonalInformation = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        birthDate: birthDate ? moment(parseInt(birthDate.toString())).format() : null,
        // ? moment(new Date(+birthDate)).format('DD-MM-YYYY')
        // :
        //  null
        biography,
        note,
        phoneFields:
          phoneFields && phoneFields.code
            ? ({
                label: phoneFields.code, // label should be something like +995
                value: phoneFields.dialCode, // value should be something like GE
              } as IPhoneFields)
            : undefined,
        phoneFieldValue: phoneFieldValue || null,
        avatar,
      }
      setValues({
        ...formData,
      })
    }
  }, [formData, initialValues])

  const handleDrawerClose = (): void => {
    setIsClickedOnEdit(false)
    dispatch({
      type: 'SET_CLOSE_DRAWER',
      payload: true,
    })
  }
  const { fireSwal } = useSwal()

  const handleCloseDrawer = (): void => {
    const { text } = state.closeDrawer
    if (!text) {
      handleDrawerClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
        onConfirm: () => {
          handleDrawerClose()
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
      })
    }
  }

  const handleValueChange = (e: string | React.ChangeEvent<HTMLInputElement>): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  return (
    <>
      <Drawer opened={isDrawerOpen} toggleDrawer={(): void => undefined}>
        <CustomDrawerHeader title={t('users_layout.edit_profile')} onClose={handleCloseDrawer} />
        <DrawerContent headerHeight={68}>
          <FormContainer
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <FormGroupWrapper>
              <EditProfileFields
                values={values}
                errors={errors}
                generalError={generalError}
                touched={touched}
                handleChange={handleValueChange}
                setFieldValue={setFieldValue}
                setValues={setValues}
              />
            </FormGroupWrapper>
            <FormFooter>
              <FormButtons>
                <Button
                  onClick={handleCloseDrawer}
                  background='#E0E1E2'
                  text={t('actions.cancel')}
                  textColor={'#414141'}
                  type='small'
                />
                <Button
                  onClick={(): void => handleSubmit()}
                  background='#06C68F'
                  text={t('actions.save')}
                  type='small'
                />
              </FormButtons>
            </FormFooter>
          </FormContainer>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default EditAdminProfile
