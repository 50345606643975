import React from 'react'
import { genderOptions } from 'components/UsersLayout/staticData'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormButtons,
  FormContainer,
  FormGroupWrapper,
  FormField,
  FormFields,
  FormFooter,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import TextInput from 'components/common/TextInput/TextInput'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker'
import { Button } from 'components/common/Button'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import useStudentForm from 'hooks/useStudentsForm/useStudentsForm'
import { IAddStudentsProps, IGroupOption } from './addStudent.interface'
import { ISelectFilterOption } from 'interfaces/common'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const AddStudent = ({
  title,
  role,
  drawerData,
  onClose,
  setFormData,
  group,
  setSelectedStudents,
}: IAddStudentsProps): JSX.Element => {
  const {
    loading,
    handleCloseDrawer,
    handleChange,
    handleSubmit,
    handleSelectChange,
    t,
    values,
    touched,
    errors,
    groupLoading,
    generalError,
    groupData,
    setFieldValue,
    onDateChange,
  } = useStudentForm({
    setFormData,
    drawerData,
    setSelectedStudents,
    role,
    group,
    onClose,
  })

  return (
    <>
      {loading && <Loader />}
      <CustomDrawerHeader title={title} onClose={handleCloseDrawer} />
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {/* <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField> */}
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.first_name')}
                  required={true}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size='small'
                  placeholder={`${t('user_details.first_name')}`}
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.last_name')}
                  required={true}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size='small'
                  placeholder={`${t('user_details.last_name')}`}
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  error={errors.email || generalError ? true : false}
                  required={true}
                  errorMessage={errors.email || generalError ? errors.email || generalError : ''}
                  touched={touched.email}
                  title={t('user_details.email')}
                  size='small'
                  placeholder={t('user_details.email')}
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  // label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  // title={t('user_details.phone')}
                  selectValue={values.phoneFields || null}
                  selectChange={(e: ISelectFilterOption | null): void =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel='Phone'
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: IGroupOption): void => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  // title={t('user_details.birthday')}
                  // label={t('user_details.birthday')}
                  touched={!!touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  name='birthDate'
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>
              {!drawerData.groupField && (
                <FormField width='100%'>
                  <Title $required>{t('groups_layout.select_group')}</Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: IGroupOption): void => setFieldValue('group', e)}
                    data={(groupData && groupData.getAllGroups.data) || []}
                    value={values.group}
                    label={t('groups_layout.select_group')}
                    loading={groupLoading}
                    labelDataKey='name'
                    valueDataKey='id'
                    error={errors.group as string}
                    errorMessage={errors.group as string}
                    touched={!!touched.group}
                  />
                </FormField>
              )}
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size='small'
                  placeholder={`${t('form_fields.job_title')}`}
                  name='jobTitle'
                  type='text'
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size='small'
                  placeholder={`${t('form_fields.location')}`}
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              {/* {!drawerData.groupField && (
                <FormField width="100%">
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: any) => setFieldValue('group', e)}
                    data={(groupData && groupData.getAllGroups.data) || []}
                    value={values.group}
                    label={t('form_fields.type_to_search_group')}
                    loading={groupLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    error={errors.group}
                    errorMessage={errors.group}
                    touched={touched.group}
                  />
                </FormField>
              )} */}
              <FormField>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  size='small'
                  placeholder={`${t('quiz_details.type_here')}`}
                  name='biography'
                  type='text'
                  multiline
                  rows='3'
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  title={`${t('user_details.note')}`}
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  size='small'
                  placeholder={`${t('user_details.note')}`}
                  name='note'
                  type='text'
                  multiline
                  rows='3'
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddStudent
