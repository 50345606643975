import { styled } from 'styled-components'

export const CheckBoxFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CustomPadding = styled.div`
  padding: 10px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

export const GridHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const NoDataDiv = styled.div`
  font-size: 20px;
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FormButtons = styled.div`
  display: flex;
  padding: 20px;
  gap: 10px;
`

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  & > :first-child {
    width: 30%;
  }

  & nav {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
  }

  & > :last-child {
    width: 70%;
  }
`
