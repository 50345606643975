export interface IExportTestAssessmentsScoreServiceParams {
  testAssessmentId: string
}

export interface IExportTestAssessmentsScoreServiceResponse {
  exportTestAssessmentsScore: {
    link: string
  }
}

export interface IExportTestAssessmentsScoreServiceReturn {
  exportTestAssessmentsScore: (testAssessmentId: string) => Promise<string | null>
  loading: boolean
  error?: string
}

export enum ExportGroupScoresOptionEnum {
  QUIZ = 'QUIZ',
  TEST = 'TEST',
  ASSESSMENT = 'ASSESSMENT',
}

export interface IExportGroupScoresServiceParams {
  groupId: string
  options: ExportGroupScoresOptionEnum[]
}

export interface IExportGroupScoresServiceResponse {
  exportGroupScore: {
    link: string
  }
}

export interface IExportGroupScoresServiceReturn {
  exportGroupScores: (variables: IExportGroupScoresServiceParams) => Promise<string | null>
  loading: boolean
  error?: string
}
