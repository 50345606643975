import React, { useState, useEffect, ReactElement } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery } from '@apollo/client'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { QuizSchema, IQTestSchema } from 'helpers/validationSchemas'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormContainer, FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { Button } from 'components/common/Button'
import { useEditQuizService } from 'hooks/quizzes/useEditQuizService'
import Step1 from './Step1'
import { IValidationSchema } from 'interfaces/common'
import { IEditQuizDrawerFormData, IEditQuizDrawerProps } from './editQuizDrawer.interface'
import { ITags } from 'components/common/Tags/tags.interface'
import removeKeys from 'utils/removeKeys'
import { IEditValue } from '../AddQuizDrawer/addQuizDrawer.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { IQuiz } from '../quiz.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const formData: IEditValue = {
  name: '',
  time: '',
  quizParticipationType: '',
  passRate: '',
  feedback: false,
  tags: [],
  IQTestType: '',
  randomization: false,
  isShowAnswersTurnedOn: true,
  randomizationNumber: 0,
  description: '',
}

const EditQuizDrawer = (props: IEditQuizDrawerProps): ReactElement => {
  const { t } = useTranslation()
  const validationSchema = props.data.IQTest ? IQTestSchema : QuizSchema
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [validation, setValidation] = useState<Yup.ObjectShape>(validationSchema)

  const [currentQuiz, setCurrentQuiz] = useState<IEditValue>(formData)

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: props.data.quizId,
    },
  })

  const { editQuiz } = useEditQuizService()
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: Yup.object(validation),
      onSubmit: (values: IEditValue) => {
        const data = { ...values }

        if (!data.time || data.time === 0) {
          data.time = 0
        }

        if (data.tags && data.tags.length > 0) {
          data.tags = data.tags.map((i: ITags) => ({
            label: i.label,
          })) as ITags[]
        }
        if (data.description) data.description = values.description

        if (!data.randomization) data.randomizationNumber = 0

        if (data.quizParticipationType === 'optional') delete data.passRate

        if (data.IQTestType)
          data.IQTestType = (values.IQTestType as ISelectDropDownOption).value as string

        if (props.data.IQTest) {
          data.IQTestType = 'initial'
        }

        editQuiz(props.data.quizId, data as IQuiz, onSuccess)
      },
    })

  const onSuccess = (): void => {
    props.onClose()
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = (): void => {
    const formData = removeKeys(values, ['quizParticipationType'])
    const currentQuizData = removeKeys(currentQuiz, ['quizParticipationType'])
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuiz',
        values: formData,
        compareTo: currentQuizData,
      },
    })
  }

  const handleQuizParticipationTypeChange = (value: string): void => {
    if (value === 'mandatory') {
      setValidation((validation: Yup.ObjectShape) => ({
        ...validation,
        passRate: Yup.number().required('Pass Rate is Required'),
      }))
    } else {
      setValidation((validation: Yup.ObjectShape) => ({
        ...validation,
        passRate: Yup.number().nullable(),
      }))
      delete errors.passRate
      delete touched.passRate
    }
    setFieldValue('quizParticipationType', value)
  }

  const handleRandomizationChange = (randomization: boolean): void => {
    if (randomization) {
      const maxNumber = data?.getQuizById?.questions?.length
      setFieldValue('randomizationNumber', maxNumber)
      setValidation((validation: IValidationSchema) => ({
        ...validation,
        randomizationNumber: Yup.number()
          .max(maxNumber, `${t('validations.number_of_questions')} ${maxNumber}`)
          .required(`${t('quiz_details.number_of_questions')} ${t('validations.required')}`),
      }))
    } else {
      // set validation same object without randomziaitonNumber
      setValidation((validation: Yup.ObjectShape) => ({
        ...validation,
        randomizationNumber: Yup.number().nullable(),
      }))
      delete errors.randomizationNumber
      values.randomizationNumber = ''
      delete touched.randomizationNumber
    }
    setFieldValue('randomization', randomization)
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (data && data.getQuizById) {
      const dataClone: Partial<IEditQuizDrawerFormData> = {}
      for (const key of Object.keys(formData) as (keyof IEditQuizDrawerFormData)[]) {
        dataClone[key] =
          key === 'tags' && data.getQuizById[key]
            ? data.getQuizById[key].map((i: { label: string; id: string }) => ({
                label: i.label,
                value: i.id,
              }))
            : data.getQuizById[key]
      }
      handleRandomizationChange(dataClone.randomization as boolean)
      handleQuizParticipationTypeChange(dataClone.quizParticipationType as string)
      setValues(dataClone as IEditQuizDrawerFormData)
      setCurrentQuiz(dataClone as IEditQuizDrawerFormData)
    }
  }, [data])

  return (
    <>
      <CustomDrawerHeader
        title={
          props.data.IQTest
            ? `${t('quizzes_layout.edit_iq_test')}`
            : `${t('quizzes_layout.edit_quiz')}`
        }
        onClose={handleCloseDrawer}
      />
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            {data && data.getQuizById && (
              <Step1
                quizData={data && data.getQuizById}
                errors={errors}
                values={values}
                touched={touched}
                handleChange={handleChange}
                handleQuizParticipationTypeChange={handleQuizParticipationTypeChange}
                handleRandomizationChange={handleRandomizationChange}
                setFieldValue={setFieldValue}
                type={props.type}
                companyId={props.data.companyId}
                questionListSize={data && data?.getQuizById?.questions?.length}
                t={t}
              />
            )}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />

              <Button
                text={t('actions.save')}
                type='small'
                // onClick={handleSubmit}
                background='#06C68F'
                btnType='submit'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditQuizDrawer
