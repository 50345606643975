import DatePicker from 'components/common/DatePicker/DatePicker'
import { FormField, FormFields } from 'components/common/Form'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { genderOptions } from 'components/UsersLayout/staticData'
import { IEditProfileFieldsProps, IGenderOption } from './EditProfileFields.interface'
import { IPersonalInformation } from './PersonalInformation.interface'
import { OptionTypeBase } from 'react-select'
import { IPhoneFields, ISelectFilterOption } from 'interfaces/common'

export const EditProfileFields: React.FC<IEditProfileFieldsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setValues,
}) => {
  const { t } = useTranslation()

  const [currentGender, setCurrentGender] = useState({})
  const onDateChange = (date: Date | null): void => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
      setValues(values as IPersonalInformation)
    }
  }

  useEffect(() => {
    const val = genderOptions.find((x: IGenderOption) => x.value === values.gender)
    val && setCurrentGender(val)
  }, [values.gender])

  const onGenderChange = (e: OptionTypeBase): void => {
    setCurrentGender(e)
    const gender = e.value
    values.gender = gender
    setValues(values as IPersonalInformation)
  }

  useEffect(() => {
    setFieldValue('phoneFieldValue', (values.phoneFields && values.phoneFields.label) || null)
    values.phoneFieldValue = values.phoneFields ? values.phoneFields.label : null
  }, [values.phoneFields])

  return (
    <div>
      <FormFields>
        <FormField width='48%'>
          <TextInput
            error={errors.firstName ? true : false}
            errorMessage={errors.firstName ? errors.firstName : ''}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            size='small'
            name='firstName'
            type='text'
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            error={errors.lastName ? true : false}
            errorMessage={errors.lastName ? errors.lastName : ''}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            size='small'
            name='lastName'
            type='text'
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={`${t('user_details.email')}*`}
            type='text'
            value={values.email}
            disabled
            size='small'
          />
        </FormField>
        <FormField width='48%'>
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields as IPhoneFields}
            selectChange={(e: ISelectFilterOption | null): void => {
              setFieldValue('phoneFields', e)
            }}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            errorMessage={(errors.phoneFields as string) || (errors.phone as string)}
            error={!!(errors.phoneFields || errors.phone)}
            touched={!!(touched.phoneFields || touched.phone)}
          />
        </FormField>
        <FormField width='48%'>
          <SelectField
            placeholder={t('user_details.gender')}
            options={genderOptions}
            value={currentGender}
            onChange={onGenderChange}
          />
        </FormField>
        <FormField width='48%'>
          <DatePicker
            label={t('user_details.birthday')}
            value={values.birthDate}
            onChange={onDateChange}
            error={!!errors.birthDate}
            errorMessage={
              errors.birthDate && `${t('user_details.birthday')} ${t(`validations.required`)}`
            }
            touched={touched.birthDate}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.job_title')}
            size='small'
            name='jobTitle'
            type='text'
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.location')}
            size='small'
            name='location'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='100%'>
          <TextInput
            label={t('user_details.biography')}
            size='small'
            name='biography'
            type='text'
            value={values.biography}
            onChange={handleChange}
            multiline
            rows='3'
          />
        </FormField>
        <FormField width='100%'>
          <TextInput
            label={t('user_details.note')}
            size='small'
            name='note'
            type='text'
            value={values.note}
            onChange={handleChange}
            multiline
            rows='3'
          />
        </FormField>
      </FormFields>
    </div>
  )
}
