import React, { ReactElement } from 'react'
import {
  ContainerContent,
  DetailsHeader,
  DetailsHeaderRight,
  WhiteBg,
  DetailsContentGrid,
  Overview,
  Details,
  Tags as TagsContainer,
  DetailsContainerGrid,
  DetailsContainerItem,
  TagsGrid,
  StyledTabNavigationWrapper,
  TestName,
  Label,
  SubLabel,
  PublishIndicator,
  SubTitle,
} from './styled-components'
import { MoreButton } from '../testItem/styled-components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { t } from 'i18next'
import AppBar from '@mui/material/AppBar'
import Breadcrumb from 'components/common/BreadCrumbV2'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { activeTabClassName, TabNavigation } from 'components/common/Tabs/Tabs'
import GroupsLayout from './groupsLayout/GroupsLayout'
import QuestionsLayout from './questionsLayout/QuestionsLayout'
import StudentsLayout from './studentsLayout/StudentsLayout'
import MyLoader from 'loading/loading'
import { loaderStyles } from 'pages/courses/CourseQuizzes/styled.components'
import Tags from 'components/common/Tags/Tags'
import useTestDetails from './useTestDetails'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { ITags } from 'components/common/Tags/tags.interface'
import { styles } from 'pages/tasks/TaskLayout/styled-component'

const TestDetails = (): ReactElement => {
  const {
    tab,
    handleTabChange,
    testAssessment,
    testAssessmentLoading,
    testAssessmentError,
    testAssessmentGroupsLoading,
    testAssessmentGroupsRefetch,
    groups,
    actions,
    breadCrumbData,
    tabs,
    totalGroupCount,
    convertTextToEditorState,
  } = useTestDetails()

  return (
    <>
      <Breadcrumb data={breadCrumbData} />
      {!testAssessment && testAssessmentLoading && (
        <MyLoader width={250} speed={0.8} style={loaderStyles} />
      )}
      {testAssessmentError && <div>{`${testAssessmentError}`}</div>}
      {testAssessment && (
        <>
          {/* {testAssessment.attachment && (
            <CoverImageContainer>
              <img src={testAssessment.attachment.link} alt="" />
            </CoverImageContainer>
          )} */}
          <ContainerContent>
            <WhiteBg>
              <DetailsHeader>
                <TestName>{testAssessment.name}</TestName>
                <DetailsHeaderRight>
                  <PublishIndicator $published={testAssessment.published || false}>
                    {testAssessment.published
                      ? t('courses_layout.published')
                      : t('courses_layout.unpublished')}
                  </PublishIndicator>
                  <MoreButton>
                    <GridActionMenu actionConfig={actions} row={testAssessment} />
                  </MoreButton>
                </DetailsHeaderRight>
              </DetailsHeader>
              <DetailsContentGrid>
                <Overview>
                  <Label>{t('course_details.overview')}</Label>
                  <TextEditor
                    isVisible={false}
                    text={convertTextToEditorState(
                      testAssessment.description || t('general.no_data'),
                    )}
                    hasControls={false}
                    defaultText={t('general.no_data')}
                  />
                </Overview>
                <Details>
                  <Label>{t('general.details')}</Label>
                  <DetailsContainerGrid>
                    <DetailsContainerItem>
                      <SubTitle>{t('general.questions')}</SubTitle>
                      <SubLabel>{testAssessment.questions?.length || 0}</SubLabel>
                    </DetailsContainerItem>
                    <DetailsContainerItem>
                      <SubTitle>{`${t('quiz_details.participation')} ${t(
                        'general.type',
                      )}`}</SubTitle>
                      <SubLabel>{testAssessment.quizParticipationType as string}</SubLabel>
                    </DetailsContainerItem>
                    <DetailsContainerItem>
                      <SubTitle>{t('quiz_details.pass_rate')}</SubTitle>
                      <SubLabel>{testAssessment.passRate || 0}%</SubLabel>
                    </DetailsContainerItem>
                    <DetailsContainerItem>
                      <SubTitle>{t('quiz_details.randomize')}</SubTitle>
                      <SubLabel>
                        {testAssessment.randomize ? t('general.yes') : t('general.no')}
                      </SubLabel>
                    </DetailsContainerItem>
                    <DetailsContainerItem>
                      <SubTitle>{t('general.type')} </SubTitle>
                      <SubLabel>
                        {testAssessment.timeType
                          ? (testAssessment.timeType as string)
                          : t('general.no_data')}
                      </SubLabel>
                    </DetailsContainerItem>
                    <DetailsContainerItem>
                      <SubTitle>{t('tests_layout.total_questions')}</SubTitle>
                      <SubLabel>{testAssessment.questions?.length || 0}</SubLabel>
                    </DetailsContainerItem>
                  </DetailsContainerGrid>
                </Details>
                <TagsContainer>
                  <Label>{t('general.tags')}</Label>
                  <TagsGrid>
                    <Tags tags={testAssessment.tags as ITags[]} />
                  </TagsGrid>
                </TagsContainer>
              </DetailsContentGrid>
            </WhiteBg>
            <StyledTabNavigationWrapper>
              <TabNavigation>
                <AppBar position='static' style={styles.bar}>
                  <Tabs value={tab} onChange={handleTabChange}>
                    {tabs.map((tab, index) => (
                      <Tab key={index} label={tab.label} {...activeTabClassName(index)} />
                    ))}
                  </Tabs>
                </AppBar>
              </TabNavigation>
            </StyledTabNavigationWrapper>
            {tab === 0 && (
              <GroupsLayout
                testAssessmentId={testAssessment.id as string}
                groups={groups}
                totalGroupCount={totalGroupCount}
                refetchGroups={testAssessmentGroupsRefetch}
                groupsLoading={testAssessmentGroupsLoading}
                companyId={testAssessment.companyId as string}
              />
            )}
            {tab === 1 && <StudentsLayout testAssessmentId={testAssessment.id as string} />}
            {tab === 2 && (
              <QuestionsLayout
                testAssessment={testAssessment}
                companyId={testAssessment.companyId as string}
              />
            )}
          </ContainerContent>
        </>
      )}
    </>
  )
}

export default TestDetails
