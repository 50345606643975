import { ReactElement, SVGProps } from 'react'
import { ILink } from './modules'
import * as Yup from 'yup'

export interface IPhoneFields {
  label?: string
  value?: string
  dialCode?: string
  code?: string
}
export type T = (key: string) => string

export interface IFilterDropDownOption {
  value: string
}

export const fileTypeConstant = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file: 'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
  image: 'image/jpeg|image/png|image/gif|image/bmp',
}

export interface IMessageResponse {
  message: string
}

export interface IBreadCrumbItem {
  label: string
  icon?: JSX.Element
}

export enum SnackbarVariants {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface IAttachment {
  _id?: string
  id?: string
  link?: string
  links?: ILink[]
  uploading?: boolean
  name?: string
  title?: string
  size?: number | string
  lastModified?: number
  value?: string
  type?: string
  fileType?: string
  __typename?: string
}

export interface IFilterableField {
  type: string
  value: string | string[]
}

export interface IFilterableNestedField {
  type: string
  value: string | string[]
  nestedField?: string
}
export interface IFilterableNestedArrayField {
  type: string
  value: string[]
  nestedField: string
}

export interface IFilterableRangeField {
  type: string
  min: number
  max: number
}

export interface ITagsInterface {
  label?: string
  id?: string
}

export interface ISelectFilterOption {
  value: string
  label?: string | ReactElement
}

export type CropperFileType = { name?: string; file?: string; link?: string }
export type CropperResultType = CropperFileType | string

export interface ISvgProps extends SVGProps<SVGSVGElement> {
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
}

export interface IValidationSchema {
  passRate?: Yup.NumberSchema<number>
  name?: Yup.StringSchema<string>
  tags?: Yup.ArraySchema<{ label: string }[], Yup.AnyObject>
  description?: Yup.StringSchema<string>
  time?: Yup.NumberSchema<number>
  position?: Yup.StringSchema<string>
  quizParticipationType?: Yup.StringSchema<string>
  isShowAnswersTurnedOn?: Yup.BooleanSchema<boolean>
  IQTestType?: Yup.StringSchema<string>
  parentName?: Yup.StringSchema<string>
}
