import React, { ReactElement } from 'react'
import { useFormik } from 'formik'
import { SkilTestDetailsFieldTitle } from './styled-components'
import { SkillTestVideoSchema } from 'helpers/validationSchemas'
import useAddSkillTestVideoService from 'hooks/modules/useAddSkillTestVideoTemplateService'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { ISkillTestVideo } from '../SkillTestVideos.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const initialValues: ISkillTestVideo = {
  title: '',
  description: '',
  tips: '',
}

const AddSkillTestVideoDrawer = ({
  onClose,
  data,
}: {
  onClose: () => void
  data: { moduleId: string }
}): ReactElement => {
  const { addSkillTestVideoTemplate, addSkillTestVideoTemplateLoading } =
    useAddSkillTestVideoService({ moduleId: data.moduleId })
  const { handleSubmit, setFieldValue, values, handleChange, errors, touched } = useFormik({
    initialValues,
    validationSchema: SkillTestVideoSchema,
    onSubmit(values) {
      addSkillTestVideoTemplate(data.moduleId, values, () => onClose())
    },
  })

  return (
    <>
      {addSkillTestVideoTemplateLoading && <Loader />}
      <CustomDrawerHeader title={'Create Skill Test Video Template'} onClose={onClose} />
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  label='Title'
                  name='title'
                  type='text'
                  value={values.title}
                  onChange={handleChange}
                  error={errors.title ? true : false}
                  errorMessage={errors.title}
                  touched={touched.title}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <TextInput
                  label='Skill Test Video Overview'
                  name='description'
                  aria-label='minimum height'
                  multiline
                  rows='3'
                  value={values.description}
                  onChange={handleChange}
                  icon={<></>}
                />
              </FormField>
              <FormField>
                <SkilTestDetailsFieldTitle>Skill Test Video Tips</SkilTestDetailsFieldTitle>
                <TextEditor
                  text={JSON.stringify({
                    blocks: [
                      {
                        key: '1gm3s',
                        text: '',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                      },
                    ],
                    entityMap: {},
                  })}
                  editorTitle='Skill Test Video Overview Text'
                  handleChange={(value): void => {
                    setFieldValue('tips', value)
                  }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text='Cancel'
                type='small'
                onClick={onClose}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text='Save'
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddSkillTestVideoDrawer
