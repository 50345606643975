import { useQuery } from '@apollo/client'
import { useUserValue } from 'context/UserContext'
import { GET_MODULES } from 'gql/modules.query'
import { useSwal } from 'hooks/useSwal'
import { IFilterData } from 'pages/modules/FilterDrawer/filterDrawer.interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import {
  IModuleItem,
  IUseAddModuleToQuestionDrawerProps,
  IUseAddModuleToQuestionDrawerReturnType,
} from '../AddModulesToQuestionDrawer/addModuleToQuestionDrawer.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

const useAddModuleToQuestionDrawer = ({
  closeDrawer,
  selectedModules,
  setSelectedModules,
}: IUseAddModuleToQuestionDrawerProps): IUseAddModuleToQuestionDrawerReturnType => {
  const filterOption: IFilterData = {
    name: '',
    coverImage: { label: 'All', id: 0, value: 'all' },
    video: { label: 'All', id: 0, value: 'all' },
    attachment: { label: 'All', id: 0, value: 'all' },
    range: [0, 5000],
  }

  const { t } = useTranslation()
  const { data: modulesData, loading, refetch } = useQuery(GET_MODULES)

  const { fireSwal } = useSwal()
  const [, dispatch] = useUserValue()
  const [filterData, setFilterData] = useState<IFilterData>(filterOption)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItems.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1))
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      )
    }

    if (newSelected.length > 0) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }

    setSelectedItems(newSelected)
  }

  const handleCloseDrawer = (): void => {
    if (selectedItems.length === 0) {
      closeDrawer()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        onConfirm: () => closeDrawer(),
      })
    }
  }

  const handleSubmit = (): void => {
    const moduleList = modulesData.allModules.reduce(
      (modules: { moduleId: string; name: string }[], item: IModuleItem) => {
        if (selectedItems.includes(item.id)) {
          modules.push({
            moduleId: item.id,
            name: item.name,
          })
        }
        return modules
      },
      [] as { moduleId: string; name: string }[],
    )

    setSelectedModules([...selectedModules, ...moduleList])
    closeDrawer()
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
        },
      })
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    refetch({
      filter: {
        name: {
          type: 'match',
          value: searchValue,
        },
      },
    })
  }

  const handleResetClick = (): void => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
      },
    })
    setFiltered(false)
    setSearchValue('')
  }

  const handleVideoModal = (item?: IModule): void => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data:
        !videoModal.modal && item && item.video
          ? ((item.video as IVideo[])[0] as IVideo).links[2].url
          : '',
    })
  }

  const selectedModuleIds = selectedModules.map(module => module.moduleId) as string[]

  return {
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    refetch,
    loading,
    modulesData,
    handleVideoModal,
    selectItem,
    selectedModuleIds,
    selectedItems,
    handleResetClick,
    handleSearchSubmit,
    handleSearchChange,
    searchValue,
    handleSubmit,
    handleCloseDrawer,
    filtered,
    setFiltered,
    videoModal,
    t,
  }
}

export default useAddModuleToQuestionDrawer
