import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useTranslation } from 'react-i18next'
import { IUseTestLayoutServices, IUseTestLayoutServicesReturn } from './testLayout.interface'
import useDeleteTestService from './useDeleteTestService'
import usePublishUnpublishTestService from './usePublishUnpublishTestService'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import { testListAction, testListConfig } from 'pages/tests/testLayout/gridConfig'
import { useNavigate } from 'react-router-dom'
import { actionHandler } from 'pages/tests/testItem/TestItem'
import { useUserValue } from 'context/UserContext'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'

const useTestLayoutServices = ({
  companyId,
  selectedItem,
  setSelectedItem,
  currentPage,
  setCurrentPage,
  filterData,
  refetch,
  setIsSearchType,
}: IUseTestLayoutServices): IUseTestLayoutServicesReturn => {
  const { fireSwal } = useSwal()
  const { t } = useTranslation()
  const { deleteTest } = useDeleteTestService()
  const [searchText, setSearchText] = useState<string>('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [isGridView, setIsGridView] = useState(true)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const navigate = useNavigate()
  const [state] = useUserValue()

  const handleOpenEditDrawer = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editTest', true, {
      id,
    })
  }

  // Select Item handler cross pages
  const selectItem = (id: string): void => {
    // Retrieve the currently selected items on the current page
    const selectedItemsOnPage = selectedItem[currentPage] || []

    // Determine the new selection state:
    // If the item is already selected, remove it; otherwise, add it to the selection
    const newSelected = selectedItemsOnPage.includes(id)
      ? selectedItemsOnPage.filter(itemId => itemId !== id)
      : [...selectedItemsOnPage, id]

    // Update the selected items for the current page
    const updatedSelectedItem = {
      ...selectedItem,
      [currentPage]: newSelected,
    }

    // Update the state with the new selection
    // Flatten the selected items across all pages to maintain a single list of selected items
    setSelectedItem(updatedSelectedItem)
    setSelectedItems(Object.values(updatedSelectedItem).flat())
  }

  // Selects or deselects all items on the current page
  const selectAllItem = (allItemIds: string[]): void => {
    // Retrieve the currently selected items on the current page
    const selectedItemsOnPage = selectedItem[currentPage] || []

    // Determine new selection state: if all items are already selected, deselect all; otherwise, select all
    const newSelectedItems = selectedItemsOnPage.length === allItemIds.length ? [] : allItemIds

    // Update the selected items for the current page
    const updatedSelectedItem = {
      ...selectedItem,
      [currentPage]: newSelectedItems,
    }

    // Update the state with the new selection
    setSelectedItem(updatedSelectedItem)
    setSelectedItems(Object.values(updatedSelectedItem).flat())
  }
  // Configures the test list with translation and item selection functionality
  const testListConfiguration = testListConfig(t, selectItem)

  // Defines actions for the test list, including publish, edit, and delete operations
  const handleAction = (event: string, field: IQuiz): void =>
    actionHandler(event, field, handlePublish, handleOpenEditDrawer, handleDeleteClick)
  // Defines actions for the test list, including publish, edit, and delete operations
  const actions = testListAction(handleAction, state.userPermission, t, setIsSearchType)

  // Navigates to the test details page for a specific test ID
  const redirectToTest = (testId: string): void => {
    navigate(`/tests/${testId}`)
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchText: string) => {
      const searchValue = { type: UpperCaseFilterableFieldType.MATCH, value: newSearchText }
      refetch({
        filter: {
          name: searchValue,
        },
        currentPage: 1,
        perPage,
      })
    }, 500),
    [refetch, perPage],
  )

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setIsSearchType(true)
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
  }
  // Search Submit Handler
  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const searchValue = { type: 'MATCH', value: searchText }
    setCurrentPage(1)
    refetch({
      filter: {
        name: searchValue,
      },
      currentPage: 1,
      perPage,
    })
  }

  const handleDeleteManyClick = (ids: string[]): void => {
    fireSwal({
      title: t('popups.delete_test_many'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteTest(ids)
        handlePaginationClick(null, currentPage)
        setSelectedItem({})
        refetch({
          companyId,
          currentPage,
          perPage,
        })
      },
    })
  }

  const handleDeleteClick = (id: string): void => {
    fireSwal({
      title: t('popups.delete_test'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        handlePaginationClick(null, currentPage)
        deleteTest([id])

        // The current state of selectedItems and the id to be deleted are used to update the selection
        // Filter out the item to be deleted from the selectedItems array
        const updatedSelectedItems = selectedItems.filter(itemId => itemId !== id)

        // Create a new object for selectedItem with the current page's updated selected items
        const updatedSelectedItem = {
          ...selectedItem,
          [currentPage]: updatedSelectedItems,
        }

        // Update the state with the new selected items and selectedItem object
        setSelectedItems(updatedSelectedItems)
        setSelectedItem(updatedSelectedItem)

        refetch({
          companyId,
          currentPage,
          perPage,
        })
      },
    })
  }

  // Function to check if an item is selected
  const isItemSelected = (id: string): boolean => {
    return selectedItems.includes(id)
  }

  useEffect(() => {
    setIsFiltered(checkIfFiltered(filterData.filterOptions || {}))
  }, [filterData.filterOptions])

  const { publishUnpublishTestAssessment } = usePublishUnpublishTestService()

  const handlePublish = (testAssessment: IQuiz): void => {
    fireSwal({
      title: testAssessment.published
        ? t('tests_layout.unpublish_test')
        : t('tests_layout.publish_test'),
      confirmText: testAssessment.published
        ? t('popups.confirm_unpublish')
        : t('popups.confirm_publish'),
      onConfirm: () => {
        publishUnpublishTestAssessment(testAssessment.id as string, !testAssessment.published).then(
          () => {
            refetch({
              companyId,
              currentPage,
              perPage,
              filter: { ...filterData.filterOptions },
            })
          },
        )
      },
    })
  }

  const handleDrawerOpen = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addTest', true, {
      onSuccess: () => {
        refetch({
          currentPage: 1,
          perPage,
          companyId,
        })
        setSelectedItem({})
      },
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    setCurrentPage(1)
    refetch({
      filter: {
        ...filterData.filterOptions,
      },
      currentPage: 1,
      perPage: newPerPage,
    })
  }

  const handlePaginationClick = (_: ChangeEvent<unknown> | null, value: number): void => {
    setCurrentPage(value)
  }

  return {
    selectItem,
    onSearchSubmit,
    handleDeleteManyClick,
    handleDeleteClick,
    handlePublish,
    handleDrawerOpen,
    handlePaginationClick,
    onSearchChange,
    searchText,
    setSearchText,
    isFiltered,
    isGridView,
    setIsGridView,
    selectAllItem,
    testListConfiguration,
    actions,
    redirectToTest,
    selectedItems,
    isItemSelected,
    handleChangeRowsPerPage,
    perPage,
  }
}

export default useTestLayoutServices
