import React, { ReactElement } from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { Wrapper } from '../../styled-components'
import Drawer from 'components/common/Drawer'
import Grid from 'components/common/Grid'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterCoaches } from 'pages/coaches'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import { useCompanyTutors } from './hooks'
import { ICompanyTutorsParams } from './companyTutors.interface'

const CompanyTutors = ({ variables, currentTab, t }: ICompanyTutorsParams): ReactElement => {
  const {
    filterData,
    filterOptions,
    setFilterOptions,
    loading,
    users,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleResetClick,
    handlePushNotificationSend,
    handleModalClose,
    pushNotificationModal,
    messageData,
    isResetPasswordOpen,
    setResetPasswordOpen,
    userId,
    selectedItem,
    selectAllItem,
    redirectToProfile,
    config,
    actions,
    perPage,
    searchValue,
    filterDrawerOpened,
    currentPage,
    setFilterDrawerOpened,
    multiActions,
    groupAdminPolicy,
    setMessageData,
    openAddDrawer,
  } = useCompanyTutors({ variables, currentTab })

  if (loading) return <div>Loading...</div>

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <FilterCoaches
          role='COACH'
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify='between' align='center'>
          <Wrapper align='center'>
            {groupAdminPolicy && (
              <>
                <Button
                  text={`Create Tutor`}
                  color='secondary'
                  onClick={openAddDrawer}
                  icon={<AddCircleRoundedIcon />}
                  background='#06C68F'
                />
                <GridMultipleActions
                  selectedItems={selectedItem[currentPage] ? selectedItem[currentPage].length : 0}
                  actions={multiActions()}
                />
              </>
            )}
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon onClick={handleSearchSubmit} />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          title={t('general.users')}
          onFiltersClick={(): void => setFilterDrawerOpened(true)}
          resetVisible={true}
          resetFilters={handleResetClick}
          config={config}
          data={users.data}
          actionConfig={!groupAdminPolicy ? null : actions}
          selected={_.size(selectedItem[currentPage])}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          redirectToProfile={redirectToProfile}
        />

        {users && users.totalCount > 0 && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      {isResetPasswordOpen && (
        <ResetUsersPassword
          isModalOpen={isResetPasswordOpen}
          setModalOpen={setResetPasswordOpen}
          userId={userId}
        />
      )}
      <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
        <SendPushNotification
          messageData={messageData}
          setMessageData={setMessageData}
          onClose={handleModalClose}
          onSend={handlePushNotificationSend}
        />
      </Modal>
    </div>
  )
}

export default CompanyTutors
