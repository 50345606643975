import { useMutation } from '@apollo/client'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import {
  GET_TEST_ASSESSMENTS_BY_GROUP_ID,
  REMOVE_TEST_ASSESSMENTS_TO_GROUP_BY_ID,
} from 'gql/tests/tests.query'
import {
  GetTestsByGroupFiltersByEnums,
  IRemoveTestToGroupResponse,
  IFetchTestsByCompanyIdProps,
  ITestToGroupDto,
} from 'services/tests/getTestsService.interface'

export const useRemoveTestsFromGroupService = ({
  groupId,
}: IFetchTestsByCompanyIdProps): IRemoveTestToGroupResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [removeTest, { data, error, loading }] = useMutation(
    REMOVE_TEST_ASSESSMENTS_TO_GROUP_BY_ID,
    {
      refetchQueries: [
        {
          query: GET_TEST_ASSESSMENTS_BY_GROUP_ID,
          variables: {
            groupId,
            searchValue: '',
            currentPage: 1,
            filterBy: GetTestsByGroupFiltersByEnums.IN_GROUP,
            perPage: 12,
          },
        },
      ],
    },
  )
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleRemoveTestFromGroup = async ({
    testIds,
    groupId,
  }: ITestToGroupDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await removeTest({
        variables: {
          testAssessmentIds: testIds,
          groupId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('tests_layout.test_removed')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    removeTestsFromGroup: (removeTestDto: ITestToGroupDto) =>
      handleRemoveTestFromGroup(removeTestDto),
    tests: (data && data.createTest) || [],
    loading,
    error: errorMessage,
  }
}
