import React, { ReactElement } from 'react'

import { IModalParams } from './modal.interface'
import { StyledModal, styles } from './Modal.styles'

const CustomModal = ({ isOpened, onClose, children }: IModalParams): ReactElement => {
  return (
    <StyledModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      style={styles.modal}
      open={isOpened}
      onClose={onClose}
      closeAfterTransition
    >
      {children}
    </StyledModal>
  )
}

export default CustomModal
