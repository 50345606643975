import React from 'react'
import config from 'config'
import CircularProgress from '@mui/material/CircularProgress'

import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { DetailsContentVideoCover, VideoProcessingWrapper } from 'components/common/DetailsLayout'
import {
  CourseContentWrapper,
  CourseContentLeft,
  CourseContentRight,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseTitle,
  CourseContentDescription,
  CourseContentReview,
  CourseContentPlayIcon,
  CourseContentDetailsList,
} from 'components/courseDetail/styled-component'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import AudioPlayer from 'components/common/AudioPlayer'
import HeadsetIcon from '@mui/icons-material/Headset'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { Label, ResourceContainer } from './styled-components'
import { useLessonDetails } from './hooks'

const LessonDetails = (): React.JSX.Element => {
  const {
    breadCrumbData,
    lessonData,
    descriptionEdit,
    setDescriptionEdit,
    t,
    canModify,
    editEditorText,
    transcriptEdit,
    setTranscriptEdit,
    video,
    audio,
    document,
    hasConverted,
    mediaModal,
    setMediaModal,
    downloadActions,
    action,
    state,
  } = useLessonDetails()

  return (
    <div>
      <Breadcrumb data={breadCrumbData} />
      <CourseContentWrapper>
        <CourseContentLeft>
          <CourseContentHeader>
            <Thumbnail width={100} height={100} imageSrc={''} />

            <CourseContentHeaderSection>
              <CourseTitle>{lessonData.name}</CourseTitle>
            </CourseContentHeaderSection>
          </CourseContentHeader>

          <CourseContentDescription>
            {lessonData.description && (
              <TextEditor
                text={lessonData.description}
                isVisible={descriptionEdit}
                hasControls={true}
                editorTitle={t('lesson_details.lesson_overview')}
                closeEditMode={(): void => setDescriptionEdit(false)}
                openEditMode={(): void => setDescriptionEdit(true)}
                onSave={(editorText: string): void => editEditorText(editorText, 'description')}
                canEdit={!!state.userPermission.editLesson && canModify}
                defaultText={t('default_texts.lesson')}
              />
            )}
          </CourseContentDescription>
          <CourseContentReview></CourseContentReview>
        </CourseContentLeft>

        <CourseContentRight
          style={{ textAlign: 'right' }}
          // className={!video ? 'noVideo' : ''}
        >
          <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
            <GridActionMenu
              actionConfig={downloadActions}
              row={lessonData}
              icon={
                <Button
                  text={t('actions.download')}
                  size='small'
                  onClick={(): void => undefined}
                  icon={<ArrowDropDownIcon />}
                  iconPosition='right'
                />
              }
            />
          </div>
          <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
            {!(!state.userPermission.editLesson && !state.userPermission.deleteLesson) && (
              <GridActionMenu
                actionConfig={action}
                row={lessonData}
                icon={
                  <Button
                    text={t('general.actions')}
                    size='small'
                    onClick={(): void => undefined}
                    icon={<ArrowDropDownIcon />}
                    iconPosition='right'
                  />
                }
              />
            )}
          </div>
          {video || audio ? (
            <DetailsContentVideoCover
              noBefore={video && !hasConverted && config.ENV === 'production' ? true : false}
            >
              {(video && hasConverted) || (config.ENV === 'development' && !hasConverted) ? (
                <img src={video.thumbnail} />
              ) : null}
              <CourseContentPlayIcon onClick={(): void => setMediaModal(true)}>
                {(video && hasConverted) || (config.ENV === 'development' && !hasConverted) ? (
                  <PlayCircleOutlineIcon fontSize='large' />
                ) : (
                  <VideoProcessingWrapper>
                    <CircularProgress />
                    <div> {t('general.video_processing_title')}</div>
                    <div> {t('general.video_processing_description')}</div>
                  </VideoProcessingWrapper>
                )}
                {audio && <HeadsetIcon fontSize='large' />}
              </CourseContentPlayIcon>
            </DetailsContentVideoCover>
          ) : null}
          {document && (
            <div
              style={{
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src='https://img.icons8.com/ios/500/document.png'
                alt=''
                style={{ width: '100px', cursor: 'pointer' }}
                onClick={(): Window | null => window.open(document, '_blank')}
              />
            </div>
          )}
          <CourseContentDetailsList>
            {(video || audio) && (
              <div style={{ fontSize: '13px' }}>
                {t('general.duration')}: {lessonData.duration}
              </div>
            )}
          </CourseContentDetailsList>
        </CourseContentRight>
      </CourseContentWrapper>
      <CourseContentDescription>
        {lessonData.transcript && (
          <TextEditor
            text={lessonData.transcript}
            isVisible={transcriptEdit}
            hasControls={true}
            editorTitle={`${t('general.lesson')} ${t('form_fields.transcript')}`}
            closeEditMode={(): void => setTranscriptEdit(false)}
            openEditMode={(): void => setTranscriptEdit(true)}
            onSave={(editorText: string): void => editEditorText(editorText, 'transcript')}
            canEdit={!!state.userPermission.editLesson && canModify}
            defaultText={t('default_texts.transcript')}
          />
        )}
      </CourseContentDescription>

      <CourseContentDescription>
        <Label>{t('lesson_details.resource')}</Label>
        <ResourceContainer>
          {(lessonData.attachment && (
            <div>
              <a href={lessonData.attachment.link} target='_blank'>
                {lessonData.attachment.name}
              </a>
            </div>
          )) || <span>{t('lesson_details.no_resource')}</span>}
        </ResourceContainer>
      </CourseContentDescription>

      <Modal isOpened={mediaModal} onClose={(): void => setMediaModal(false)}>
        <div>
          {audio && <AudioPlayer url={audio} />}
          {video && video.links && (
            <Player url={video.links[0].url} subtitle={lessonData.subtitle as string} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default LessonDetails
