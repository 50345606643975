import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { RefObject, useEffect, useRef, useState } from 'react'
import { ReactCropperElement } from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { IPersonalInformation } from '../PersonalInformation.interface'
import { IUserCompany } from 'interfaces/users'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import { getInfoItemsRow } from 'hooks/users/staticData'
import { actionConfig, getActions } from '../gridConfig'
import { IUseAdminProfileReturnType } from '../adminProfile.interface'
import { useParams } from 'react-router-dom'

const useAdminProfile = (): IUseAdminProfileReturnType => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const [isClickedOnEdit, setIsClickedOnEdit] = useState(false)
  const { id: userId } = useParams<{ id: string }>()
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<string | null>(null)
  const cropper = useRef<ReactCropperElement>(null)
  const avatarRef = useRef(null)

  const [isClickedOnChangePassword, setIsClickedOnChangePassword] = useState(false)

  const {
    firstName,
    lastName,
    email,
    avatar,
    id,
    role,
    gender,
    birthDate,
    location,
    jobTitle,
    companyId,
    phone,
    createDate,
    biography,
    note,
    status,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
  } = state.currentUser
  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    gender: 'prefer not to say',
    location: '',
    phone: '',
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    isCompanyAdmin: false,
    isSuperAdmin: false,
    phoneFieldValue: null,
    avatar: '',
  })

  useEffect(() => {
    const data = {
      firstName,
      lastName,
      email,
      role,
      companyId,
      createDate,
      birthDate,
      gender,
      location,
      phone,
      jobTitle,
      biography,
      note,
      status,
      id,
      age,
      phoneFields,
      isCompanyAdmin,
      isSuperAdmin,
      phoneFieldValue: phoneFields ? phoneFields.code : null,
      avatar,
    } as IPersonalInformation
    setProfileData(data)
  }, [
    firstName,
    lastName,
    email,
    role,
    companyId,
    createDate,
    birthDate,
    gender,
    location,
    phone,
    jobTitle,
    biography,
    note,
    status,
    id,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    avatar,
  ])

  const userCompany = companyId as IUserCompany
  const isCompany = userCompany && userCompany.id && userCompany.name

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement> | null,
  ): void => {
    let croppedFile = null
    if (action === 'update') {
      croppedFile = cropper?.current?.cropper.getCroppedCanvas()?.toDataURL()
    }
    if (userId) updateAvatar(croppedFile as string, userId, action, crudFunction)
  }

  const infoItemsRow = getInfoItemsRow(profileData, !!isCompany)

  const setResetPasswordOpen = (): void => {
    setIsClickedOnChangePassword(true)
  }
  const handleEditClick = (): void => {
    setIsClickedOnEdit(true)
  }

  const handleAvatarDelete = (): void => {
    fireSwal({
      title: `${t('popups.delete_avatar')} `,
      onConfirm: () => {
        finishCrop('delete', () => undefined, null)
        setFile(null)
      },
      confirmText: t('popups.confirm_delete'),
    })
  }

  const actions = actionConfig(handleAvatarDelete, profileData)

  const visibleActions = getActions(profileData, handleEditClick, setResetPasswordOpen)

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()

    const { files } = e.target
    const reader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result)
      }
      setCropperOpen(true)
    }
    if (files) reader.readAsDataURL(files[0])
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const crudFunction = (isFinished: boolean): void => {
    if (isFinished) {
      setCropperOpen(false)
      setFile(null)
    }
  }

  const onSave = (): void => {
    finishCrop('update', crudFunction, cropper)
    setFile(null)
    setCropperOpen(false)
  }
  return {
    t,
    actions,
    profileData,
    avatarRef,
    handleAvatarChange,
    avatar,
    finishCrop,
    hasResponseReceived,
    role,
    firstName,
    lastName,
    isClickedOnEdit,
    setIsClickedOnEdit,
    isClickedOnChangePassword,
    setIsClickedOnChangePassword,
    cropperOpen,
    cropper,
    file,
    cropperModalToggle,
    onSave,
    visibleActions,
    infoItemsRow,
    isCompany,
  }
}

export default useAdminProfile
