import { useLazyQuery } from '@apollo/client'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICompanyFilter,
  IFilterStudentsFormValues,
  IFilterStudentsProps,
  IStudentsCourseFilter,
  IStudentsGroupFilter,
  IUseFilterStudentsReturnType,
} from '../filterStudents.interface'
import { IStudentsFilterOptions, studentsFilterOptions } from 'components/UsersLayout/staticData'
import { FilterStudentsSchema } from 'helpers/validationSchemas'
import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { roles } from 'utils/permission'
import { useLocation } from 'react-router-dom'

const useFilterStudents = ({
  closeDrawer,
  manualCompanyId,
  useCompanyId,
  setFilterOptions,
  filterOptions,
  filter,
  defaultFilter,
  refetchStudents,
}: IFilterStudentsProps): IUseFilterStudentsReturnType => {
  const { t } = useTranslation()
  const [companyOptions, setCompanyOptions] = useState([])
  const [state] = useUserValue()
  const isCompanySelected = Boolean(state?.selectedCompany?.id)
  const ref = useRef<NodeJS.Timeout | null>(null)
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()

  const [fetchGroup, { data: groupData, loading: groupLoading }] = useLazyQuery(GET_GROUP)
  const [fetchCompany, { data: companyData, loading: companyLoading }] = useLazyQuery(GET_COMPANIES)

  const [fetchCourse, { data: courseData, loading: courseLoading }] = useLazyQuery(GET_ALL_COURSE)

  setFilterRole('STUDENTS')

  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: studentsFilterOptions,
      validationSchema: FilterStudentsSchema,
      onSubmit(values) {
        const formValues: IFilterStudentsFormValues = {}

        if (values.firstName) {
          formValues.firstName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.firstName,
          }
        }
        if (values.lastName) {
          formValues.lastName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.lastName,
          }
        }
        if (values.note) {
          formValues.note = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.note,
          }
        }
        if (values.location) {
          formValues.location = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.location,
          }
        }

        if (values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        if (values.group) {
          const searchedGroupIds = [...values.group.map((gr: { value: string }) => gr.value)]

          const groups =
            searchedGroupIds && defaultFilter?.group?.value
              ? [...searchedGroupIds, ...defaultFilter.group.value]
              : searchedGroupIds

          formValues.group = {
            nestedField: 'groupId',
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_ALL,
            value: groups,
          }
        }

        if (values.course) {
          formValues.courses = {
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
            value: values.course.map(i => i.value.id),
            nestedField: 'courseId',
          }
        }

        if (values?.status?.value !== 'all') {
          formValues.status = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.status?.value,
          }
        }

        if (values.company) {
          const companyIds = values.company.map((i: { value: string | null }) =>
            i.value === '-1' ? (i.value = null) : i.value,
          )
          const query = { companyId: { $in: companyIds } }
          // formValues.companyId = {
          //   type: 'arrayIn',
          //   value: companyIds,
          // }
          formValues.query = {
            type: LowerCaseFilterableFieldType.QUERY,
            value: JSON.stringify(query),
          }
        }

        const isAllZero = values?.age?.every(item => item === 0)

        if (!isAllZero) {
          formValues.age = {
            type: LowerCaseFilterableFieldType.RANGE,
            value: `${values?.age?.[0]}-${values?.age?.[1]}`,
          }
        }

        // formValues.query = {
        //   type: 'query',
        //   value: JSON.stringify(queryString),
        // }

        if (values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        //if user wants to clear the date, we should send undefined
        const safeCreateDate = createDate || [undefined, undefined]

        safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
        safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

        const isSameDate =
          safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

        if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

        const formattedData = isSameDate
          ? safeCreateDate[0]
          : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
              safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
            }`

        if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : LowerCaseFilterableFieldType.DATE_RANGE,
            value: formattedData as string,
          }
        }
        if (refetchStudents && defaultFilter) {
          refetchStudents({
            role: roles.student,
            filter: { ...defaultFilter, ...formValues },
            currentPage: 1,
            perPage: 10,
          })
          closeDrawer()
        }

        filter(formValues)
        setFilterOptions(values as IFilterQueryType)
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'STUDENTS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as IStudentsFilterOptions)
    }
  }, [])

  useEffect(() => {
    setFilterOptions(values as IFilterQueryType)
  }, [values])

  const handleRangeChange = (range: number | number[]): void => {
    setFieldValue('age', range)
  }

  const currentLocation = useLocation()
  const inOnCompanyPage = currentLocation.pathname.indexOf('companies') > -1

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      switch (field) {
        case 'company':
          // Fetch company data with the created filter
          fetchCompany({
            variables: {
              filter: createCompanyFilter(value),
            },
          })
          break
        case 'group':
          // Fetch group data with the created filter
          fetchGroup({
            variables: {
              filter: createGroupFilter(value),
              perPage: 0,
            },
          })
          break
        default:
          // Fetch course data with the created filter
          fetchCourse({
            variables: {
              filter: createCourseFilter(value),
              perPage: 0,
            },
          })
          break
      }
    }, 200)

  // Function to create a filter for companies
  const createCompanyFilter = (value: string): ICompanyFilter => ({
    name: {
      type: UpperCaseFilterableFieldType.MATCH,
      value,
    },
  })

  // Function to create a filter for groups
  const createGroupFilter = (value: string): IStudentsGroupFilter => {
    let filter: IStudentsGroupFilter = {
      name: {
        type: LowerCaseFilterableFieldType.MATCH,
        value,
      },
    }
    // Add company filter if a company ID is used
    if (useCompanyId) {
      const companyId = manualCompanyId ? manualCompanyId : state.selectedCompany?.id
      if (companyId) {
        filter = {
          ...filter,
          company: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: companyId,
          },
        }
      }
    }
    return filter
  }

  // Function to create a filter for courses
  const createCourseFilter = (value: string): IStudentsCourseFilter => {
    let filter: IStudentsCourseFilter = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value },
    }

    if (!inOnCompanyPage && !useCompanyId) {
      filter = {
        ...filter,
        originalId: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: [null],
        },
      }
    }
    if (useCompanyId || state.selectedCompany?.id) {
      filter = {
        ...filter,
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: manualCompanyId ? manualCompanyId : state.selectedCompany?.id,
        },
      }
    }
    return filter
  }

  const handleSelectChange = (e: string, field: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])
  return {
    t,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isCompanySelected,
    companyOptions,
    groupData,
    groupLoading,
    courseData,
    courseLoading,
    createDate,
    setCreateDate,
    handleRangeChange,
    handleSelectChange,
    companyLoading,
  }
}

export default useFilterStudents
