import { Action } from 'components/common/GridV2'
import React from 'react'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import { ReactComponent as UnlockIcon } from 'assets/unlock.svg'
import { ReactComponent as SmallUnlockIcon } from 'assets/small-unlocked.svg'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { ReactComponent as RedDeleteIcon } from 'assets/red-delete-icon.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { Wrapper } from './styled-components'
import Tooltip from '@mui/material/Tooltip'
import strCutter from 'utils/strCutter'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import Checkbox from 'components/common/Checkbox'
import { CheckBoxAndAvatarContainer } from 'components/UsersLayout/styled-components'

// Update Permissions type to allow undefined
interface IPermissions {
  editGroup?: boolean
  viewGroup?: boolean
  deleteGroup?: boolean
}
export interface IGridItem {
  id: string
  label: string
  render: (item: IQuiz) => JSX.Element
  numeric: boolean
  disablePadding: boolean
}

export interface IActionConfigItem {
  hide: boolean
  render: (item: IQuiz) => JSX.Element
}

export const actionConfig = (
  actionHandler: (action: string, item: IQuiz) => void,
  permissions: IPermissions,
  t: TFunction,
  setIsSearchType?: (value: boolean) => void,
): IActionConfigItem[] => {
  const action: IActionConfigItem[] = [
    {
      hide: !permissions.editGroup,
      render: (item: IQuiz) => (
        <Action onClick={(): void => actionHandler('edit', item)} className='edit'>
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.viewGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => actionHandler('publish', item)}
          className={item.published ? 'unpublish' : 'publish'}
        >
          <UnlockIcon />
          <span>{item.published ? t('actions.unpublish') : t('actions.publish')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => {
            actionHandler('delete', item)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
          className='delete'
        >
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionConfigItem) => !i.hide)
}

export const GroupActionConfig = (
  actionHandler: (action: string, item: IQuiz) => void,
  permissions: IPermissions,
  t: TFunction,
): IActionConfigItem[] => {
  const action: IActionConfigItem[] = [
    {
      hide: !permissions.editGroup,
      render: item => (
        <Action onClick={(): void => actionHandler('remove', item)}>
          <TrashCanRed />
          <DeleteSpan>{t('actions.remove_test')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionConfigItem) => !i.hide)
}

export const testListAction = (
  actionHandler: (action: string, item: IQuiz) => void,
  permissions: IPermissions,
  t: TFunction,
  setIsSearchType?: (value: boolean) => void,
): IActionConfigItem[] => {
  const action: IActionConfigItem[] = [
    {
      hide: !permissions.editGroup,
      render: (item: IQuiz) => (
        <Action onClick={(): void => actionHandler('edit', item)} className='edit'>
          <Tooltip title={t('actions.edit')} arrow>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions.viewGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => actionHandler('publish', item)}
          className={item.published ? 'unpublish' : 'publish'}
        >
          <SmallUnlockIcon />
          <span>{item.published ? t('actions.unpublish') : t('actions.publish')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => {
            actionHandler('delete', item)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
          className='delete'
        >
          <RedDeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionConfigItem) => !i.hide)
}

export const testListConfig = (t: TFunction, selectItem: (id: string) => void): IGridItem[] => {
  const grid: IGridItem[] = [
    {
      id: 'isChecked',
      label: '',
      render: (item: IQuiz): JSX.Element => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={(): void => {
              if (item.id) selectItem(item.id)
            }}
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IQuiz): JSX.Element => (
        <Wrapper style={{ cursor: 'pointer' }}>
          {item.name && item.name.length > 30 ? (
            <Tooltip title={item.name}>
              <div>{strCutter(item.name, 30)}</div>
            </Tooltip>
          ) : (
            <div>{item.name}</div>
          )}
        </Wrapper>
      ),
    },
    {
      id: 'published',
      label: t('general.status'),
      render: (item: IQuiz): JSX.Element => (
        <Wrapper>
          <StatusIndicator
            status={item.published ? GridStatusEnums.PUBLISHED : GridStatusEnums.UNPUBLISHED}
          />
        </Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'questions',
      label: t('general.questions'),
      render: (item: IQuiz): JSX.Element => <Wrapper>{`${item.questions?.length}`}</Wrapper>,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'passRate',
      label: t('quiz_details.pass_rate'),
      render: (item: IQuiz): JSX.Element => <Wrapper>{`${item.passRate}%`}</Wrapper>,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'type',
      label: t('general.type'),
      render: (item: IQuiz): JSX.Element => <Wrapper>{`${item.timeType}`}</Wrapper>,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'randomization',
      label: t('quiz_details.randomize'),
      render: (item: IQuiz): JSX.Element => (
        <Wrapper>{`${item.randomization ? 'Yes' : 'No'}`}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
  ]
  return grid
}
