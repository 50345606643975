import Modal from '@mui/material/Modal'
import { styled as muiStyled } from '@mui/material/styles'

const PREFIX = 'CustomModal'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

export const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export const StyledModal = muiStyled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
