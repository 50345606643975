import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledIndicator } from './SelectedItemIndicator.styles'

const SelectedItemIndicator = ({ count = 0 }: { count: number }): ReactElement => {
  if (!count) return <></>
  const { t } = useTranslation()

  return (
    <StyledIndicator>
      <strong>{count}</strong> {t('general.selected')}
    </StyledIndicator>
  )
}

export default SelectedItemIndicator
