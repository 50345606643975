import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { Container } from './styled-components'
import { IStepperProps } from './stepper.interface'

const UiStepper = ({ active, steps, setStep, completedSteps }: IStepperProps): ReactElement => {
  const currentSteps = useRef(completedSteps)
  const onStepClick = useCallback(
    (index: number) => {
      if (completedSteps && currentSteps?.current && completedSteps.indexOf(0) > -1) {
        if (setStep) setStep(index)
      }
    },
    [completedSteps, currentSteps?.current],
  )

  useEffect(() => {
    if (currentSteps?.current) currentSteps.current = completedSteps
  }, [completedSteps])

  return (
    <Container>
      <Stepper activeStep={active} alternativeLabel>
        {steps.map((label: string, index: number) => (
          <Step key={label} onClick={(): void => onStepClick(index)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  )
}

export default UiStepper
