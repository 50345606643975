import React, { ReactElement } from 'react'
import { useBooksLayout } from 'hooks/books'
import { BreadCrumb, SplashScreen, Drawer, Button, TextInput } from 'components/common'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { BooksGrid, Wrapper, toolbarStyles } from './styled-components'
import AddBookDrawer from './AddBookDrawer/AddBookDrawer'
import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import BookItem from './BookItem/BookItem'
import MyLoader from 'loading/loading'

const BooksLayout = (): ReactElement => {
  const {
    t,
    companyId,
    breadCrumbData,
    createButton,
    books,
    searchValue,
    handleSearchChange,
    onSearchSubmit,
    addBookDrawerOpened,
    isSearchType,
    setIsSearchType,
    setAddBookDrawerOpened,
  } = useBooksLayout()

  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Wrapper>
        {!companyId ? (
          <SplashScreen subTitle={t('books_layout.switch_company')} />
        ) : books && books.data?.length === 0 && !searchValue && !isSearchType ? (
          <SplashScreen
            title={t('books_layout.no_books')}
            subTitle={t('books_layout.create_books')}
            createButton={createButton}
          />
        ) : (
          <>
            <Toolbar styles={toolbarStyles}>
              <ToolbarItem>
                <Button
                  text={t('books_layout.add_book')}
                  background='#06C68F'
                  onClick={(): void => setAddBookDrawerOpened(true)}
                  icon={<AddIcon className='icon' />}
                />
              </ToolbarItem>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type='submit'>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                  />
                </form>
              </ToolbarItem>
            </Toolbar>
            {searchValue && books && books.data?.length === 0 ? (
              <SplashScreen
                title={t('general.no_results_found')}
                subTitle={t('general.try_adjusting_your_search_or_filter')}
                textMarginTop='40px'
                isSearchOrFilter={true}
              />
            ) : books?.data && books.data.length > 0 ? (
              <BooksGrid>
                {books.data.map(book => (
                  <BookItem
                    key={book.id}
                    book={book}
                    setIsSearchType={setIsSearchType}
                    enableActions={true}
                  />
                ))}
              </BooksGrid>
            ) : (
              <MyLoader />
            )}
          </>
        )}

        <Drawer opened={addBookDrawerOpened}>
          <AddBookDrawer
            onClose={(): void => setAddBookDrawerOpened(false)}
            companyId={companyId || ''}
          />
        </Drawer>
      </Wrapper>
    </>
  )
}

export default BooksLayout
