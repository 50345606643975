import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchTasksByGroup } from 'services/tasks/getTasksByGroupService'
import { columnConfig, testTabActionConfig } from 'pages/tasks/gridConfig'
import { debounce } from 'lodash'
import { IGroup } from 'interfaces/groups'
import {
  GetTasksByGroupFiltersByEnums,
  ITask,
  IUseGroupTasksReturn,
} from 'services/tasks/getTasksService.interface'
import { useSwal } from 'hooks/useSwal'
import { useRemoveTasksFromGroupService } from 'services/tasks/removeTaskFromGroupService'
import { ISelectAll, ISelectedItem } from 'components/common/FilterInterface/filter.interface'

const useGroupTasks = ({ group }: { group: IGroup }): IUseGroupTasksReturn => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedTasks, setSelectedTasks] = useState<string[]>([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const groupId = group.id

  const { removeTasksFromGroup } = useRemoveTasksFromGroupService({ groupId })

  const openAddDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addTaskToGroup', true, {
      groupData: group,
    })
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('tasks_layout.add_task')}
      color='secondary'
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background='#06C68F'
    />
  )

  const {
    tasks,
    totalPages,
    loading: fetchLoading,
    refetch,
  } = useFetchTasksByGroup({
    searchValue: '',
    filterBy: GetTasksByGroupFiltersByEnums.IN_GROUP,
    groupId,
  })

  useEffect(() => {
    if (!fetchLoading) {
      setInitialLoad(false)
    }
  }, [fetchLoading])

  const selectAllItem = (): void => {
    // Check if tasks are available and not all are selected on the current page
    if (tasks?.length && !selectAll[currentPage]) {
      // Create an array of task IDs, filtering out any falsy values
      const newArr = tasks.map((task: ITask) => task._id || task.id || '').filter(Boolean)
      // Update the selected items for the current page
      setSelectedItem(prev => ({
        ...prev,
        [currentPage]: newArr,
      }))
      // Update the list of selected tasks
      setSelectedTasks(newArr)
      // Mark all items as selected for the current page
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: true,
      }))
    } else {
      // If all items are already selected, deselect all
      setSelectedItem(prev => ({
        ...prev,
        [currentPage]: [],
      }))
      // Clear the list of selected tasks
      setSelectedTasks([])
      // Mark all items as deselected for the current page
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: false,
      }))
    }
  }

  const selectItem = (id?: string): void => {
    if (!id) return
    // Get the list of selected items for the current page
    const selectedItemsOnPage = selectedItem[currentPage] || []
    // Check if the item is already selected
    const isSelected = selectedItemsOnPage.includes(id)
    // Toggle the selection of the item
    const newSelected = isSelected
      ? selectedItemsOnPage.filter(itemId => itemId !== id)
      : [...selectedItemsOnPage, id]
    // Update the selected items for the current page
    setSelectedItem(prev => ({
      ...prev,
      [currentPage]: newSelected,
    }))
    // Update the list of selected tasks
    setSelectedTasks(newSelected)
    // If the item was deselected, ensure 'select all' is marked as false
    if (isSelected) {
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: false,
      }))
    }
  }

  const hasNoTasks = tasks?.length === 0

  const debouncedRefetch = useCallback(
    debounce((value: string) => {
      setLoading(true)
      refetch({
        searchValue: value,
        groupId,
        filterBy: GetTasksByGroupFiltersByEnums.IN_GROUP,
      }).finally(() => setLoading(false))
      setIsSearchType(value !== '')
    }, 500),
    [groupId],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value
    setSearchValue(newSearchValue)
    debouncedRefetch(newSearchValue)
    setIsSearchType(true)
  }

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setLoading(true)
    refetch({
      searchValue,
      groupId,
    }).finally(() => setLoading(false))
  }

  const handleTaskSelection = (taskId: string): void => {
    selectItem(taskId)
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter(id => id !== taskId))
    } else {
      setSelectedTasks([...selectedTasks, taskId])
    }
  }

  const handleDeleteTaskFromGroup = (taskId: string): void => {
    if (!groupId) return
    fireSwal({
      title: t('popups.delete_task_from_group'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: async () => {
        await removeTasksFromGroup({ taskIds: [taskId], groupId })
        const remainingTasks = tasks.length - 1
        if (remainingTasks === 0 && currentPage > 1) {
          setCurrentPage(1)
        }
        refetch({
          searchValue,
          groupId,
          currentPage: remainingTasks === 0 && currentPage > 1 ? 1 : currentPage,
          perPage,
        })
      },
    })
  }

  const handlePaginationClick = async (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): Promise<void> => {
    try {
      // Update the current page state
      setCurrentPage(newPage)

      // Refetch tasks for the new page
      await refetch({
        searchValue,
        groupId,
        currentPage: newPage,
        perPage,
      })

      // Reset selected items for the new page
      setSelectedItem(prev => ({
        ...prev,
        [newPage]: [],
      }))
    } catch (error) {
      // Log any errors during the refetch process
      console.error('Error during refetch:', error)
    }
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      searchValue,
      groupId,
      currentPage,
      perPage: newPerPage,
    })
    setSelectedItem
  }

  const deleteTasks = (id?: string): void => {
    // Get task IDs to delete
    const selectedIds = id ? [id] : selectedItem[currentPage] || []
    // Exit if no IDs
    if (selectedIds.length === 0) return
    const params = {
      title: t('tasks_layout.sure_remove_tasks_from_group'),
      confirmText: 'Yes, remove!',
      onConfirm: async (): Promise<void> => {
        // Remove tasks
        await removeTasksFromGroup({ taskIds: selectedIds, groupId })
        // Clear selections
        setSelectedItem(prev => ({
          ...prev,
          [currentPage]: [],
        }))
        setSelectedTasks([])
        // Check if the current page is empty after deletion
        const remainingTasks = tasks.length - selectedIds.length
        if (remainingTasks === 0 && currentPage > 1) {
          setCurrentPage(1) // Reset to the first page
        }
        // Refetch tasks to update the list
        refetch({
          searchValue,
          groupId,
          currentPage: remainingTasks === 0 && currentPage > 1 ? 1 : currentPage,
          perPage,
        })
      },
    }
    // Show confirmation dialog
    fireSwal(params)
  }

  const actions = testTabActionConfig(handleDeleteTaskFromGroup, t)

  const gridConfig = columnConfig(handleTaskSelection, selectedTasks, t, false)

  return {
    t,
    tasks,
    refetchTasks: refetch,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    groupId,
    loading: fetchLoading || loading,
    isSearchType,
    initialLoad,
    gridConfig,
    actions,
    selectedTasks,
    selectAll,
    hasNoTasks,
    selectAllItem,
    selectItem,
    selectedItem,
    currentPage,
    handlePaginationClick,
    deleteTasks,
    handleChangeRowsPerPage,
    perPage,
    totalPages,
  }
}

export default useGroupTasks
