import React, { ReactElement } from 'react'
import { GridTableWrapper } from './styled-components'

import DndItem from 'components/common/Grid/DndGrid'
import Grid from 'components/common/GridV2/Grid'
import { ICourse, ICourseListProps } from './courseList.interface'
import { useCourseList } from './hooks/useCourseList'

const CourseList = ({
  isGridView,
  companyMode,
  activeTab,
  actionHandler,
  data,
  isDraggable = false,
  gridStyle,
  selectItem,
  selectedItem,
  actions,
  fromCertificateBuilder = false,
  fromWizard,
  $canOpen = true,
  withTooltips = true,
  openInExternalTab,
  user,
  groupId,
  courseListActions,
}: ICourseListProps): ReactElement => {
  const { moveCard, cards, handleChangeRequest, renderContent, RedirectToCourse, courseList } =
    useCourseList({
      groupId,
      user,
      openInExternalTab,
      data,
      $canOpen,
      fromCertificateBuilder,
      fromWizard,
      selectedItem,
      gridStyle,
      actions,
      selectItem,
      actionHandler,
      companyMode,
      activeTab,
      withTooltips,
    })

  return (
    <>
      {cards &&
        (isGridView ? (
          cards.map((item: ICourse, index: number) =>
            isDraggable ? (
              <DndItem
                key={item.id}
                id={item.id}
                index={index}
                moveCard={moveCard}
                cardIds={cards.map((item: ICourse) => item.id)}
                onEnd={handleChangeRequest}
              >
                {renderContent(item)}
              </DndItem>
            ) : (
              <React.Fragment key={item.id}>{renderContent(item)}</React.Fragment>
            ),
          )
        ) : (
          <GridTableWrapper style={{ width: '100%' }}>
            <Grid
              config={courseList}
              data={cards}
              actionConfig={courseListActions}
              actionTilesLength={2}
              redirectToDetailsHandler={RedirectToCourse}
            />
          </GridTableWrapper>
        ))}
    </>
  )
}

export default CourseList
