import { styled } from 'styled-components'

export const TextWrapper = styled.div`
  padding: 16px;
  color: #020210;
  font-size: 16px;
  font-weight: 400;
`

export const DetailsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding: 16px 16px;
`

export const InformationBackground = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  min-height: 300px;
`

export const ProfileBackground = styled.div`
  width: 30%;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const StyledFlex = styled.div`
  display: flex;
  gap: 30px;
`

export const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  color: #68707a;
  font-size: 18px;
`
