import { gql } from 'graphql.macro'

export const GET_ACCREDIBLE_DATA = gql`
  query GetAccredibleData($companyId: String, $designId: Int) {
    getAccredibleDataWithModuleId(companyId: $companyId, designId: $designId) {
      accredibleDesignId
      accredibleBadgeIframeUrl
    }
  }
`
