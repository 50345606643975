import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { SelectChangeEvent } from '@mui/material'
import { IFilterData } from 'pages/quizzes/quiz.interface'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { debounce } from 'lodash'
import { IUseAddGroupToTaskProps, IUseAddGroupToTaskReturn } from './useTaskGroupLayout.interface'
import { useFetchGroupsByTask } from 'services/tasks/getGroupsByTaskId'
import { GetGroupsByTaskFiltersEnums } from 'services/tasks/getTasksService.interface'

const useAddGroupToTask = ({
  onClose,
  taskId,
}: IUseAddGroupToTaskProps): IUseAddGroupToTaskReturn => {
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(12)
  const [searchText, setSearchText] = useState<string>('')
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    filterOptions: {},
    filterValues: null,
    opened: false,
  })

  const { groups, totalCount, loading, refetch } = useFetchGroupsByTask({
    taskId,
    groupInclusion: GetGroupsByTaskFiltersEnums.NOT_IN_GROUP,
  })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setSearchText(value)
    debounceSubmit(value)
  }

  const handleSearchSubmit = (searchText: string): void => {
    refetch({
      taskId,
      filter: {
        ...filterDrawer.filterOptions,
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
      },
      currentPage,
      perPage,
    })
  }
  const debounceSubmit = debounce(handleSearchSubmit, 500)
  const { t } = useTranslation()

  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const groupIds = groups.map(group => group.id as string) || []

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItems.indexOf(id)
    if (selectedIndex === -1) {
      if (selectedItems.length + 1 === groupIds.length) setIsAllSelected(true)
      setSelectedItems(prev => [...prev, id])
      return
    }

    setIsAllSelected(false)
    setSelectedItems(prev => prev.filter(selectedGroupId => selectedGroupId !== id))
  }

  const selectAllItem = async (): Promise<void> => {
    if (!isAllSelected) {
      setSelectedItems(groupIds)
      setIsAllSelected(true)
      return
    }

    setSelectedItems([])
    setIsAllSelected(false)
  }

  const onFormSubmit = (): void => {
    onClose(selectedItems)
  }

  const handleCloseDrawer = (): void => {
    if (!selectedItems.length) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => undefined,
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = (_: ChangeEvent<unknown> | null, newPage: number): void => {
    refetch({
      filter: {
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
      },
      currentPage,
      perPage,
    })
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>): void => {
    const newPerPage = parseInt(event.target.value as string, 10)
    refetch({
      filter: {
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
      },
      currentPage,
      perPage: newPerPage,
    })
    setPerPage(newPerPage)
  }

  const filterData = (filters: IFilterData): void => {
    refetch({
      filter: { ...filters },
      currentPage,
      perPage: 0,
    })
    if (groups.length > 0) {
      setFilterDrawer({ ...filterDrawer, opened: false })
    }
  }
  return {
    selectItem,
    selectedItems,
    isAllSelected,
    selectAllItem,
    onFormSubmit,
    handleCloseDrawer,
    filterDrawer,
    setFilterDrawer,
    groups,
    loading,
    searchText,
    handlePaginationClick,
    handleChangeRowsPerPage,
    refetch,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    filterData,
    handleSearchChange,
    t,
    totalCount,
  }
}

export default useAddGroupToTask
