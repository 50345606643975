import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow: scroll;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px;
  height: 100%;
`

export const ImageContainer = styled.div`
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  padding: 15px;
  transition: background 0.3s ease;
  img {
    width: 110px;
    height: 110px;
  }

  &:hover,
  &.selected {
    background: rgba(6, 198, 143, 0.3);
  }
`

export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}
