import React from 'react'
import { InfoWrapper, Subtitle, Wrapper } from './styled-components'
import { t } from 'i18next'
import useAllUserProfile from 'hooks/users/useAllUserProfile'
import { StatusWrapper } from 'components/UsersLayout/styled-components'

export const StatusWrapperComponent = (): React.JSX.Element => {
  const { profileData } = useAllUserProfile()
  return (
    <Wrapper>
      <Subtitle>{t('general.status')}</Subtitle>
      <InfoWrapper>
        <StatusWrapper
          className={`${profileData.status?.toLowerCase()}`}
          style={{ fontSize: 16, fontWeight: 500, width: '100%' }}
        >
          {profileData.status}
        </StatusWrapper>
      </InfoWrapper>
    </Wrapper>
  )
}
