import styled, { css } from 'styled-components'

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &.active {
    color: green;

    div {
      background: green;
    }
  }

  &.suspended {
    color: red;

    div {
      background: red;
    }
  }

  &.pending {
    color: grey;

    div {
      background: grey;
    }
  }
`

export const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`
export const LayoutWrapper = styled.div<{ $loading?: boolean }>`
  border-radius: 4px;
  background: #fff;
  padding-inline: 20px;
  padding-top: 2px;
  padding-bottom: 2px;

  ${({ $loading }): ReturnType<typeof css> =>
    $loading
      ? css`
          padding: 0px;
        `
      : css``}
`
export const FlexComponent = styled.div`
  display: flex;
  gap: 10px;
`
export const Title = styled.div`
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
`
export const Action = styled.div<{
  hoverColor?: string
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    ${({ hoverColor }): string =>
      (hoverColor || ' ') &&
      `svg path {
        fill: ${hoverColor};
         }`}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
  }
`
export const StyledPagination = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-right: 40px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
`
