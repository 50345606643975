import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { useCourseByUserId } from 'hooks/course/useCourseByUserId'
import { IFilterCriteria } from 'interfaces/courses/courses.interface'
import { filterData } from 'pages/courses/courseList.interface'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUseUserCoursesProps, IUseUserCoursesReturnType } from '../userCourses.interface'
import { debounce } from 'lodash'

const initialValues = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const useUserCourses = ({ groups, userId }: IUseUserCoursesProps): IUseUserCoursesReturnType => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(12)
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<filterData>({
    values: initialValues,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const defaultFilter = {
    groups: {
      type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
      value: groups,
      nestedField: 'GROUP_ID',
    },
  }

  const { data: courses, refetch } = useCourseByUserId(
    {
      ...defaultFilter,
      ...filterQuery,
      ...filterData.filterOptions,
    } as IFilterCriteria,
    currentPage,
    perPage,
    userId,
  )

  const [isCoursesVisible, setCoursesVisible] = useState(true)

  type VideoModalState = {
    modal: boolean
    data: string
    subtitle: string
  }

  const [videoModal, setVideoModal] = useState<VideoModalState>({
    modal: false,
    data: '',
    subtitle: '',
  })
  const handlePaginationClick = (_: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== courses.currentPage) {
      setCurrentPage(value)
    }
  }

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchText: string) => {
      const formValue = { name: { type: UpperCaseFilterableFieldType.MATCH, value: newSearchText } }
      setCurrentPage(1)
      setFilterQuery({
        ...filterQuery,
        ...formValue,
        ...filterData.filterOptions,
      })
      refetch({
        currentPage: 1,
        perPage,
        filter: filterQuery as IFilterCriteria,
      })
    }, 500),
    [filterQuery, filterData.filterOptions, refetch, perPage],
  )

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = { name: { type: UpperCaseFilterableFieldType.MATCH, value: searchText } }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery as IFilterCriteria,
    })
  }
  return {
    setCoursesVisible,
    t,
    isCoursesVisible,
    filterData,
    searchText,
    resetFilter,
    setFilterData,
    onSearchChange,
    filterDrawer,
    filterIsOpen,
    setFilterIsOpen,
    setCurrentPage,
    handlePaginationClick,
    videoModal,
    setVideoModal,
    onSearchSubmit,
    courses,
    refetch,
  }
}

export default useUserCourses
