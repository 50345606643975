import { GET_TASK_BY_ID } from 'gql/tasks.query'
import { ApolloError, useQuery } from '@apollo/client'
import { ITask } from './getTasksService.interface'

interface IUseFetchTaskByIdServiceResponse {
  task?: ITask | null
  loading: boolean
  error?: ApolloError
  taskRefetch: () => void
}

interface IGetTaskByIdResponse {
  getTaskById: ITask
}

interface IGetTaskByIdParams {
  taskId?: string
}

export const useFetchTaskById = (taskId?: string): IUseFetchTaskByIdServiceResponse => {
  const { data, loading, error, refetch } = useQuery<IGetTaskByIdResponse, IGetTaskByIdParams>(
    GET_TASK_BY_ID,
    {
      variables: {
        taskId,
      },
      skip: !taskId,
    },
  )

  return {
    task: data && data.getTaskById,
    loading,
    error,
    taskRefetch: refetch,
  }
}
