import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IExportServiceParams, IUseGroupScoreExportResponse } from './exportData.interface'
import { exportGroupScoresService } from 'services/analytics/exportData/exportGroupScoresService'
import { IAnalyticsGroupOption } from 'pages/analytics/analytics.interface'
import { ExportGroupScoresOptionEnum } from 'services/analytics/exportData/exportData.interface'
import { useQuery } from '@apollo/client'
import { GET_GROUP } from 'gql/group/group.query'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { IGroup } from 'interfaces/groups'

const useGroupScoreExport = ({
  companyId,
  setLoading,
}: IExportServiceParams): IUseGroupScoreExportResponse => {
  const { t } = useTranslation()
  const [groupOptions, setGroupOptions] = useState<IAnalyticsGroupOption[]>([])
  const [selectedGroup, setSelectedGroup] = useState<IAnalyticsGroupOption | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<
    Record<ExportGroupScoresOptionEnum, boolean>
  >({
    [ExportGroupScoresOptionEnum.QUIZ]: false,
    [ExportGroupScoresOptionEnum.ASSESSMENT]: false,
    [ExportGroupScoresOptionEnum.TEST]: false,
  })

  const { data, loading: groupsLoading } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        status: { type: LowerCaseFilterableFieldType.EXACT, value: GridStatusEnums.ACTIVE },
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      },
      currentPage: 1,
      perPage: 0,
    },
    skip: !companyId,
  })

  useEffect(() => {
    if (!(data && data.getAllGroups?.data.length > 0)) return
    const filterOptionsMapped = data.getAllGroups.data.map((group: IGroup) => ({
      label: group.name,
      value: group.id,
    }))

    setGroupOptions(filterOptionsMapped)
  }, [data])

  const handleGroupChange = (e: IAnalyticsGroupOption): void => {
    setSelectedGroup(e)
  }

  const handleOptionChange = (e?: ChangeEvent<HTMLInputElement>): void => {
    if (!e) return
    console.log(e.target.name, e.target.checked)
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name as ExportGroupScoresOptionEnum]: e.target.checked,
    })
  }

  const { exportGroupScores, loading: exportLoading } = exportGroupScoresService()

  const handleGroupExport = async (): Promise<void> => {
    const selectedOptionsArr = Object.keys(selectedOptions)
      .filter(key => selectedOptions[key as ExportGroupScoresOptionEnum])
      .map(key => key as ExportGroupScoresOptionEnum)

    const link = await exportGroupScores({
      groupId: selectedGroup?.value as string,
      options: selectedOptionsArr,
    })
    if (link) window.open(link, '_blank')
  }

  useEffect(() => {
    setLoading(exportLoading)
  }, [exportLoading])

  useEffect(() => {
    setSelectedGroup(null)
  }, [companyId])

  return {
    t,
    groupOptions,
    groupsLoading,
    exportLoading,
    selectedGroup,
    handleGroupChange,
    handleGroupExport,
    selectedOptions,
    handleOptionChange,
  }
}

export default useGroupScoreExport
