import React, { ReactElement } from 'react'
import {
  Container,
  About,
  GridItemContentHeaderStyle,
  MoreButton,
  Row,
  PaddedContainer,
  PublishedIndicator,
  GridItemContainer,
} from './styled-components'
import { Props } from './testItem.interface'
import CheckBox from 'components/common/Checkbox'
import { useNavigate } from 'react-router-dom'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { actionConfig, GroupActionConfig } from '../testLayout/gridConfig'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemTitle,
} from 'components/common/Cards/styled-components'
import TooltipHeight from 'components/common/Tooltip/TooltipHeight'
import { IQuiz } from 'pages/quizzes/quiz.interface'

// Export the actionHandler function
export const actionHandler = (
  event: string,
  field: IQuiz,
  handlePublishClick?: (field: IQuiz) => void,
  handleOpenEditDrawer?: (id: string) => void,
  handleDeleteClick?: (id: string) => void,
  handleDeleteTestFromGroup?: (id: string) => void,
): void => {
  switch (event) {
    case 'publish':
      handlePublishClick && handlePublishClick(field)
      break
    case 'edit':
      handleOpenEditDrawer && handleOpenEditDrawer(field.id as string)
      break
    case 'delete':
      handleDeleteClick && handleDeleteClick(field.id as string)
      break
    case 'remove':
      handleDeleteTestFromGroup && handleDeleteTestFromGroup(field.id as string)
      break
  }
}

const TestItem = ({
  item,
  selectItem,
  isChecked,
  handleDeleteClick,
  handlePublishClick,
  isFromUser,
  setIsSearchType,
  handleClick,
  showMoreButton,
  showCheckBox,
  hasRemoveAction,
  disableNavigation = false,
  handleDeleteTestFromGroup,
}: Props): ReactElement => {
  const {
    attachment,
    questions = [],
    timeType,
    passRate = 0,
    randomization,
    published = false,
  } = item
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { t } = useTranslation()
  const handleOpenEditDrawer = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editTest', true, {
      id,
    })
  }

  const result = randomization ? 'Yes' : 'No'

  const handleAction = (event: string, field: IQuiz): void =>
    actionHandler(
      event,
      field,
      handlePublishClick,
      handleOpenEditDrawer,
      handleDeleteClick,
      handleDeleteTestFromGroup,
    )

  const actions = actionConfig(handleAction, state.userPermission, t, setIsSearchType)

  const removeAction = GroupActionConfig(handleAction, state.userPermission, t)

  return (
    <Container
      onClick={(): void => {
        if (handleClick) {
          handleClick(item)
        } else if (!disableNavigation) {
          navigate(`/tests/${item.id}`)
        }
      }}
      isFromUser={isFromUser}
    >
      <GridItemImage>
        <img src={attachment && attachment.link} />
      </GridItemImage>
      {!isFromUser && (
        <GridItemContainer>
          <GridItemContentHeaderStyle>
            <GridItemContainer>
              {showMoreButton ? (
                <PublishedIndicator $published={published} style={{ marginLeft: '10px' }}>
                  {published ? 'Published' : 'Unpublished'}
                </PublishedIndicator>
              ) : (
                <>
                  <CheckBox
                    onChange={(): void | undefined => {
                      selectItem && selectItem(item.id as string)
                    }}
                    checked={isChecked}
                  />
                  <PublishedIndicator $published={published}>
                    {published ? 'Published' : 'Unpublished'}
                  </PublishedIndicator>
                </>
              )}
            </GridItemContainer>
            {!showCheckBox && (
              <MoreButton>
                <GridActionMenu
                  actionConfig={hasRemoveAction ? removeAction : actions}
                  row={item}
                />
              </MoreButton>
            )}
          </GridItemContentHeaderStyle>
        </GridItemContainer>
      )}
      <GridItemContent>
        <GridItemContentHeader>
          <GridItemTitle style={{ fontWeight: 600 }}>
            <TooltipHeight text={item.name} lines={2} />
          </GridItemTitle>
        </GridItemContentHeader>
      </GridItemContent>
      <PaddedContainer>
        <Row>
          <About>{`Questions: ${questions?.length}`}</About>
          <About>{`Pass rate: ${passRate}%`}</About>
        </Row>
        <Row>
          <About>{`type: ${timeType} `}</About>
          <About>{`Randomize: ${result} `}</About>
        </Row>
      </PaddedContainer>
    </Container>
  )
}

export default TestItem
