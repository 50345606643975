import GroupProgressTab from 'pages/courses/CourseDetail/Tabs/GroupProgressTab'
import React, { ReactElement } from 'react'
import {
  CenterParagraph,
  SelectorContainer,
  Wrapper,
  InputContainer,
  SelectFieldWrapper,
  Content,
} from './styled-components'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'components/common/SelectField'
import { IGroupsAnalyticsProps } from './groupsAnalytics.interface'

import Statistic from './Statistic'
import { useFetchAnalytics } from 'hooks/analytics/useAnalytics'

const GroupsAnalytics = ({
  onCourseChange,
  onGroupChange,
  coursesOptions,
  groupsLoading,
  groupsOptions,
  coursesLoading,
  selectedCourse,
  selectedGroup,
  companyId,
  handleTabChange,
  tabValue,
}: IGroupsAnalyticsProps): ReactElement => {
  const { t } = useTranslation()

  const { analytics, loading } = useFetchAnalytics(
    selectedCourse?.value,
    companyId as string,
    selectedGroup?.value === 'all' ? undefined : selectedGroup?.value,
  )

  return (
    <Wrapper>
      <InputContainer $withPadding={true}>
        <SelectorContainer>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByCourse')}
              value={selectedCourse}
              options={coursesOptions}
              onChange={onCourseChange}
              isClearable
              isLoading={coursesLoading}
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByGroup')}
              value={selectedGroup}
              options={groupsOptions}
              onChange={onGroupChange}
              isClearable
              isDisabled={!selectedCourse?.value}
              isLoading={groupsLoading}
            />
          </SelectFieldWrapper>
        </SelectorContainer>
      </InputContainer>
      <Content>
        <Statistic
          companyId={companyId || ''}
          additionalQuantities={{
            groupsQuantity: analytics?.groupsQuantity,
            skillTestVideosQuantity: analytics?.skillTestVideosQuantity,
            studentsQuantity: analytics?.studentsQuantity,
          }}
          loading={loading}
        />
      </Content>

      {!!(
        selectedCourse?.value &&
        selectedGroup?.value &&
        selectedGroup?.value === 'all' &&
        groupsOptions?.length
      ) && (
        <GroupProgressTab
          companyId={companyId || ''}
          courseId={selectedCourse.originalId || selectedCourse.value}
          groupIds={groupsOptions?.slice(1).map(group => group.value)}
          t={t}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
        />
      )}

      {!!(selectedCourse?.value && selectedGroup?.value && selectedGroup?.value !== 'all') && (
        <GroupProgressTab
          companyId={companyId || ''}
          courseId={selectedCourse.originalId || selectedCourse.value}
          groupId={selectedGroup.value}
          t={t}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
        />
      )}

      {!selectedCourse?.value && <CenterParagraph>{t('analytics.selectCourse')}</CenterParagraph>}
    </Wrapper>
  )
}

export default GroupsAnalytics
