import { useLazyQuery } from '@apollo/client'
import { GET_TASKS_BY_COMPANY_ID_GROUPED_BY_TAGS } from 'gql/tasks.query'
import {
  GetTasksByCompanyIdGroupedByTagsResponse,
  IFetchMoreVariables,
  ITask,
} from './getTasksService.interface'

export const useFetchCompanyTasksGroupedByTags = (): {
  fetchCompanyTasks: (variables?: IFetchMoreVariables) => void
  data: ITask[]
  totalPages: number
  loading: boolean
  error: string | undefined
  refetch: (variables?: IFetchMoreVariables) => void
} => {
  const [fetchCompanyTasks, { data: resp, loading, error, refetch }] =
    useLazyQuery<GetTasksByCompanyIdGroupedByTagsResponse>(GET_TASKS_BY_COMPANY_ID_GROUPED_BY_TAGS)

  const errorMessage = error && error?.message.split(':')[1]
  const { data = [], totalPages = 1 } = (resp && resp.getTasksByCompanyIdGroupedByTags) || {}

  return {
    fetchCompanyTasks: (variables?: IFetchMoreVariables) => fetchCompanyTasks({ variables }),
    data,
    totalPages,
    loading,
    error: errorMessage,
    refetch,
  }
}
