import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import {
  Grid,
  ToolbarStyles,
  PaginationContainer,
  ToolbarPaddingStyles,
  ContentWrapper,
  GridTableWrapper,
} from './styled-components'

import Button from 'components/common/Button/CustomButton'
import { t } from 'i18next'
import { Input } from 'components/common/TextInput'
import TestItem from '../testItem/TestItem'
import { Toolbar, ToolbarItem } from '../../../components/common/Toolbar'
import Breadcrumb from 'components/common/BreadCrumbV2'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import Pagination from 'components/common/PaginationV2'
import { useUserValue } from 'context/UserContext'
import Loader from 'components/common/Loader/Loader'
import AddIcon from '@mui/icons-material/Add'
import Drawer from 'components/common/Drawer'
import FilterTestDrawer from '../filterTestDrawer/FilterTestDrawer'
import { ReactComponent as FilterAltOffIcon } from 'assets/remove-filter.svg'
import { ISelectedItem } from './testLayout.interface'
import { useTestLayoutServices, useGetTestAssessmentsByCompanyId, PER_PAGE } from 'hooks/tests'
import { ReactComponent as HorizontalSVG } from 'assets/horizontal-view.svg'
import { ReactComponent as GridSVG } from 'assets/grid-view.svg'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { SplashScreen } from 'components/common'
import { IFilterData } from 'pages/quizzes/quiz.interface'
import Tooltip from '@mui/material/Tooltip'
import GridV2 from 'components/common/GridV2/Grid'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

const TestsLayout = (): React.JSX.Element => {
  const [state] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [filterData, setFilterData] = useState<IFilterData>({
    filterOptions: null,
    values: null,
  })
  const companyId = state.selectedCompany?.id as string

  const { tests, loading, refetch } = useGetTestAssessmentsByCompanyId(
    currentPage,
    PER_PAGE,
    companyId,
  )

  const {
    selectItem,
    onSearchSubmit,
    handleDeleteClick,
    handleDeleteManyClick,
    handleDrawerOpen,
    handlePublish,
    handlePaginationClick,
    onSearchChange,
    searchText,
    setSearchText,
    isFiltered,
    isGridView,
    setIsGridView,
    selectAllItem,
    testListConfiguration,
    actions,
    redirectToTest,
    selectedItems,
    isItemSelected,
    handleChangeRowsPerPage,
    perPage,
  } = useTestLayoutServices({
    companyId,
    selectedItem,
    setSelectedItem,
    filterData,
    currentPage,
    setCurrentPage,
    perPage: PER_PAGE,
    refetch,
    setIsSearchType,
  })

  useEffect(() => {
    refetch({
      companyId,
      currentPage,
      perPage: PER_PAGE,
      filter: { ...filterData.filterOptions },
    })
  }, [filterData])

  const createButton = (): React.JSX.Element => (
    <Button
      text={`${t('actions.create')} ${t('tests_layout.test')}`}
      background='#06C68F'
      onClick={handleDrawerOpen}
      icon={<AddIcon className='icon' />}
    />
  )

  const breadCrumbData = [
    {
      label: 'Tests',
      icon: <Learning />,
    },
  ]

  if (!state?.selectedCompany?.id) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen subTitle={t('tests_layout.switch_company')} />
      </>
    )
  }
  if (
    tests &&
    companyId &&
    searchText === '' &&
    !isSearchType &&
    !isFiltered &&
    tests?.data.length <= 0
  ) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('tests_layout.no_tests')}
          subTitle={t('tests_layout.create_tests')}
          createButton={createButton}
        />
      </>
    )
  }
  return (
    <>
      <Breadcrumb data={breadCrumbData} />
      <>
        <Toolbar styles={ToolbarPaddingStyles}>
          <ToolbarItem>
            <Button
              text={`${t('actions.create')} ${t('tests_layout.test')}`}
              background='#06C68F'
              onClick={handleDrawerOpen}
              icon={<AddIcon className='icon' />}
            />
            <ToolbarItem>
              <GridMultipleActions
                selectedItems={selectedItem[currentPage]?.length || 0}
                actions={[
                  {
                    id: 0,
                    color: 'secondary',
                    tooltipText: `${t('actions.delete')}`,
                    disabled: !selectedItem[currentPage]?.length || false,
                    onClick: () => handleDeleteManyClick(selectedItem[currentPage]),
                    component: <DeleteIcon />,
                  },
                ]}
              />
            </ToolbarItem>
          </ToolbarItem>
          <ToolbarItem style={ToolbarStyles}>
            <ToolbarItem>
              {isGridView ? (
                <IconButton onClick={(): void => setIsGridView(false)}>
                  <GridSVG />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsGridView(true)}>
                  <HorizontalSVG />
                </IconButton>
              )}
            </ToolbarItem>
            <ToolbarItem $noGap>
              <IconButton onClick={(): void => setFilterDrawer(true)}>
                <Tooltip title={t('actions.filter')} arrow>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton
                  onClick={(): void => {
                    setFilterData({
                      filterOptions: null,
                      values: null,
                    })
                    setSearchText('')
                  }}
                >
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <FilterAltOffIcon />
                  </Tooltip>
                </IconButton>
              )}
            </ToolbarItem>
            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={onSearchChange}
                  icon={
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  $iconInStart
                />
              </form>
            </ToolbarItem>
          </ToolbarItem>
        </Toolbar>
        <ContentWrapper $isGrid={isGridView}>
          {tests &&
          state.selectedCompany?.id &&
          tests.data.length <= 0 &&
          (searchText !== '' || isFiltered) ? (
            <SplashScreen
              title={t('general.no_results_found')}
              subTitle={t('general.try_adjusting_your_search_or_filter')}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          ) : (
            <>
              {!isGridView ? ( // Show TestItem when isGridView is false
                <GridTableWrapper style={{ width: '100%' }}>
                  {tests && (
                    <GridV2
                      config={testListConfiguration}
                      data={tests.data}
                      actionConfig={actions}
                      actionTilesLength={1}
                      selectItem={selectItem}
                      selected={selectedItems.length}
                      selectAllItem={(): void =>
                        selectAllItem(
                          tests.data
                            .map(test => test.id)
                            .filter((id): id is string => id !== undefined),
                        )
                      }
                      selectedItems={selectedItems}
                      redirectToDetailsHandler={(test): void => redirectToTest(test.id)}
                    />
                  )}
                </GridTableWrapper>
              ) : (
                // Show GridV2 when isGridView is true
                <Grid>
                  {tests?.data.map((test, index: number) => (
                    <TestItem
                      key={index}
                      item={test}
                      setIsSearchType={setIsSearchType}
                      selectItem={selectItem}
                      handleDeleteClick={handleDeleteClick}
                      isChecked={test.id ? isItemSelected(test.id) : false}
                      handlePublishClick={handlePublish}
                    />
                  ))}
                </Grid>
              )}
              <PaginationContainer>
                {!isGridView && (
                  <PerPageDropdown
                    value={perPage}
                    onChange={(value): void =>
                      handleChangeRowsPerPage({
                        target: {
                          value: value.toString(),
                        } as HTMLInputElement,
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    options={[10, 20, 50]}
                  />
                )}
                <Pagination
                  currentPage={tests?.currentPage ?? 1}
                  totalPages={tests?.totalPages ?? 1}
                  handleChange={handlePaginationClick}
                />
              </PaginationContainer>
            </>
          )}
        </ContentWrapper>
        <Drawer opened={filterDrawer} toggleDrawer={(): void => undefined} totalWidth='700px'>
          <FilterTestDrawer
            onClose={(): void => setFilterDrawer(false)}
            setFilterData={setFilterData}
            filterData={filterData}
            companyId={companyId as string}
            setCurrentPage={setCurrentPage}
            t={t}
          />
        </Drawer>
      </>

      <>
        <>{loading && <Loader withBackground />}</>
      </>
    </>
  )
}

export default TestsLayout
