import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const TasksHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`
export const TasksIcon = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const StyledTableContainer = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 16px;
  td {
    width: 100%;
  }
  th:nth-child(2) {
    width: 500px;
  }
  th:nth-child(3) {
    width: 300px;
  }
`
export const StyledTagName = styled.span`
  color: rgb(2, 2, 16);
  font-weight: 500;
  font-size: 16px;
`
export const StyledDeleteContainer = styled.div`
  svg {
    color: black !important;
  }
`

export const StyledTasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1344px;
  height: 710px;
  gap: 16px;
`

export const Title = styled.div`
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 4px 0 20px;
`

export const StyledFilterWrapper = styled.div`
  width: 212px;
`

export const StyledFilterContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const StyledConditionTypeSpan = styled.span`
  display: inline-flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f2f7fb;
  color: #20a8c6;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
export const StyledConditionTypeNameSpan = styled.span`
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #e7e9ed;
  overflow: hidden;
  color: #68707a;
  text-overflow: ellipsis;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const StyledConditionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const StyledDownloadSvgContainer = styled.div`
  width: 45px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px !important;
    height: 24px !important;
  }
`

export const StyledConditionSpan = styled.span`
  width: 60px;
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #e7e9ed;
  color: #68707a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    background: ${({ hoverBackground }): string => hoverBackground || 'rgba(0, 0, 0, 0.1)'};
    ${({ hoverColor }): string =>
      (hoverColor &&
        `svg path {
        fill: ${hoverColor};
         }`) ||
      ''}
  }
  svg {
    font-size: 1rem;
    margin-right: 2px;
    transition: 0.3s ease;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderTop: '1px solid rgba(0,0,0,.2)',
  },
  border: {
    borderLeft: '1px solid #020210',
    borderBottom: '1px solid #020210',
    width: '16px',
    height: '16px',
  },
  paragraph: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#68707A',
  },
  header: {
    fontWeight: '700',
    lineHeight: '36px',
    color: '#020210',
  },
  btn: {
    color: '#000',
  },
}

export const GridAction = styled.div<{
  hoverColor?: string
  hoverBackground?: string
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  transition: 0.3s ease;
  border-radius: 50%;
  color: ${({ disabled }): string => (disabled ? '#A8A8A8' : '#31313d')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};

  &:hover {
    background: ${({ hoverBackground, disabled }): string =>
      disabled ? 'transparent' : hoverBackground || 'rgba(0, 0, 0, 0.1)'};
    ${({ hoverColor, disabled }): string | false | undefined =>
      !disabled &&
      hoverColor &&
      `svg path {
        fill: ${hoverColor};
      }`}
  }

  svg {
    font-size: 1rem;
  }
  svg path {
    fill: ${({ disabled }): string => (disabled ? '#A8A8A8' : '#31313d')};
  }
`
