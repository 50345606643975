/**
 * Get the duration of a video file
 * @param file - File to get the duration of
 * @returns Duration of the video file
 */
const getMediaDuration = (file: File, type: 'video' | 'audio' = 'video'): Promise<number> => {
  return new Promise(resolve => {
    const media = document.createElement(type)
    media.preload = 'metadata'

    media.onloadedmetadata = function (): void {
      window.URL.revokeObjectURL(media.src)
      resolve(media.duration)
    }

    media.src = URL.createObjectURL(file)
  })
}

export default getMediaDuration
