import React, { FormEvent } from 'react'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormField,
  FormFields,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { AsyncSelect } from 'components/common/SelectField'
import { generateFullName } from 'utils/utils'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { DataOptionFieldEnums } from '../AddGroupDrawer/addGroupDrawer.interface'
import useGroupDrawer from 'hooks/group/useGroupDrawers/useGroupDrawer'
import useEditGroupDrawer from 'hooks/group/useGroupDrawers/useEditGroupDrawer'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'
import MyLoader from 'loading/loading'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { IEditGroupDrawerProps } from 'hooks/group/useGroupDrawers/useGroupDrawer.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const EditGroupDrawer = ({
  id,
  companyId,
  courseData,
  onClose,
  afterSave,
}: IEditGroupDrawerProps): React.JSX.Element => {
  const { t, handleSelectChange, courses, coursesLoading, adminData, adminLoading } =
    useGroupDrawer({
      companyId,
    })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  } = useEditGroupDrawer({
    id,
    companyId,
    courseData,
    onClose,
    afterSave,
  })
  return (
    <>
      {loading && <MyLoader width={250} />}
      <CustomDrawerHeader title={`${t('actions.edit')} ${t('general.group')}`} onClose={onClose} />

      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width='100%'>
                <TextInput
                  title={t('user_details.first_name')}
                  required
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  size='small'
                  placeholder={t('groups_layout.group_name')}
                  name='name'
                  type='text'
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <Title $required>{t('tasks_layout.select_admin')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e): void => handleSelectChange(DataOptionFieldEnums.ADMINS, e)}
                  onChange={(e: ISelectOption): void => setFieldValue('admin', e)}
                  data={(adminData && adminData.usersByRole.data) || []}
                  value={values.admin}
                  label={t('form_fields.type_to_search_admin')}
                  loading={adminLoading}
                  labelDataName={generateFullName}
                  valueDataKey='id'
                  error={!!errors.admin}
                  touched={!!touched.admin}
                  errorMessage={errors.admin ? (errors.admin as string) : ''}
                />
              </FormField>

              {!courseData && (
                <FormField>
                  <Title>{t('tasks_layout.select_courses')} </Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e): void => handleSelectChange(DataOptionFieldEnums.COURSES, e)}
                    onChange={(e: ISelectOption): void => setFieldValue('courses', e)}
                    data={courses?.getAllCourseOptions?.data || []}
                    value={values.courses}
                    label={t('form_fields.type_to_search_courses')}
                    loading={coursesLoading}
                    valueDataKey='id'
                    labelDataKey='name'
                    error={!!errors.courses}
                    touched={!!touched.courses}
                    errorMessage={(errors.courses as string) || ''}
                  />
                </FormField>
              )}
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size='small'
                  placeholder={`${t('form_fields.location')}`}
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size='small'
                  name='note'
                  type='text'
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows='3'
                />
              </FormField>
              <FormField width='100%'>
                <CustomSwitcher
                  onChange={(e): void => {
                    setFieldValue('isNewsFeedAvailable', e.target.checked)
                  }}
                  label='Is News Feed Available for this Group?'
                  name='isNewsFeedAvailable'
                  value={values.isNewsFeedAvailable}
                />
              </FormField>
            </FormFields>
            {/* <Drawer
              opened={drawer.addAdmin}
              toggleDrawer={handleInnerDrawerClose}
              totalWidth="600px"
            >
              <AddAdmin
                drawerData={drawer.args}
                onClose={(): void => setDrawer({ addAdmin: false, args: {} })}
              />
            </Drawer> */}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                color='secondary'
                type='small'
                onClick={handleSubmit}
                isDisabled={loading}
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>

      {/* <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={() => setDrawer({ addAdmin: false, args: {} })}
        totalWidth="600px"
      >
        <AddAdmin
          drawerData={{ ...drawer.args, companyId: props?.data?.companyId }}
          onClose={() => setDrawer({ addAdmin: false, args: {} })}
        />
      </Drawer> */}
    </>
  )
}

export default EditGroupDrawer
