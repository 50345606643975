import React, { ReactElement } from 'react'
import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { ToolbarItem } from 'components/common/Toolbar'
import { Wrapper } from './styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import { IGroup } from 'interfaces/groups'
import TestItem from 'pages/tests/testItem/TestItem'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import useGroupTestAssessments from 'hooks/tests/GroupTestAssessment/useGroupTestAssessment'

export const GroupTestAssessments = ({ group }: { group: IGroup }): ReactElement => {
  const {
    t,
    searchText,
    onSearchChange,
    tests,
    handleDeleteTestFromGroup,
    navigate,
    loading,
    handlePaginationClick,
    isSearchType,
    searchValue,
    createButton,
    initialLoad,
    onSearchSubmit,
    totalPages,
    currentPage,
  } = useGroupTestAssessments({ group })

  return (
    <>
      {!loading &&
      !isSearchType &&
      !searchValue &&
      (!group.testAssessments || (!tests?.length && !searchText)) ? (
        <SplashScreen
          title={t('tests_layout.no_tests')}
          subTitle={t('tests_layout.create_tests')}
          createButton={createButton}
          marginTop={20}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <>
          {!initialLoad && (
            <Wrapper>
              <div>{createButton()}</div>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchText}
                    onChange={onSearchChange}
                    icon={
                      <IconButton onClick={onSearchSubmit}>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </ToolbarItem>
            </Wrapper>
          )}
          <div>
            {loading ? (
              <MyLoader />
            ) : (
              tests && (
                <>
                  {tests.length === 0 ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t('general.try_adjusting_your_search_or_filter')}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <Grid
                      className='card'
                      style={{ position: 'relative', marginTop: '17px' }}
                      cols={4}
                    >
                      {tests.map((test, index: number) => (
                        <TestItem
                          key={index}
                          item={test}
                          showMoreButton={true}
                          hasRemoveAction={true}
                          handleDeleteTestFromGroup={handleDeleteTestFromGroup}
                          handleClick={({ id: testAssessmentId, companyId }): void =>
                            navigate(`/`, {
                              state: {
                                testAssessmentId,
                                groupId: group.id,
                                companyId,
                              },
                            })
                          }
                        />
                      ))}
                    </Grid>
                  )}
                  {tests.length > 0 && (totalPages ?? 0) > 1 && (
                    <div
                      style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages ?? 0}
                        handleChange={handlePaginationClick}
                      />
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </>
      )}
    </>
  )
}
