import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react'
import { Container } from './styled-components'
import { GET_GROUP } from 'gql/group/group.query'
import { useQuery } from '@apollo/client'
import { columnConfig, actionConfig, groupActionConfig } from './groupGridConfig'
import { useUserValue } from 'context/UserContext'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/GridV2'
import Drawer from 'components/common/Drawer'
import AddGroupInCourseDrawer from '../AddCourseDrawer/AddGroupInCourseDrawer'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'

import { AddGroupDrawer } from 'pages/group'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import { EditGroupDrawer } from 'pages/group'

import { FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { Button } from 'components/common/Button'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import MyLoader from 'loading/loading'
import useAddRemoveGroupFromCourse from 'hooks/group/useAddRemoveGroupFromCourse'
import { useTranslation } from 'react-i18next'
import { Action } from 'components/UsersLayout/styled-components'
import { DrawerEventEmitter } from 'helpers/drawer'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IGroupBrief } from '../CourseTutors/tutorsLayout.interface'
import { useSwal } from 'hooks/useSwal'
import { IGroup } from 'interfaces/groups'
import { useNavigate } from 'react-router-dom'
import { SplashScreen } from 'components/common'
import { ActionTypes, ICourseGroupTab2Params } from './editCourse.interrface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { ICourseBriefData } from '../courseList.interface'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { LoaderContainer } from 'pages/tests/testDetails/studentsLayout/styled-components'
import { PaginationContainer } from 'pages/group/GroupTasks/styled-components'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import Pagination from 'components/common/PaginationV2'
import { calculateNewCurrentPage } from 'utils/pagination'

const loaderStyles = {
  left: 0,
  top: 0,
}

const CourseGroups = ({
  setSelectedGroup,
  selectedGroup,
  handleDeleteCourse,
  handleButtonClick,
  step,
  courseData,
  editMode,
  handleChangeStep,
  manualCompanyId,
  hasSearchByCompany,
  hasPurchasedCourses,
  formFooterVisible = true,
}: ICourseGroupTab2Params): ReactElement => {
  const { fireSwal } = useSwal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [userState] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [defaultFilter, setDefaultFilter] = useState<IFilterQueryType>({
    company: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: manualCompanyId || userState.selectedCompany?.id,
    },
  })
  const [drawer, setAddGroupDrawer] = useState<{
    addGroup: boolean
    createGroup: boolean
    editGroup: boolean
  }>({
    addGroup: false,
    createGroup: false,
    editGroup: false,
  })

  const { addGroupsToCourse, removeGroupsFromCourse, loading } = useAddRemoveGroupFromCourse()

  const handleAddGroupsToCourse = (updatedGroups?: IGroupBrief[]): void => {
    if (!updatedGroups?.length || !courseData) {
      setAddGroupDrawer({ ...drawer, addGroup: false })
      return
    }

    const ids = updatedGroups.map(g => g.groupId)
    addGroupsToCourse((courseData as ICourseBriefData).courseId, ids, async () => {
      const newCurrentPage = calculateNewCurrentPage(totalItems + ids.length, perPage, currentPage)
      setCurrentPage(newCurrentPage)
      await refetch({
        filter: defaultFilter,
        currentPage: newCurrentPage,
        perPage,
      })

      setSelectedGroup([...updatedGroups, ...selectedGroup])
    })
    setAddGroupDrawer({ ...drawer, addGroup: false })
  }

  const deleteGroup = (ids: string[]): void => {
    if (!courseData) return

    removeGroupsFromCourse((courseData as ICourseBriefData).courseId, ids, async () => {
      const newCurrentPage = calculateNewCurrentPage(totalItems - ids.length, perPage, currentPage)
      setCurrentPage(newCurrentPage)
      await refetch({
        filter: defaultFilter,
        currentPage: newCurrentPage,
        perPage,
      })
    })

    const remainingGroups = selectedGroup.filter(group => !ids.includes(group.groupId))
    setSelectedGroup(remainingGroups)
  }

  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    filterOptions: {
      company: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: manualCompanyId || userState.selectedCompany?.id,
      },
    },
    filterValues: null,
    opened: false,
  })
  const [currentGroupId, setCurrentGroupId] = useState('')

  useEffect(() => {
    if (courseData) {
      setDefaultFilter({
        ...defaultFilter,
        courses: {
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: [(courseData as ICourseBriefData).courseId],
          nestedField: 'courseId',
        },
      })
    }
  }, [courseData])

  const {
    data,
    loading: GetGroupLoading,
    refetch,
  } = useQuery(GET_GROUP, {
    variables: {
      filter: defaultFilter,
      currentPage: 1,
      perPage,
    },
  })

  const totalItems = data?.getAllGroups?.totalCount || 0
  const totalPages = Math.ceil(totalItems / perPage)

  const handleSelect = (id: string): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = (): void => {
    if (!selectAll) {
      const newArr: string[] = selectedGroups.map(n => n.id) as string[]
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const selectedGroups: IGroup[] = data?.getAllGroups?.data || []

  const handleDeleteGroup = (ids: string[]): void => {
    if (ids.length > 0) {
      const params = {
        title:
          ids.length > 1
            ? `${t('popups.delete_many')} ${t('general.groups')}`
            : `${t('popups.delete_single')} ${t('general.group')}`,
        onConfirm: (): void => {
          deleteGroup(ids)
        },
        confirmText: t('popups.confirm_delete'),
      }
      fireSwal(params)
      setSelectedItem([])
    }
  }

  const filterFun = (i: IGroup): IGroup | boolean =>
    searchText ? (i?.name?.toLowerCase().includes(searchText.toLowerCase()) && i) || false : i

  const handleOpenDrawer = (action: string): void => {
    setAddGroupDrawer({
      ...drawer,
      [action]: true,
    })
  }

  const handleClick = (e: IGroup): void => navigate(`/group/${e.id}`)

  const handleActionClick = (e: string | string[] | IGroup, action: ActionTypes): void => {
    switch (action) {
      case ActionTypes.EDIT:
        setCurrentGroupId(e as string)
        setAddGroupDrawer({ ...drawer, editGroup: true })
        break
      case ActionTypes.MODIFY_STUDENTS:
        DrawerEventEmitter.emit('openDrawer', 'addStudentInGroup', true, {
          groupData: e,
        })
        break
      case ActionTypes.ASSIGN_TUTOR:
        DrawerEventEmitter.emit('openDrawer', 'assignTutor', true, {
          groupData: e,
          courseData,
        })
        break
      default:
        handleDeleteGroup(e as string[])
    }
  }

  const handleCancelClick = (): void => {
    if (handleDeleteCourse) handleDeleteCourse(true)
  }

  const config = columnConfig(
    handleClick,
    handleSelect,
    t,
    (courseData as ICourseBriefData).courseId,
  )
  const actions = actionConfig(handleActionClick, t)
  const buttonActions = groupActionConfig(handleOpenDrawer, t)

  const handlePaginationClick = async (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): Promise<void> => {
    try {
      setCurrentPage(newPage)
      await refetch({
        filter: defaultFilter,
        currentPage: newPage,
        perPage,
      })
      setSelectedItem([])
    } catch (error) {
      console.error('Error during refetch:', error)
    }
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      filter: defaultFilter,
      currentPage,
      perPage: newPerPage,
    })
    setSelectedItem([])
  }

  const createButton = (): React.JSX.Element => (
    <>
      <ToolbarItem>
        <GridActionMenu
          actionConfig={buttonActions}
          row={{}}
          icon={
            <Button
              className='group-button'
              text={t('general.groups')}
              size='small'
              color='secondary'
              icon={<ArrowDropDownIcon />}
              iconPosition='right'
              background='#06C68F'
            />
          }
        />
      </ToolbarItem>
    </>
  )

  return (
    <>
      {GetGroupLoading || loading ? (
        <LoaderContainer>
          <MyLoader />
        </LoaderContainer>
      ) : (
        <>
          {!selectedGroups?.length ? (
            <SplashScreen
              title={t('courses_layout.no_groups')}
              subTitle={t('courses_layout.create_groups_information')}
              createButton={createButton}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          ) : (
            <FormGroupWrapper>
              <Container>
                {loading && <MyLoader width={250} speed={0.8} style={loaderStyles} />}
                <Toolbar styles={{ justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <ToolbarItem>
                      <GridActionMenu
                        actionConfig={buttonActions}
                        row={{}}
                        icon={
                          <Button
                            className='group-button'
                            text={t('general.groups')}
                            size='small'
                            color='secondary'
                            icon={<ArrowDropDownIcon />}
                            iconPosition='right'
                            background='#06C68F'
                          />
                        }
                      />
                    </ToolbarItem>

                    <GridMultipleActions
                      selectedItems={selectedItem.length}
                      actions={[
                        {
                          id: 0,
                          color: 'secondary',
                          tooltipText: t('courses_layout.delete_groups_from_course'),
                          disabled: selectedItem.length < 1,
                          onClick: (): void => handleDeleteGroup(selectedItem),
                          component: (
                            <Action $hoverColor='#EA382A'>
                              <DeleteIcon />
                            </Action>
                          ),
                        },
                      ]}
                    />
                  </div>
                  <ToolbarItem>
                    <TextInput
                      label={t('general.search_placeholder')}
                      type='text'
                      size='small'
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        setSearchText(e.target.value)
                      }
                      value={searchText}
                      $iconInStart
                      icon={
                        <IconButton>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </ToolbarItem>
                </Toolbar>

                {loading ? (
                  <MyLoader />
                ) : (selectedGroups && selectedGroups.filter(filterFun)).length === 0 ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.try_adjusting_your_search_or_filter')}
                    textMarginTop='40px'
                    isSearchOrFilter={true}
                  />
                ) : (
                  <>
                    <Grid
                      data={selectedGroups.filter(filterFun)}
                      config={config}
                      actionConfig={actions}
                      selectAllItem={selectAllItem}
                      selected={selectedItem.length}
                      selectItem={handleSelect}
                      selectedItems={selectedItem}
                      actionTilesLength={2}
                      redirectToDetailsHandler={handleClick}
                    />
                    <PaginationContainer>
                      <PerPageDropdown
                        value={perPage}
                        onChange={(value): void =>
                          handleChangeRowsPerPage({
                            target: {
                              value: value.toString(),
                            } as HTMLInputElement,
                          } as React.ChangeEvent<HTMLInputElement>)
                        }
                        options={[10, 20, 50]}
                      />
                      <Pagination
                        currentPage={currentPage ?? 1}
                        totalPages={totalPages ?? 1}
                        handleChange={handlePaginationClick}
                      />
                    </PaginationContainer>
                  </>
                )}
              </Container>
            </FormGroupWrapper>
          )}

          {formFooterVisible && (
            <FormFooter>
              <FormButtons multiCols={true}>
                <Button
                  text={t('actions.cancel')}
                  type='small'
                  onClick={handleCancelClick}
                  background='#E0E1E2'
                  textColor='#414141'
                />

                {!editMode && (
                  <Button
                    text={t('actions.previous')}
                    type='small'
                    color='secondary'
                    onClick={(): void => handleChangeStep && handleChangeStep(1)}
                    background='#E0E1E2'
                    textColor='#414141'
                  />
                )}

                {(step as number) <= 1 && (
                  <Button
                    text={t('actions.save_as_draft')}
                    type='small'
                    // isDisabled={selectedGroup.length === 0}
                    color='secondary'
                    onClick={(): void => handleButtonClick && handleButtonClick('draft')}
                    textColor='#06C68F'
                    background='#f5f5f5'
                    outline='1px solid #06C68F'
                  />
                )}

                <Button
                  text={t('actions.save_and_next')}
                  type='small'
                  // isDisabled={selectedGroup.length === 0}
                  color='secondary'
                  onClick={(): void => handleButtonClick && handleButtonClick('next')}
                  background='#06C68F'
                />
              </FormButtons>
            </FormFooter>
          )}

          <Drawer opened={drawer.addGroup} totalWidth='600px'>
            {GetGroupLoading ? (
              <MyLoader width={250} speed={0.8} style={loaderStyles} />
            ) : (
              data && (
                <AddGroupInCourseDrawer
                  onClose={(updatedGroups?: IGroupBrief[]): void => {
                    handleAddGroupsToCourse(updatedGroups)
                  }}
                  data={data.getAllGroups}
                  selectedGroup={selectedGroup}
                  selectGroup={(e): void => setSelectedGroup(prev => [...prev, e] as IGroupBrief[])}
                  currentGroupIds={selectedGroups?.map(item => item.id) as string[]}
                  companyId={manualCompanyId || (userState.selectedCompany?.id as string)}
                  hasSearchByCompany={hasSearchByCompany}
                  groups={data.getAllGroups}
                  refetch={refetch}
                />
              )
            )}
          </Drawer>

          <Drawer opened={drawer.createGroup} totalWidth='650px'>
            <AddGroupDrawer
              afterSave={(): void => {
                refetch()
              }}
              onClose={(): void => setAddGroupDrawer({ ...drawer, createGroup: false })}
              companyId={
                manualCompanyId ||
                userState.selectedCompany?.id ||
                (userState.currentUser.companyId as string)
              }
              courseData={courseData as ICourseBriefData[]}
            />
          </Drawer>

          <Drawer opened={drawer.editGroup} toggleDrawer={(): void => undefined} totalWidth='700px'>
            <EditGroupDrawer
              id={currentGroupId}
              afterSave={(): void => {
                setCurrentGroupId('')
              }}
              onClose={(): void => setAddGroupDrawer({ ...drawer, editGroup: false })}
              companyId={(manualCompanyId as string) || (userState.selectedCompany?.id as string)}
            />
          </Drawer>
          <Drawer
            opened={!!filterDrawer.opened}
            toggleDrawer={(): void => undefined}
            totalWidth='600px'
          >
            <FilterDrawer
              closeDrawer={(): void => setFilterDrawer({ ...filterDrawer, opened: false })}
              setFilterOptions={setFilterDrawer}
              refetchGroup={refetch}
              setCurrentPage={(): void => undefined}
              defaultFilter={defaultFilter}
              companyId={manualCompanyId || userState.selectedCompany?.id}
              useCompanyId={true}
              hasPurchasedCourses={hasPurchasedCourses}
            />
          </Drawer>
        </>
      )}
    </>
  )
}

export default CourseGroups
