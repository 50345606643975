import React, { ReactElement, useEffect } from 'react'
import {
  FormRules,
  FormRulesItem,
  FormGroupWrapper,
  FormGroup,
  FormGroupItem,
  QuestionContainer,
  StyledTextareaAutosize,
  AddMoreContainer,
} from './styled-components'
import { ReactComponent as AddCircleIcon } from 'assets/dark-add-alt.svg'
import { ReactComponent as RemoveCircleIcon } from 'assets/remove-alt.svg'
import { ReactComponent as RemoveCircleIconDisable } from 'assets/remove-alt-disable.svg'
import Radio from '@mui/material/Radio'
import Checkbox from 'components/common/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { QuestionTypeEnums } from './CreateQuestion.interface'
import { Title } from 'components/DrawerLayout/styled-components'
import Tooltip from '@mui/material/Tooltip'
import { TFunction } from 'interfaces/TFunction'
import { IAnswer, IQuestionItemProps } from '../EditQuestionDrawer/editQuestionDrawer.interface'

const renderMulti = (
  t: TFunction,
  handleAddAnswerClick: (answerType: string) => void,
  handleRemoveAnswerClick: (answerType: string, index: number) => void,
  multiAnswers: string[] | IAnswer[],
  handleAnswerChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    answerType: string,
    index: number,
  ) => void,
  handleSelect: (e: React.ChangeEvent<HTMLInputElement> | number, type: string) => void,
  selectedMultiAnswers: string[],
  fieldsValid?: boolean,
  selectedValid?: boolean,
): ReactElement => {
  if (multiAnswers.length < 2) {
    multiAnswers = [...multiAnswers, { value: '' }, { value: '' }] as IAnswer[]
  }

  return (
    <>
      <Title $required className='full'>
        {t('create_question.add_and_select_correct_answer')}
      </Title>
      <FormRules>
        <FormRulesItem className={fieldsValid ? 'valid' : ''}>
          {t('create_question.please_add_and_fill_all_answers')}
        </FormRulesItem>
        <FormRulesItem className={selectedValid ? 'valid' : ''}>
          {t('create_question.please_add_and_select_more_than_one_correct_answer')}
        </FormRulesItem>
      </FormRules>
      <FormGroupWrapper>
        <FormGroup>
          {multiAnswers.map((multiAnswer, index) => (
            <FormGroupItem key={index}>
              <Checkbox
                name={`multiple-${index}`}
                value={index}
                checked={selectedMultiAnswers?.includes(index?.toString())}
                onChange={(): void => handleSelect(index, 'multiple')}
              />
              <StyledTextareaAutosize
                name={`multiple-${index}`}
                aria-label='minimum height'
                minRows={2}
                value={(multiAnswer as IAnswer).value}
                placeholder={t('general.type_answer')}
                onChange={(e): void => handleAnswerChange(e, 'multiple', index)}
              />

              {index < 2 && multiAnswers.length < 3 ? (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>
                      {t('questions_layout.disabled_option')}
                    </span>
                  }
                >
                  <RemoveCircleIconDisable />
                </Tooltip>
              ) : (
                <RemoveCircleIcon
                  onClick={(): void => handleRemoveAnswerClick('multiple', index)}
                  style={{ color: '#06C68F' }}
                />
              )}
            </FormGroupItem>
          ))}
        </FormGroup>
      </FormGroupWrapper>
      <AddMoreContainer onClick={(): void => handleAddAnswerClick('multiple')}>
        <span>{t('general.add_answer')}</span>
        <AddCircleIcon style={{ color: '#06C68F' }} />
      </AddMoreContainer>
    </>
  )
}

const renderChoice = (
  t: TFunction,
  handleAddAnswerClick: (answerType: string) => void,
  choiceAnswers: IAnswer[],
  handleRemoveAnswerClick: (answerType: string, index: number) => void,
  handleAnswerChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    answerType: string,
    index: number,
  ) => void,
  selectedChoiceAnswer?: string,
  handleSelect?: (event: React.ChangeEvent<HTMLInputElement>, answerType: string) => void,
  fieldsValid?: boolean,
  selectedValid?: boolean,
): ReactElement => {
  if (choiceAnswers.length < 2) {
    choiceAnswers = [...choiceAnswers, { value: '' }, { value: '' }]
  }

  return (
    <>
      <Title $required className='full'>
        {t('create_question.add_and_select_correct_answer')}
      </Title>
      <FormRules>
        <FormRulesItem className={fieldsValid ? 'valid' : ''}>
          {t('create_question.please_add_and_fill_all_answers')}
        </FormRulesItem>
        <FormRulesItem className={selectedValid ? 'valid' : ''}>
          {t('create_question.please_select_one_correct_answer')}
        </FormRulesItem>
      </FormRules>
      <FormGroupWrapper>
        <FormGroup>
          {choiceAnswers.map((choiceAnswer: IAnswer, index: number) => (
            <FormGroupItem key={index}>
              <Radio
                name='single'
                value={index}
                checked={selectedChoiceAnswer === index.toString()}
                onChange={(e): void => handleSelect?.(e, 'single')}
              />
              <StyledTextareaAutosize
                name={`single-${index}`}
                aria-label='minimum height'
                minRows={2}
                placeholder={t('general.type_answer')}
                value={choiceAnswer.value}
                onChange={(e): void => handleAnswerChange(e, 'single', index)}
              />

              {index < 2 && choiceAnswers.length < 3 ? (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>
                      {t('questions_layout.disabled_option')}
                    </span>
                  }
                >
                  <RemoveCircleIconDisable />
                </Tooltip>
              ) : (
                <RemoveCircleIcon
                  onClick={(): void => handleRemoveAnswerClick('single', index)}
                  style={{ color: '#06C68F' }}
                />
              )}
            </FormGroupItem>
          ))}
        </FormGroup>
      </FormGroupWrapper>
      <AddMoreContainer onClick={(): void => handleAddAnswerClick('single')}>
        <span>{t('general.add_answer')}</span>
        <AddCircleIcon style={{ color: '#06C68F' }} />
      </AddMoreContainer>
    </>
  )
}

const renderBool = (
  onBoolChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  boolAnswer: boolean,
  t: TFunction,
): ReactElement => (
  <QuestionContainer>
    <Title $required>{t('create_question.tick_correct_answer')}:</Title>
    <RadioGroup
      row
      aria-label='position'
      name='position'
      defaultValue='top'
      onChange={onBoolChange}
      value={`${boolAnswer}`}
    >
      <FormControlLabel
        value='true'
        control={<Radio color='secondary' />}
        label={t('general.true')}
      />
      <FormControlLabel
        value='false'
        control={<Radio color='secondary' />}
        label={t('general.false')}
      />
    </RadioGroup>
  </QuestionContainer>
)

const QuestionItem = ({
  type,
  boolAnswer,
  choiceAnswers = [],
  multiAnswers = [],
  onBoolChange,
  handleAddAnswerClick,
  handleRemoveAnswerClick,
  handleAnswerChange,
  selectedChoiceAnswer,
  selectedMultiAnswers,
  handleSelect,
  fieldsValid,
  selectedValid,
}: IQuestionItemProps): ReactElement => {
  const { t } = useTranslation()
  useEffect(() => {
    const addAnswersIfNeeded = (typeEnum: string, answers: IAnswer[], answerType: string): void => {
      if (type === typeEnum && answers.length < 2) {
        handleAddAnswerClick?.(answerType)
        handleAddAnswerClick?.(answerType)
      }
    }

    addAnswersIfNeeded(QuestionTypeEnums.SINGLE, choiceAnswers as IAnswer[], 'single')
    addAnswersIfNeeded(QuestionTypeEnums.MULTIPLE, multiAnswers as IAnswer[], 'multiple')
  }, [type, choiceAnswers, multiAnswers, handleAddAnswerClick])

  return (
    <>
      {type === QuestionTypeEnums.BOOLEAN && renderBool(onBoolChange!, boolAnswer, t)}
      {type === QuestionTypeEnums.SINGLE &&
        renderChoice(
          t,
          handleAddAnswerClick!,
          choiceAnswers as IAnswer[],
          handleRemoveAnswerClick!,
          handleAnswerChange!,
          selectedChoiceAnswer,
          handleSelect,
          fieldsValid,
          selectedValid,
        )}
      {type === QuestionTypeEnums.MULTIPLE &&
        renderMulti(
          t,
          handleAddAnswerClick!,
          handleRemoveAnswerClick!,
          multiAnswers,
          handleAnswerChange!,
          handleSelect!,
          selectedMultiAnswers!,
          fieldsValid,
          selectedValid,
        )}
    </>
  )
}

export default QuestionItem
