import React from 'react'
import { Button } from 'components/common/Button'
import Slider from '@mui/material/Slider'
import SelectAsync from 'components/common/Select/SelectAsync'
import Typography from '@mui/material/Typography'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { FilterDrawerProps } from './filterDrawer.interface'
import useFilterDrawer from 'hooks/modules/FilterDrawer/useFilterDrawer'
import { IFilterOptionsType } from 'components/common/FilterInterface/filter.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const FilterDrawer = (props: FilterDrawerProps): React.JSX.Element => {
  const {
    onFormSubmit,
    handleSelectChange,
    handleFieldChange,
    data,
    filterData,
    loading,
    t,
    range,
    setRange,
  } = useFilterDrawer(props)

  return (
    <>
      <CustomDrawerHeader
        title={`${t('general.advanced_filters')} - ${t('actions.to_filter')} ${t(
          'general.modules',
        )}`}
        onClose={props.onClose}
      />
      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroup>
              {!props.courseId && (
                <FormGroupItem>
                  <SelectAsync
                    className='select-course'
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={
                      handleFieldChange('courses') as (
                        selectedOption: string | IFilterOptionsType | IFilterOptionsType[] | null,
                      ) => void
                    }
                    data={data?.getAllCourse?.data || []}
                    value={(filterData.courses as []) || []}
                    label={t('general.course')}
                    loading={loading}
                  />
                </FormGroupItem>
              )}

              <FormGroupItem>
                <Typography id='range-slider' gutterBottom>
                  {t('general.duration')}
                </Typography>
                <Slider
                  value={range}
                  onChange={(e: Event, i: number | number[]): void => setRange(i as number[])}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  min={0}
                  max={500}
                />
              </FormGroupItem>
            </FormGroup>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={(): void => props.onClose()}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text={t('actions.filter')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
