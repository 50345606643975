import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAnalyticsTestOption } from '../useAnalyticsData.interface'
import {
  IExportServiceParams,
  IUseTestAssessmentsScoreExportResponse,
} from './exportData.interface'
import { exportTestAssessmentsScoreService } from 'services/analytics/exportData/exportTestAssessmentsScoreService'

const useTestAssessmentsScoreExport = ({
  companyId,
  setLoading,
}: IExportServiceParams): IUseTestAssessmentsScoreExportResponse => {
  const { t } = useTranslation()
  const [selectedTest, setSelectedTest] = useState<IAnalyticsTestOption | null>(null)

  const handleTestChange = (e: IAnalyticsTestOption): void => {
    setSelectedTest(e)
  }

  const { exportTestAssessmentsScore, loading: exportLoading } = exportTestAssessmentsScoreService()

  const handleTestExport = async (): Promise<void> => {
    const link = await exportTestAssessmentsScore(selectedTest?.value as string)
    if (link) window.open(link, '_blank')
  }

  useEffect(() => {
    setLoading(exportLoading)
  }, [exportLoading])

  useEffect(() => {
    setSelectedTest(null)
  }, [companyId])

  return {
    t,
    exportLoading,
    selectedTest,
    handleTestChange,
    handleTestExport,
  }
}

export default useTestAssessmentsScoreExport
