import { useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterCoachesSchema } from 'helpers/validationSchemas'
import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { roles } from 'utils/permission'
import { useUserValue } from 'context/UserContext'
import {
  ICoachesFilterOptions,
  ICoachesCompanyFilter,
  ICoachesCourseFilter,
  IUseFilterCoachesProps,
  IUseFilterCoachesReturn,
} from 'pages/coaches/FilterCoaches/filterCoaches.interface'
import { coachesFilterOptions } from 'components/UsersLayout/staticData'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

export const useFilterCoaches = ({
  filterOptions,
  setFilterOptions,
  filter,
  defaultFilter,
  refetchStudents,
  closeDrawer,
  manualCompanyId,
  forAssignCoaches,
  useCompanyId,
}: IUseFilterCoachesProps): IUseFilterCoachesReturn => {
  const { t } = useTranslation()
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [state] = useUserValue()
  const { filterRole, setFilterRole } = useFilterRoleContext()
  const { createDate, setCreateDate } = useFilterContext()
  const [error, setError] = useState(false)

  const [fetchCompany, { data: companyData, loading: companyLoading }] = useLazyQuery(GET_COMPANIES)

  const [fetchCourse, { data: courseData, loading: courseLoading }] = useLazyQuery(GET_ALL_COURSE)

  setFilterRole('COACH')
  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: coachesFilterOptions,
      validationSchema: FilterCoachesSchema,
      onSubmit(values) {
        const formValues: IFilterQueryType = {}

        if (values.firstName) {
          formValues.firstName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.firstName,
          }
        }
        if (values.lastName) {
          formValues.lastName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.lastName,
          }
        }
        if (values.note) {
          formValues.note = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.note,
          }
        }
        if (values.location) {
          formValues.location = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.location,
          }
        }

        if (values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        if (values.group) {
          formValues.group = {
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
            value: values.group.map((group: { value: string }) => group.value),
            nestedField: 'groupId',
          }
        }

        if (values.course) {
          formValues.courses = {
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
            value: values.course.map((i: { value: string }) => {
              return i.value
            }),
            nestedField: 'courseId',
          }
        }

        if (values?.status?.value !== 'all') {
          formValues.status = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.status?.value,
          }
        }

        if (values.company) {
          const companyIds = values.company.map((i: { value: string | null }) =>
            i.value === '-1' ? (i.value = null) : i.value,
          )
          const query = { companyId: { $in: companyIds } }

          formValues.query = {
            type: LowerCaseFilterableFieldType.QUERY,
            value: JSON.stringify(query),
          }
        }

        const isAllZero = values?.age?.every((item: number) => item === 0)

        if (!isAllZero) {
          formValues.age = {
            type: LowerCaseFilterableFieldType.RANGE,
            value: `${values?.age?.[0]}-${values?.age?.[1]}`,
          }
        }

        if (values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        //if user wants to clear the date, we should send undefined
        const safeCreateDate = createDate || [undefined, undefined]

        safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
        safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

        const isSameDate =
          safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

        if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

        const formattedData = isSameDate
          ? safeCreateDate[0]
          : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
              safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
            }`

        if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : LowerCaseFilterableFieldType.DATE_RANGE,
            value: formattedData as string,
          }
        }

        if (refetchStudents && defaultFilter) {
          refetchStudents({
            role: roles.student,
            filter: { ...defaultFilter, ...formValues },
            currentPage: 1,
            perPage: 10,
          })
          closeDrawer()
        }

        filter(formValues)
        setFilterOptions(values as IFilterQueryType)
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'COACH') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as ICoachesFilterOptions)
    }
  }, [])

  const handleRangeChange = (range: number | number[]): void => {
    setFieldValue('age', range)
  }

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      switch (field) {
        case 'company':
          // Fetch company data with the created filter
          fetchCompany({
            variables: {
              filter: createCompanyFilter(value),
            },
          })
          break
        case 'course':
          // Fetch course data with the created filter
          fetchCourse({
            variables: {
              filter: createCourseFilter(value),
              perPage: 0,
            },
          })
          break
      }
    }, 200)

  // Function to create a filter for companies
  const createCompanyFilter = (value: string): ICoachesCompanyFilter => ({
    name: {
      type: UpperCaseFilterableFieldType.MATCH,
      value,
    },
  })

  // Function to create a filter for courses
  const createCourseFilter = (value: string): ICoachesCourseFilter => {
    let filter: ICoachesCourseFilter = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value },
    }

    if (!useCompanyId) {
      filter = {
        ...filter,
        originalId: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: [null],
        },
      }
    }

    if (useCompanyId || state.selectedCompany?.id) {
      const assignedCoachesFilter = {
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: true,
        },
      }
      const companyCoachesFilter = {
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: false,
        },
        originalId: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: [null],
        },
      }

      const coachFilter = forAssignCoaches ? assignedCoachesFilter : companyCoachesFilter

      filter = {
        ...filter,
        ...coachFilter,
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: manualCompanyId ? manualCompanyId : (state.selectedCompany?.id as string),
        },
      }
    }
    return filter
  }

  const handleSelectChange = (e: string, field: string): void => {
    if (ref.current) {
      clearTimeout(ref.current as NodeJS.Timeout)
    }
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }
  const [companyOptions, setCompanyOptions] = useState([])

  const handleDateChange = (processedDates: [string | null, string | null]): void => {
    setFieldValue('createDate', processedDates)
  }

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return {
    t,
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleRangeChange,
    handleSelectChange,
    companyOptions,
    companyLoading,
    courseData,
    courseLoading,
    isCompanySelected: Boolean(state?.selectedCompany?.id),
    error,
    setError,
    createDate,
    setCreateDate,
    handleDateChange,
  }
}
