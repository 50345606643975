import React, { useState } from 'react'
import { Container, Title, SubText, ContentRight, StyledLink, Content } from './styled-components'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EmailSchema } from 'helpers/validationSchemas'

import AuthLayout from 'components/AuthLayout'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'

import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { Api } from 'api'
import { AxiosError } from 'axios'

interface IForgotPasswordData {
  email: string
}

const ForgotPassword = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const { setSnackbar } = useSnackbarAlert()

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailSchema,
    async onSubmit(values) {
      const data: IForgotPasswordData = { ...values }

      setLoading(true)

      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      Api('post', 'users/forgotPassword', {
        email: data.email,
      })
        .then(() => {
          message = `Successful`
          variant = 'success'
          setLoading(false)
          setSnackbar({ message, variant })
          setSuccess(true)
        })
        .catch((err: AxiosError) => {
          message = err.response?.data.message || 'An error occurred'
          variant = 'error'
          setLoading(false)
          setSnackbar({ message, variant })
        })
    },
  })

  return (
    <AuthLayout>
      <Container>
        {
          !success ? (
            <>
              <SubText>{t('login.password_reset')}</SubText>
              <Title>{t('login.password_reset_description')}</Title>
              <Content>
                <TextInput
                  label={t('form_fields.email')}
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  errorMessage={errors.email}
                  touched={touched.email}
                />
              </Content>
              <Button
                text={t('login.send_link')}
                onClick={handleSubmit}
                background='#06C68F'
                loading={loading}
              />

              <ContentRight>
                <StyledLink>
                  <Link to='/login'>{t('login.back_to_sign_in')}</Link>
                </StyledLink>
              </ContentRight>
            </>
          ) : (
            <>
              <SubText>{t('login.password_reset_link_sent')}</SubText>
              <Title>{t('login.check_email')}:</Title>

              <div
                style={{
                  padding: '15px 0 28px',
                  color: 'rgb(65 65 65 / 80%)',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                {values.email}
              </div>

              <Button
                text={t('login.back_to_sign_in')}
                onClick={(): void => navigate('/login')}
                background='#06C68F'
              />
            </>
          )
          // <div style={{ color: 'green' }}>Please Check Your Email Address </div>
        }
      </Container>
    </AuthLayout>
  )
}

export default ForgotPassword
