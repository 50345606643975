import { styled } from 'styled-components'

export const Subtitles = styled.div<{ $hasLargeTopPadding?: boolean }>`
  display: flex;
  font-weight: 400;
  padding: ${({ $hasLargeTopPadding }): string => ($hasLargeTopPadding ? '22px' : '13px')} 16px 13px
    16px;
  justify-content: space-between;
  font-size: 16px;
  max-width: 600px;

  span:last-child {
    font-size: 16px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    span:last-child {
      margin-right: 20px;
    }
  }

  @media (min-width: 1024px) {
    span:last-child {
      margin-right: 40px;
    }
  }
`

export const InfoWrapper = styled.div`
  padding-left: 32px;
  font-weight: 600;
  font-size: 16px;
`

export const TitleContainer = styled.div`
  font-size: 16px;
  color: #68707a;
  font-weight: 400;
  line-height: 20px;
`

export const Tracker = styled.div`
  padding-left: 20px;
  overflow-y: auto;
  height: 100%;
  max-height: 233px;
`

export const DetailsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding: 16px 16px;
`

export const InformationBackground = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  min-height: 300px;
`

export const ProfileBackground = styled.div`
  width: 30%;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const StyledFlex = styled.div`
  display: flex;
  gap: 30px;
`
