import { GET_TASKS_BY_GROUP, REMOVE_TASKS_FROM_GROUP } from 'gql/tasks.query'
import {
  GetTasksByGroupFiltersByEnums,
  IFetchTasksByCompanyIdProps,
  IRemoveTaskFromGroupResponse,
  ITaskToGroupDto,
} from './getTasksService.interface'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useMutation } from '@apollo/client'
import { ISnackbarProps } from 'interfaces/snackbar'
import { SnackbarVariants } from 'interfaces/common'

export const useRemoveTasksFromGroupService = ({
  groupId,
}: IFetchTasksByCompanyIdProps): IRemoveTaskFromGroupResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [removeTasksFromGroup, { data, error, loading }] = useMutation(REMOVE_TASKS_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_TASKS_BY_GROUP,
        variables: {
          groupId,
          searchValue: '',
          currentPage: 1,
          filterBy: GetTasksByGroupFiltersByEnums.IN_GROUP,
          perPage: 12,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleRemoveTasksFromGroup = async ({
    taskIds,
    groupId,
  }: ITaskToGroupDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await removeTasksFromGroup({
        variables: {
          taskIds,
          groupId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('tasks_layout.task_removed')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    removeTasksFromGroup: (removeTaskDto: ITaskToGroupDto) =>
      handleRemoveTasksFromGroup(removeTaskDto),
    tasks: (data && data.removeTask) || [],
    loading,
    error: errorMessage,
  }
}
