import React, { useEffect, useState } from 'react'
import {
  Container,
  ToolbarItemContainer,
  ContentWrapper,
  LoadMoreWrapper,
} from './styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_NOTIFICATIONS, NOTIFICATION_LISTENER } from 'gql/notifications/notifications.query'
import { userReadNotification, useDeleteNotifications } from 'hooks/notifications/useNotifications'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import NotificationList from './NotificationList'
import { NotificationLayoutProps } from './Notification.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const NotificationLayout = (props: NotificationLayoutProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [skip, setSkip] = useState<number>(0)
  const [count] = useState<number>(10)

  const { data: notificationListener } = useSubscription(NOTIFICATION_LISTENER)
  const [fetchNotificationData, { loading, data: getNotificationsResponse, fetchMore }] =
    useLazyQuery(GET_NOTIFICATIONS)
  const { readNotification } = userReadNotification()
  const { deleteNotifications } = useDeleteNotifications()

  useEffect(() => {
    fetchNotificationData({ variables: { skip: 0, count: 10 } })
  }, [notificationListener])

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const handleLoadMore = (): void => {
    fetchMore({
      variables: {
        skip: skip + count,
        count,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newSkip = fetchMoreResult.getNotifications.data.length + skip
        setSkip(newSkip)
        if (!fetchMoreResult.getNotifications.data) return prev
        return Object.assign({}, prev, {
          getNotifications: {
            ...prev.getNotifications,
            totalCount: fetchMoreResult.getNotifications.totalCount,
            hasMore: fetchMoreResult.getNotifications.hasMore,
            data: [...prev.getNotifications.data, ...fetchMoreResult.getNotifications.data],
          },
        })
      },
    })
  }

  const notificationList =
    (getNotificationsResponse && getNotificationsResponse.getNotifications.data) || []
  const hasMore =
    (getNotificationsResponse && getNotificationsResponse.getNotifications.hasMore) || false
  return (
    <Container>
      <CustomDrawerHeader title={t('notifications.title')} onClose={props.onClose} />
      <ContentWrapper>
        <Toolbar styles={{ justifyContent: 'space-between' }}>
          <ToolbarItem>
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.read'),
                  disabled: !(selectedItem.length > 0),
                  onClick: (): void => {
                    readNotification(selectedItem, 'id', () =>
                      fetchNotificationData({
                        variables: { skip: 0, count: skip + count },
                      }),
                    )
                    setSelectedItem([])
                  },
                  component: (
                    <VisibilityIcon
                      fontSize='small'
                      style={{
                        color: selectedItem.length > 0 ? '#06C68F' : '#ccc',
                      }}
                    />
                  ),
                },
                {
                  id: 1,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  disabled: !(selectedItem.length > 0),
                  onClick: (): void => {
                    deleteNotifications(selectedItem, 'id', () =>
                      fetchNotificationData({
                        variables: { skip: 0, count: skip + count },
                      }),
                    )
                    setSelectedItem([])
                  },
                  component: <DeleteIcon fontSize='small' />,
                },
              ]}
            />
          </ToolbarItem>

          <ToolbarItemContainer>
            <ToolbarItem style={{ marginRight: '15px' }}>
              <Button
                variant='outlined'
                style={{
                  color: '#06C68F',
                  border: '1px solid #06C68F',
                }}
                onClick={(): Promise<void> =>
                  readNotification(selectedItem, 'all', () =>
                    fetchNotificationData({
                      variables: { skip: 0, count: skip + count },
                    }),
                  )
                }
              >
                {t('notifications.mark_all_as_read')}
              </Button>
            </ToolbarItem>

            <ToolbarItem>
              <Button
                variant='outlined'
                color='secondary'
                onClick={(): Promise<void> =>
                  deleteNotifications([], 'all', () =>
                    fetchNotificationData({
                      variables: { skip: 0, count: skip + count },
                    }),
                  )
                }
              >
                {t('notifications.clear_all')}
              </Button>
            </ToolbarItem>
          </ToolbarItemContainer>
        </Toolbar>

        <NotificationList
          notificationList={notificationList}
          selectedItem={selectedItem}
          selectItem={selectItem}
          selectAll={selectAll}
        />
        {notificationList.length === 0 && <div>No Notifications...</div>}
        {hasMore && (
          <LoadMoreWrapper>
            {!loading && (
              <Button
                variant='outlined'
                style={{
                  color: '#06C68F',
                  border: '1px solid #06C68F',
                }}
                onClick={handleLoadMore}
              >
                {t('general.load_more')}
              </Button>
            )}
            {loading && <CircularProgress />}
          </LoadMoreWrapper>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default NotificationLayout
