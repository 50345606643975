import React, { ReactElement } from 'react'
import { NewContainer } from './styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormButtons, FormFooter } from 'components/common/Form'
import { IGroup } from 'interfaces/groups'
import GroupStudents from '../GroupStudents/GroupStudents'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const AddStudentsInNewGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: IGroup
  closeDrawer: () => void
}): ReactElement => {
  const { t } = useTranslation()
  const { company } = groupData
  return (
    <>
      <CustomDrawerHeader title={t('groups_layout.modify_students')} onClose={closeDrawer} />
      <DrawerContent headerHeight={68}>
        <NewContainer>
          <GroupStudents group={groupData} companyId={company?.id} />
        </NewContainer>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.done')}
              color='secondary'
              type='small'
              onClick={closeDrawer}
              background='#06C68F'
            />
          </FormButtons>
        </FormFooter>
      </DrawerContent>
    </>
  )
}

export default AddStudentsInNewGroup
