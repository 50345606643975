import { TFunction } from 'interfaces/TFunction'
import { ILink } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

export interface Actions {
  id: number
  color: string
  hide?: boolean
  disabled: boolean
  onClick: () => void
  component: JSX.Element
}

export interface IQuestionMedia {
  currentImage: string
  name: string
  images: Image[]
  title: string
  links: ILink[]
  thumbnail: string
}

interface Audio {
  name: string
  thumbnail: string
  fileType: string
}

interface Document {
  name: string
  fileType: string
  size: string
  link: string
}

export interface Image {
  name: string
  link: string
  fileType: string
}

export interface Image {
  currentImage: string
  images: string[]
}

export interface Params {
  images: Image[]
  documents: Document[]
  videos: IQuestionMedia[]
  audios: Audio[]
  t: TFunction
}

export interface ITags {
  id: string
  label: string
}

export interface IModule {
  moduleId: string
  name: string
}

export interface IDocument {
  name: string
  fileType: string
  size: string
  link: string
}

export interface IAudio {
  name: string
  fileType: string
  link: string
}

export interface IQuestion {
  id: string
  title: string
  companyId: string
}

export interface IQuestionInfoProps {
  type: string
  tags: ITags[]
  modules: IModule[]
  documents: IDocument[]
  audios: IAudio[]
  currentModule: IModule
  question: IQuestion
  t: TFunction
}

export interface IAnswer {
  isCorrect: boolean
  value: string
}

export enum QuestionBoolAnswer {
  TRUE = 'true',
  FALSE = 'false',
}

export interface IQuestionAnswersProps {
  type: string
  answers?: IAnswer[]
  boolAnswer?: QuestionBoolAnswer
  t?: TFunction<'translation', undefined>
}
