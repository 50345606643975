import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import {
  generateUploadVideoLinksService,
  uploadImageService,
  uploadFileService,
  uploadAudioService,
  exportDataService,
  extractTextService,
  uploadCaptionsService,
  IGenerateUploadVideoLinksMutationResponse,
} from 'services/helperService/helperService'
import { useTranslation } from 'react-i18next'
import { CropperResultType } from 'interfaces/common'
import { FormikErrors } from 'formik'
import { formDataInterface } from 'pages/tests/addTestDrawer/addTestDrawer.interface'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { IAudioFile } from 'hooks/lessons/useAddLessonDrawer/useAddLesson.interface'

export const useGenerateUploadVideoLinks = (): {
  generateUploadVideoLinks: (
    videos: IVideo[],
    modelName: string,
    uploadVideoService: (url: string) => void,
    setVideoLinks: (args: IGenerateUploadVideoLinksMutationResponse) => void,
    isNewVideo?: boolean,
  ) => Promise<void>
} => {
  const { t } = useTranslation()
  const { generateUploadVideoLinksMutation } = generateUploadVideoLinksService()
  const { setSnackbar } = useSnackbarAlert()

  const generateUploadVideoLinks = async (
    videos: IVideo[],
    modelName: string,
    uploadVideoService: (url: string) => void,
    setVideoLinks: (args: IGenerateUploadVideoLinksMutationResponse) => void,
    isNewVideo = false,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await generateUploadVideoLinksMutation({
      variables: {
        videos,
        modelName,
        isNewVideo,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'

      setSnackbar({ message, variant })
    } else if (data) {
      const promises = data.generateVideoUploadLinks.uploadLinks.map((url: string) =>
        uploadVideoService(url),
      )

      await Promise.all(promises)

      setVideoLinks(data.generateVideoUploadLinks)
    }
  }

  return {
    generateUploadVideoLinks,
  }
}

export const useUploadImage = (): {
  uploadImage: (
    image: File | string,
    path: string,
    setImageLink: (link: string) => void,
  ) => Promise<void>
  imageLoading: string
  handleCropSave: (
    croppedFile: CropperResultType,
    collection: string,
    field: string,
    setFieldValue: (
      field: string,
      value: string,
    ) => Promise<void> | Promise<FormikErrors<formDataInterface>> | void,
  ) => void
  handleImageChange: (e: ChangeEvent<HTMLInputElement>) => void
  file: CropperResultType
  cropperOpened: boolean
  setCropperOpened: Dispatch<SetStateAction<boolean>>
} => {
  const { uploadImageMutation } = uploadImageService()
  const [imageLoading, setImageLoading] = useState('')
  const [file, setFile] = useState<CropperResultType>('')
  const [cropperOpened, setCropperOpened] = useState(false)

  const uploadImage = async (
    image: File | string,
    path: string,
    setImageLink: (link: string) => void,
  ): Promise<void> => {
    if (!image) return

    let base64Image: string
    if (typeof image === 'string') {
      base64Image = image
    } else {
      base64Image = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (): void => resolve(reader.result as string)
        reader.onerror = (error): void => reject(error)
      })
    }

    const loadingType = path.split('/')[1]
    setImageLoading(loadingType || 'avatar')
    const { data, errors } = await uploadImageMutation({
      variables: { image: base64Image, path },
    })

    if (errors && errors.length) {
      return
    }
    setImageLoading('')
    setImageLink(data?.uploadImageLink.link as string)
  }

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files, name } = e.target
    if (!files) return
    const reader = new FileReader()

    reader.onload = (): void => {
      const img = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result as string })
    }
    reader.readAsDataURL(files[0])
    setCropperOpened(true)
  }

  const handleCropSave = (
    croppedFile: CropperResultType,
    collection: string,
    field: string,
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
    ) => Promise<void> | Promise<FormikErrors<formDataInterface>> | void,
  ): void => {
    uploadImage(croppedFile as string, `${collection}/${field}`, (link?: string) => {
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      })
    })
  }

  return {
    uploadImage,
    imageLoading,
    handleCropSave,
    handleImageChange,
    file,
    cropperOpened,
    setCropperOpened,
  }
}

export const useUploadFile = (): {
  uploadFile: (
    file: File,
    path: string,
    setFileLink: (link: string, fileId: string) => void,
  ) => Promise<void>
  fileLoading: string
} => {
  const { uploadFileMutation } = uploadFileService()
  const [fileLoading, setFileLoading] = useState('')

  const uploadFile = async (
    file: File,
    path: string,
    setFileLink: (link: string, fileId: string) => void,
  ): Promise<void> => {
    const loadingType = path.split('/')[1]
    setFileLoading(loadingType)
    const { data, errors } = await uploadFileMutation({
      variables: { file, path },
    })

    if (errors && errors.length) {
      return
    }
    setFileLoading('')
    setFileLink(data?.uploadFileLink.link as string, data?.uploadFileLink.fileId as string)
  }

  return {
    uploadFile,
    fileLoading,
  }
}

export const useUploadCaptions = (): {
  uploadCaptions: (
    file: File,
    path: string,
    setFileLink: (link: string, fileId: string) => void,
  ) => Promise<void>
  captionUploadLoading: string
} => {
  const { uploadFileMutation } = uploadCaptionsService()
  const [captionUploadLoading, setCaptionUploadLoading] = useState('')

  const uploadCaptions = async (
    file: File,
    path: string,
    setFileLink: (link: string, fileId: string) => void,
  ): Promise<void> => {
    const loadingType = path.split('/')[1]
    setCaptionUploadLoading(loadingType)
    const { data, errors } = await uploadFileMutation({
      variables: { file, path },
    })

    if (errors && errors.length) {
      return
    }
    setCaptionUploadLoading('')
    setFileLink(data?.uploadCaptions.link as string, data?.uploadCaptions.fileId as string)
  }

  return {
    uploadCaptions,
    captionUploadLoading,
  }
}

export const useUploadAudioService = (): {
  uploadAudio: (
    values: File | IAudioFile,
    uploadApi: (url: string) => void,
    call: (fileId: string, link?: string, authorizedLink?: string) => void,
  ) => Promise<void>
  audioLoading: boolean
} => {
  const { uploadAudioMutation, loading } = uploadAudioService()

  const uploadAudio = async (
    values: File | IAudioFile,
    uploadApi: (url: string) => void,
    call: (fileId: string, link?: string, authorizedLink?: string) => void,
  ): Promise<void> => {
    const { data, errors } = await uploadAudioMutation({
      variables: {
        file: values as File,
      },
    })

    if (errors && errors.length) {
      return
    }

    const promises = [uploadApi(data?.uploadAudio.link as string)]

    await Promise.all(promises)

    call(
      data?.uploadAudio.fileId as string,
      data?.uploadAudio.link,
      data?.uploadAudio.authorizedLink,
    )
  }

  return {
    uploadAudio,
    audioLoading: loading,
  }
}

export const useExportDataService = (): {
  loading: boolean
  exportData: (
    action: string,
    ids: string[],
    companyId: string | null,
    callback: (link: string) => void,
  ) => Promise<void>
} => {
  const { exportDataMutation, loading } = exportDataService()

  const exportData = async (
    action: string,
    ids: string[],
    companyId: string | null,
    callback: (link: string) => void,
  ): Promise<void> => {
    const { data, errors } = await exportDataMutation({
      variables: {
        action,
        ids,
        companyId,
      },
    })

    if (errors && errors.length) {
      return
    }

    callback((data && data.exportCsv.link) || '')
  }

  return {
    loading,
    exportData,
  }
}

export const useExtractTextService = (): {
  loading: string
  extractText: (link: string, callback: (text: string) => void, field?: string) => Promise<void>
} => {
  const { extractTextMutation } = extractTextService()
  const [fileLoading, setFileLoading] = useState('')

  const extractText = async (
    link: string,
    callback: (text: string) => void,
    field = '',
  ): Promise<void> => {
    setFileLoading(field)
    const { data, errors } = await extractTextMutation({
      variables: {
        link,
      },
    })

    if (errors && errors.length) {
      return
    }
    setFileLoading('')
    callback((data && data.extractText.text) || '')
  }

  return {
    loading: fileLoading,
    extractText,
  }
}
export const useFormatTaskTrigger = (): {
  formatTriggerValue: (value: string) => string
} => {
  const formatTriggerValue = (value: string): string => {
    switch (value) {
      case 'BEFORE_MODULE':
      case 'BEFORE_COURSE':
        return 'Before Start'
      case 'AFTER_MODULE':
      case 'AFTER_COURSE':
        return 'After Finish'
      default:
        return value
          .toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    }
  }
  return { formatTriggerValue }
}

export function excludeKeys<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const result = { ...obj }
  for (const key of keys) {
    delete result[key]
  }
  return result
}
