import React from 'react'
import { InfoWrapper, Subtitle, Wrapper } from './styled-components'
import { t } from 'i18next'
import { Tooltip } from '@mui/material'
import strCutter from 'utils/strCutter'
import { IUserInfoRow } from './wrapper.interface'

export const WrapperComponent = ({
  item,
  index,
}: {
  item: IUserInfoRow
  index?: number
}): React.JSX.Element => {
  const text = strCutter(item.info, 30)
  const shouldRenderTooltip = item.info.length > 30

  return (
    <Wrapper key={index}>
      <Subtitle>{t(item.subtitle)}</Subtitle>
      {shouldRenderTooltip ? (
        <Tooltip title={t(item.info)} placement='bottom'>
          <InfoWrapper>{text}</InfoWrapper>
        </Tooltip>
      ) : (
        <InfoWrapper>{text}</InfoWrapper>
      )}
    </Wrapper>
  )
}
