import React, { ReactElement } from 'react'
import {
  ExportButtonContainer,
  GroupExportOptions,
  StyledExportContainer,
  StyledExportContent,
  StyledExportHeader,
} from '../styled-components'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'

import { ReactComponent as Group } from 'components/common/Button/icons/group.svg'
import Checkbox from 'components/common/CheckboxV2/Checkbox'
import { IExportGroupScoresAnalyticsProps } from '../exportAnalytics.interface'
import useGroupScoreExport from 'hooks/analytics/exportData/useGroupScoreExport'
import { ExportGroupScoresOptionEnum } from 'services/analytics/exportData/exportData.interface'

const GroupScoreExport = ({
  companyId,
  setLoading,
}: IExportGroupScoresAnalyticsProps): ReactElement => {
  const {
    t,
    exportLoading,
    handleGroupChange,
    groupOptions,
    groupsLoading,
    selectedGroup,
    handleGroupExport,
    handleOptionChange,
    selectedOptions,
  } = useGroupScoreExport({ companyId, setLoading })
  return (
    <StyledExportContainer>
      <StyledExportHeader>
        <h3>{t('analytics.export_group')} </h3>
        <Group />
      </StyledExportHeader>
      <StyledExportContent>
        <SelectField
          placeholder={t('analytics.select_group')}
          value={selectedGroup}
          options={groupOptions}
          onChange={handleGroupChange}
          isClearable
          isLoading={groupsLoading || exportLoading}
        />
        <GroupExportOptions>
          <Checkbox
            checked={selectedOptions.QUIZ}
            onChange={handleOptionChange}
            name={ExportGroupScoresOptionEnum.QUIZ}
            label={t('general.quizzes')}
          />
          <Checkbox
            checked={selectedOptions.ASSESSMENT}
            onChange={handleOptionChange}
            name={ExportGroupScoresOptionEnum.ASSESSMENT}
            label={t('general.iq_tests')}
          />
          <Checkbox
            checked={selectedOptions.TEST}
            onChange={handleOptionChange}
            name={ExportGroupScoresOptionEnum.TEST}
            label={t('general.tests')}
          />
        </GroupExportOptions>
      </StyledExportContent>
      <ExportButtonContainer>
        <Button
          text={t('actions.export')}
          color='secondary'
          onClick={handleGroupExport}
          isDisabled={
            !selectedGroup || exportLoading || !Object.values(selectedOptions).some(Boolean)
          }
          background='#06C68F'
        />
      </ExportButtonContainer>
    </StyledExportContainer>
  )
}

export default GroupScoreExport
