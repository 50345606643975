import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import { Collapse, TableCell } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { CSSProperties } from 'react'

export const Wrapper = styled.div`
  display: flex;
`

export const SelectedItemsWrapper = styled.div`
  transition: opacity 0.2s ease;
  margin: 0 10px 0 20px;
  &.disabled {
    opacity: 0.5;
  }
`

export const VisuallyHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  top: 20px;
`

export const StyledTableCell = styled(TableCell)<{ $clickable: boolean }>`
  cursor: ${({ $clickable }): string => ($clickable ? 'pointer' : 'default')};
`

export const MultipleAction = styled.div`
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &:hover > div {
    opacity: 1;
    transform: translate(-50%) scale(1);
  }
`

export const MultipleActionTooltip = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

export const NoValueWrapper = styled.div`
  opacity: 0.5;
  font-size: 0.75rem;
`

export const GridContainer = styled.div`
  margin: 0 30px;

  table,
  th,
  td {
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }
  .table_row_background {
    background: #fcfcfc;
  }
  min-width: 1300px;
  /* width: -webkit-fill-available; */
`

export const GridToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`

export const GridLayoutActions = styled.div`
  display: flex;
  align-items: center;
`

export const GridLayoutForm = styled.div`
  display: flex;
  align-items: center;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`

export const StyledPaper = styled(Paper)`
  box-shadow: none !important;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 14px 14px 0 0;
`

export const Root = styled.div<{ disabled?: boolean }>`
  width: 100%;
  visibility: ${({ disabled }): string => (disabled ? 'hidden' : 'visible')};
`
export const NoRecordsContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
`

export const StyledTable = styled(Table)`
  width: 100%;
  min-width: 750px;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 5px;

  th,
  td {
    border: 1px solid #e7e9ed;
    padding: 10px;
    cursor: pointer;
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`

export const TableHeader = styled.thead`
  background: #fcfcfc;
`

export const TableRow = styled.tr`
  .table_header {
    height: 50px;
  }
  .table_column_checkbox {
    width: 80px;
    .table_row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  transition: background 0.3s ease;

  &:nth-child(even) {
    background: rgba(238, 238, 238, 1);
  }
`
export const TableRowHeader = styled.th`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  background: #2f2834;
`

export const TableBody = styled.tbody`
  .table_row_tr {
    padding: 10px 0;
  }
`

export const TableColumn = styled.td`
  padding: 10px 0 !important;
  .table_row_container {
    width: 100%;
    height: 100%;
    padding: 0px 10px 0 0;
  }
  .column_column_item {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4e4e4e;
  }
`

export const GridHeader = styled.thead`
  display: flex;
  width: 100%;
  background: #fcfcfc;
`

export const ColumnContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
`

export const ActionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
`
export const ActionContainerItem = styled.div`
  box-sizing: border-box;
  padding: 0px 4px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`

export const ActionIcon = styled.img`
  cursor: pointer;
`

export const GridImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 2px;
  border: solid 1px rgba(170, 170, 177, 0.57);
  background-color: #ffffff;
  img {
    width: 60px;
    height: 60px;
  }
`

// Grid

export const GridTableHeader = styled.div`
  display: flex;
  padding: 0 10px 0 0;
  align-items: center;
  justify-content: space-between;
`

export const GridTableTitle = styled.h2`
  padding: 10px;
`

export const GridTableWrapper = styled.div`
  width: 100%;

  .row:last-child {
    .group-item-title {
      top: -35px;
      bottom: unset;
    }
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: rgb(38 245 0 / 8%) !important;
  }
  .MuiTableRow-root.Mui-selected-error,
  .MuiTableRow-root.Mui-selected-error:hover {
    background-color: rgba(255, 0, 0, 0.13) !important;
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LessonActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-right: 15px;
`

export const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`
export const StudentsActionsContainer = styled(ActionsContainer)`
  gap: 0;
`

export const rowStyles: CSSProperties = { height: '50px', position: 'relative' }

export const StyledTd = styled.td<{ collapsed: boolean }>`
  background: #f9f9f9;
  transition: 0.5s ease;
  ${({ collapsed }): string =>
    collapsed
      ? `
  opacity: 1;
  padding: 10px !important;
  `
      : `
  opacity: 0;
  padding: 0 10px !important;
  `}
`

export const StyledCollapse = styled(Collapse)<{ noMargin?: boolean }>`
  margin: ${({ noMargin }): string => (noMargin ? '0' : '15px')};
`
