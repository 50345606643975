/**
 * Calculates the new current page number based on the total number of items,
 * items per page, and the current page number.
 *
 * @param totalItems - The total number of items available.
 * @param perPage - The number of items displayed per page.
 * @param currentPage - The current page number.
 * @returns The new current page number, ensuring it does not exceed the total number of pages.
 */
export const calculateNewCurrentPage = (
  totalItems: number,
  perPage: number,
  currentPage: number,
): number => {
  // Calculate the total number of pages by dividing total items by items per page
  const newTotalPages = Math.ceil(totalItems / perPage)

  // Return the current page if it's within the range, otherwise return the last page
  return currentPage > newTotalPages ? newTotalPages : currentPage
}
