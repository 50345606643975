import { useQuery } from '@apollo/client'
import { useUserValue } from 'context/UserContext'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import useEditLessonService from 'hooks/lessons/useEditLessonService'
import { useSwal } from 'hooks/useSwal'
import { actionConfig, downloadActionConfig } from 'pages/lessons/gridConfig'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ILessonDataUpdate,
  ILessonDetailBreadCrumbItem,
  IUseLessonDetailsReturnType,
} from '../lessonDetails.interface'
import { ILesson } from 'interfaces/modules'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { DrawerEventEmitter } from 'helpers/drawer'
import React from 'react'

const useLessonDetails = (): IUseLessonDetailsReturnType => {
  const { t } = useTranslation()
  const { id: lessonId } = useParams()
  const [state] = useUserValue()
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [mediaModal, setMediaModal] = useState(false)
  const [descriptionEdit, setDescriptionEdit] = useState(false)
  const [transcriptEdit, setTranscriptEdit] = useState(false)

  const { data } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId,
    },
  })

  const { state: routerState } = useLocation()

  const lessonData = (data && data.getLessonById) || {}

  const { editLesson } = useEditLessonService({
    lessonId,
  })
  const { deleteLesson } = useDeleteLessonService({
    moduleId: lessonData.moduleId,
  })

  const editEditorText = (editorText: string, event: keyof ILessonDataUpdate): void => {
    const newData = {} as ILesson
    newData[event] = editorText
    if (lessonId) editLesson(lessonData.topicId, lessonId, newData)

    if (descriptionEdit && event === 'description') setDescriptionEdit(false)
    if (transcriptEdit && event === 'transcript') setTranscriptEdit(false)
  }

  const video = (lessonData.video && lessonData.video) || ''
  const audio = (lessonData.audio && lessonData.audio.link) || ''
  const document = (lessonData.contentDocument && lessonData.contentDocument.link) || ''

  const handleLessonDelete = (ids: string[]): void => {
    const title: string =
      ids.length === 1 ? t('popups.delete_lesson_single') : t('popups.delete_lesson_many')
    const params = {
      title,
      onConfirm: () => deleteLesson(ids, () => navigate(-1)),
      confirmText: t('actions.confirm_delete'),
    }

    fireSwal(params)
  }

  const editLessonDrawer = (lessonId: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
      id: null,
      data: {
        id: lessonId,
        fromTopic: true,
      },
    })
  }

  const action = actionConfig(editLessonDrawer, handleLessonDelete, t, state.userPermission, '')
  const downloadActions = downloadActionConfig(() => console.log(''), t)

  let breadCrumbData: ILessonDetailBreadCrumbItem[] = []

  if (data && lessonData) {
    breadCrumbData = [
      {
        label:
          (routerState?.from && `${t('general.course')} ${t('general.modules')}`) ||
          t('general.modules'),
        link: routerState?.from || '/modules',
        state: routerState?.state || {},
        icon: <Learning />,
      },
      {
        label: lessonData.moduleName,
        link: `/modules/${lessonData.moduleId}`,
        state: routerState,
      },
      { label: lessonData.name, link: '' },
    ]
  }

  const canModify = lessonData.companyId === (state.selectedCompany?.id || null)

  // find the link that has converted field true
  const hasConverted = video && video.links.find((link: { converted: boolean }) => link.converted)

  return {
    breadCrumbData,
    lessonData,
    descriptionEdit,
    setDescriptionEdit,
    t,
    canModify,
    editEditorText,
    transcriptEdit,
    setTranscriptEdit,
    video,
    audio,
    document,
    hasConverted,
    mediaModal,
    setMediaModal,
    downloadActions,
    action,
    state,
  }
}

export default useLessonDetails
