import React from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import _ from 'lodash'
import { PER_PAGE as perPage } from 'hooks/modules/useModules'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import {
  ModalRef,
  ToolbarItemWrapper,
  ContentWrapper,
  styles,
  GridTableWrapper,
  StyledPagination,
} from './styled-components'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import { ReactComponent as FilterAltOffIcon } from 'assets/remove-filter.svg'
import { ReactComponent as HorizontalSVG } from 'assets/horizontal-view.svg'
import { ReactComponent as GridSVG } from 'assets/grid-view.svg'
import Modal from '@mui/material/Modal'
import Player from 'components/common/VideoPlayer/Player'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { Grid } from 'components/common/Cards/styled-components'
import ModulesList from './ModulesList'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import useModulesLayout from 'hooks/modules/useModuleLayout'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import MyLoader from 'loading/loading'
import Tooltip from '@mui/material/Tooltip'
import GridV2 from 'components/common/GridV2/Grid'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

const ModulesLayout = (): React.JSX.Element => {
  const {
    state,
    refetch,
    filterQuery,
    setSelectedItem,
    modules,
    companyId,
    searchText,
    t,
    setSelectAll,
    filterData,
    resetFilter,
    filterDrawer,
    onSearchSubmit,
    handleSearchChange,
    selectItem,
    handleVideoModal,
    actions,
    handlePaginationClick,
    videoModal,
    setVideoModal,
    filterIsOpen,
    selectedItem,
    currentPage,
    setFilterData,
    setFilterQuery,
    isSearchType,
    isFiltered,
    loading,
    isCardView,
    setIsCardView,
    modulesListConfiguration,
    redirectToModule,
    handleChangeRowsPerPage,
  } = useModulesLayout()

  const breadCrumbData = [
    {
      label: 'Modules',
      icon: <Learning />,
    },
  ]

  const createButton = (): React.JSX.Element => {
    if (!state.userPermission.createModule) {
      return <></>
    }

    return (
      <Button
        text={t('modules_layout.create_module')}
        color='secondary'
        onClick={(): void =>
          DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
            data: {
              courseId: '',
              name: '',
              onSuccess: () => {
                refetch({
                  filter: {
                    companyId: {
                      type: LowerCaseFilterableFieldType.EXACT,
                      value: state.selectedCompany?.id,
                    },
                    query: {
                      type: 'query',
                      value: JSON.stringify(filterQuery),
                    },
                  },
                  currentPage: 1,
                  perPage,
                })
                setSelectedItem({})
                setSelectAll({})
              },
            },
          })
        }
        icon={<AddCircleRoundedIcon />}
        background='#06C68F'
      />
    )
  }
  if (
    !loading &&
    modules &&
    companyId &&
    !searchText &&
    modules?.data.length === 0 &&
    !isSearchType &&
    _.isEmpty(filterData)
  ) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('modules_layout.no_modules')}
          subTitle={t('modules_layout.create_modules')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <div>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>

      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createModule && (
              <Button
                text={t('modules_layout.create_module')}
                color='secondary'
                onClick={(): void =>
                  DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
                    data: {
                      courseId: '',
                      name: '',
                      onSuccess: () => {
                        refetch({
                          filter: {
                            companyId: {
                              type: 'exact',
                              value: state.selectedCompany?.id,
                            },
                            query: {
                              type: 'query',
                              value: JSON.stringify(filterQuery),
                            },
                          },
                          currentPage: 1,
                          perPage,
                        })
                        setSelectedItem({})
                        setSelectAll({})
                      },
                    },
                  })
                }
                icon={<AddCircleRoundedIcon />}
                background='#06C68F'
              />
            )}
          </div>

          <div className='wrapper'>
            <ToolbarItem>
              {isCardView ? (
                <IconButton onClick={(): void => setIsCardView(false)}>
                  <GridSVG />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsCardView(true)}>
                  <HorizontalSVG />
                </IconButton>
              )}
            </ToolbarItem>
            <ToolbarItem $noGap>
              <IconButton onClick={filterDrawer}>
                <Tooltip title={t('actions.filter')} arrow>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton onClick={resetFilter}>
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <FilterAltOffIcon />
                  </Tooltip>
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  $iconInStart
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <ContentWrapper>
        {loading ? (
          <MyLoader />
        ) : (
          modules && (
            <>
              {modules.data.length === 0 && (searchText !== '' || isFiltered) ? (
                <SplashScreen
                  title={t('general.no_results_found')}
                  subTitle={t('general.try_adjusting_your_search_or_filter')}
                  textMarginTop='40px'
                  isSearchOrFilter={true}
                />
              ) : (
                <>
                  {!isCardView ? (
                    <GridTableWrapper style={{ width: '100%' }}>
                      <GridV2
                        config={modulesListConfiguration}
                        data={modules.data}
                        actionConfig={actions}
                        actionTilesLength={1}
                        redirectToDetailsHandler={(module): void => redirectToModule(module.id)}
                      />
                    </GridTableWrapper>
                  ) : (
                    <Grid className={'card'} cols={4}>
                      <ModulesList
                        data={modules.data}
                        gridStyle={'card'}
                        selectedItem={selectedItem[currentPage] || []}
                        selectItem={selectItem}
                        handleVideoModal={handleVideoModal}
                        actions={actions}
                      />
                    </Grid>
                  )}
                  <StyledPagination>
                    {!isCardView && (
                      <PerPageDropdown
                        value={perPage}
                        onChange={(value): void =>
                          handleChangeRowsPerPage({
                            target: {
                              value: value.toString(),
                            } as HTMLInputElement,
                          } as React.ChangeEvent<HTMLInputElement>)
                        }
                        options={[10, 20, 50]}
                      />
                    )}
                    <PaginationV2
                      currentPage={modules ? modules.currentPage : 1}
                      totalPages={modules ? modules.totalPages : 0}
                      handleChange={handlePaginationClick}
                    />
                  </StyledPagination>
                </>
              )}
            </>
          )
        )}
      </ContentWrapper>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.modal}
        open={videoModal.modal}
        onClose={(): void => setVideoModal({ data: '', modal: false, subtitle: '' })}
        closeAfterTransition
      >
        <ModalRef>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </ModalRef>
      </Modal>

      <Drawer opened={filterIsOpen} totalWidth='500px'>
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          resetFilter={resetFilter}
          onClose={filterDrawer}
          companyId={state.selectedCompany?.id}
          t={t}
        />
      </Drawer>
    </div>
  )
}

export default ModulesLayout
