/**
 * Utility function to check if an item is selected.
 *
 * @param id - The ID of the item to check.
 * @param selectedItems - The list of currently selected item IDs.
 * @returns True if the item is selected, false otherwise.
 */
export const isItemSelected = (id: string, selectedItems: string[]): boolean => {
  return selectedItems.includes(id)
}
