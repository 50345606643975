import React, { ReactElement } from 'react'
import { t } from 'i18next'
import IconButton from '@mui/material/IconButton'
import Grid from 'components/common/GridV2'
import {
  Header,
  LayoutWrapper,
  Wrapper,
  SelectFieldContainer,
  LoaderContainer,
} from './styled-components'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { SelectField } from 'components/common/SelectField'
import { Input } from 'components/common/TextInput'
import useStudentsLayout from './useStudentsLayout'
import { IStudentsLayout } from './students.interface'
import TablePagination from 'components/common/Pagination/TablePagination'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'

const StudentsLayout = ({ testAssessmentId }: IStudentsLayout): ReactElement => {
  const {
    onSearchSubmit,
    setSelectedGroup,
    selectedGroup,
    searchText,
    groupOptions,
    handleClick,
    users,
    loading,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    config,
    isSearchType,
    handleSearchChange,
  } = useStudentsLayout({
    testAssessmentId,
  })

  return (
    <>
      {loading && !searchText ? (
        <LoaderContainer>
          <MyLoader />
        </LoaderContainer>
      ) : !users?.data.length && !isSearchType && !searchText ? (
        <SplashScreen
          marginTop={20}
          subTitle={t('tests_layout.no_students')}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <LayoutWrapper>
          <Header>
            <Wrapper>
              <SelectFieldContainer>
                <SelectField
                  placeholder={'Select Group'}
                  options={groupOptions}
                  onChange={(e): void => setSelectedGroup(e)}
                  value={selectedGroup}
                  isClearable
                />
              </SelectFieldContainer>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  $iconInStart
                />
              </form>
            </Wrapper>
          </Header>
          {users && users.data.length ? (
            <div>
              <Grid config={config} data={users.data} redirectToDetailsHandler={handleClick} />
              <TablePagination
                currentPage={currentPage}
                rowsPerPage={perPage}
                count={users.totalCount}
                handleChangePage={handlePaginationClick}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <SplashScreen
              title={t('general.no_results_found')}
              subTitle={t('general.try_adjusting_your_search_or_filter')}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          )}
        </LayoutWrapper>
      )}
    </>
  )
}

export default StudentsLayout
