import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Header = styled.header``

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`
