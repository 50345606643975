import React, { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'
import Modal from '@mui/material/Modal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { DefaultText } from 'components/common/TextEditor/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import { Wrapper } from 'pages/companies/CompanyDetails/styled-components'
import CourseList from 'pages/courses/CourseList'
import { PersonalInformationHeader } from '../AdminProfile/styled-components'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import useUserCourses from './hooks/useUserCourses'
import { Root, styles } from './styled-components'

export const UserCourses: React.FC<{
  userId: string
  groups: string[]
  companyId?: string | null
}> = ({ userId, groups, companyId }) => {
  const {
    setCoursesVisible,
    t,
    isCoursesVisible,
    filterData,
    searchText,
    resetFilter,
    setFilterData,
    onSearchChange,
    filterDrawer,
    filterIsOpen,
    setFilterIsOpen,
    setCurrentPage,
    handlePaginationClick,
    videoModal,
    setVideoModal,
    onSearchSubmit,
    courses,
    refetch,
  } = useUserCourses({
    groups,
    userId,
  })
  return (
    <Root>
      <PersonalInformationHeader onClick={(): void => setCoursesVisible(x => !x)}>
        {!isCoursesVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {t('general.courses')}
      </PersonalInformationHeader>

      {isCoursesVisible && (
        <>
          <Wrapper style={{ justifyContent: 'flex-end' }}>
            <ToolbarItem>
              <IconButton
                disabled={_.isEmpty(filterData.filterOptions) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize='small' />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterListIcon fontSize='medium' style={{ color: '#06C68F' }} />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={onSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </Wrapper>
          <Grid className='card' style={{ position: 'relative', marginTop: ' 17px' }} cols={4}>
            {courses?.data && courses.data.length !== 0 ? (
              <CourseList
                data={courses.data}
                gridStyle='card'
                withTooltips={false}
                isGridView={true}
                user={{
                  id: userId,
                  groups,
                }}
                $canOpen
                withoutChekbox
              />
            ) : (
              <DefaultText style={{ width: '50vw' }}>{t('default_texts.courses')}</DefaultText>
            )}
          </Grid>
        </>
      )}

      <Drawer opened={filterIsOpen} toggleDrawer={(): void => undefined} totalWidth='700px'>
        <FilterDrawer
          filterData={filterData as IGroupsFilterDrawer}
          setFilterData={setFilterData as Dispatch<SetStateAction<IGroupsFilterDrawer>>}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={(): void => setFilterIsOpen(false)}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          companyId={companyId as string}
          isOnStudentProfile={true}
          t={t}
        />
      </Drawer>

      {courses && courses.totalPages > 1 && (
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )}

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={videoModal.modal}
        onClose={(): void => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        style={styles.modal}
      >
        <div>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </div>
      </Modal>
    </Root>
  )
}
