import React, { useState, useEffect, ChangeEvent } from 'react'
import { ModuleWrapper } from './styled-components'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useEditQuestion } from 'hooks/questions/useEditQuestion'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { SET_FORMDATA } from 'store/types'
import HelpIcon from '@mui/icons-material/Help'
import { QuestionSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'
import { GET_QUESTION } from 'gql/questions.query'
import {
  // useGenerateUploadVideoLinks,
  useUploadImage,
} from 'hooks/helpers/useHelpersService'
import Tooltip from '@mui/material/Tooltip'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { ModuleItemWrapper, ModuleItem } from 'pages/questions/styled-components'
import { Wrapper, Modules } from '../styled-components'
import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import QuestionItem from '../CreateQuestionDrawer/QuestionItem'
import AddModuleToQuestionDrawer from '../CreateQuestionDrawer/AddModulesToQuestionDrawer/AddModuleToQuestionDrawer'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import UploadContent from '../CreateQuestionDrawer/UploadContent'
import { useUserValue } from 'context/UserContext'
import { Button } from 'components/common/Button'
import { QuestionTypes } from '../staticData'
import { QuestionTypeEnums } from '../CreateQuestionDrawer/CreateQuestion.interface'
import {
  HelpButtonContainer,
  // FormLabel,
  StyledFormField,
} from '../CreateQuestionDrawer/styled-components'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Radio } from '@mui/material'
import { GET_TAGS } from 'gql/skilss.query'
import { CropperResultType, IAttachment, ITagsInterface } from 'interfaces/common'
import MyLoader from 'loading/loading'
import { IModule } from 'pages/modules/ModulesList.interface'
import removeKeys from 'utils/removeKeys'
import { ILink } from 'interfaces/modules'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { IQuestion } from 'interfaces/questions'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'
import {
  IAnswer,
  IEditQuestionDrawerParams,
  IEditQuestionFormData,
} from './editQuestionDrawer.interface'

const EditQuestionDrawer = ({
  title,
  currentModule,
  questionId,
}: IEditQuestionDrawerParams): React.JSX.Element => {
  const { t } = useTranslation()
  const [userState] = useUserValue()

  const { data, loading } = useQuery(GET_QUESTION, {
    variables: {
      questionId,
      type: currentModule.moduleId ? 'module' : '',
    },
  })

  const { data: tags } = useQuery(GET_TAGS, {
    variables: {
      companyId: currentModule.manualCompanyId || userState.selectedCompany?.id,
    },
  })

  const { editQuestion, editQuestionLoading } = useEditQuestion()
  const { createQuestion, createQuestionLoading } = useCreateQuestion()

  const tagList =
    (tags &&
      tags.getAllTags.map(({ label }: ITagsInterface) => ({
        label,
        value: label,
      }))) ||
    []

  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()

  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] = useState<boolean>(false)
  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<string[] | IAnswer[]>([])
  const [multiAnswers, setMultiAnswers] = useState<string[] | IAnswer[]>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<string | null>(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<string[]>([])
  const [selectedModules, setSelectedModules] = useState<IModule[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<IAttachment[]>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<string>('')
  const { uploadImage } = useUploadImage()
  const [currentQuestion, setCurrentQuestion] = useState<IEditQuestionFormData>(
    {} as IEditQuestionFormData,
  )

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        question: '',
        type: {
          label: 'True/False',
          value: QuestionTypeEnums.BOOLEAN,
        },
        tags: [] as ITagsInterface[],
        reason: '',
        isShuffleEnabled: false,
        boolAnswer: null as boolean | null,
        answers: [] as IAnswer[],
        modules: [] as IModule[],
        videos: [] as IAttachment[],
      } as IEditQuestionFormData,
      validationSchema: QuestionSchema,
      onSubmit(values) {
        let formValues = removeKeys(values, ['choiceAnswers', 'multiAnswers'])
        // if (selectedModules.length > 0) {
        //   formValues.modules = selectedModules
        // }

        if (values.type.value === 'boolean') {
          formValues.boolAnswer = boolAnswer
        }

        if (values.type.value === 'single') {
          formValues.answers = choiceAnswers.map((answer, index: number) => {
            return {
              isCorrect: selectedChoiceAnswer == index.toString(),
              value: (answer as IAnswer).value,
            }
          })
        }

        if (values.type.value === 'multiple') {
          formValues.answers = multiAnswers.map((answer, index: number) => {
            return {
              isCorrect: !!selectedMultiAnswers.includes(index.toString()),
              value: (answer as IAnswer).value,
            }
          })
        }

        formValues.tags = values.tags.map(({ label }: ITagsInterface) => ({
          label,
        }))

        const obj: {
          documents: IAttachment[]
          videos: IAttachment[]
          audios: IAttachment[]
          images: IAttachment[]
        } = { documents: [], videos: [], audios: [], images: [] }
        if (uploadedFiles.length > 0) {
          uploadedFiles.map(item => {
            const { type, ...args } = item

            if (type === 'videos') {
              obj[type as keyof typeof obj] = [
                ...obj[type as keyof typeof obj],
                {
                  ...args,
                  links: args.links?.map((link: ILink) =>
                    removeTypeNameFromObject(link),
                  ) as ILink[],
                },
              ]
            } else {
              obj[type as keyof typeof obj] = [...obj[type as keyof typeof obj], args]
            }
          })

          formValues = {
            ...formValues,
            ...obj,
          }
        } else {
          formValues = {
            ...formValues,
            ...obj,
          }
        }

        const moduleId: string = currentModule ? (currentModule.moduleId as string) : ''
        const duplicate: boolean = currentModule.duplicate ? true : false

        formValues.videos = formValues.videos
          ? (formValues.videos.map(video => ({
              id: video.id,
              title: video.title as string,
            })) as IAttachment[])
          : []
        if (duplicate) {
          if (currentModule.moduleId) {
            formValues.modules = [
              {
                moduleId: currentModule.moduleId,
                name: currentModule.name,
              } as IModule,
            ]
          } else {
            // formValues.modules = []
          }
          createQuestion(
            formValues as IQuestion,
            (currentModule.manualCompanyId as string) || (userState.selectedCompany?.id as string),
            () => {
              closeDrawer()
              currentModule.refetch()
            },
          )
        } else {
          editQuestion(moduleId, questionId, formValues as IQuestion, closeDrawer)
        }
        toggleDrawerConfirm(false, '')
      },
    })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const handleSelectChange = (field: string, e: ITagsInterface): void => {
    setFieldValue(field, e)
  }

  const checkAnswers = (type: QuestionTypeEnums): boolean => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        const emptyChoices = choiceAnswers.filter(answer => (answer as IAnswer).value === '')
        return !!(choiceAnswers.length && !emptyChoices.length)
      case QuestionTypeEnums.MULTIPLE:
        const emptyValues = multiAnswers.filter(answer => (answer as IAnswer).value === '')

        return !!(multiAnswers.length && !emptyValues.length)
    }
  }

  const checkSelectedAnswers = (type: QuestionTypeEnums): boolean => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        return selectedChoiceAnswer !== null
      case QuestionTypeEnums.MULTIPLE:
        return selectedMultiAnswers.length > 1
    }
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: string): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers, { value: '' }]
        setChoiceAnswers(updatedChoiceAnswers as IAnswer[])
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers, { value: '' }]
        setMultiAnswers(updatedMultiAnswers as IAnswer[])
    }
  }

  const handleRemoveAnswerClick = (type: string, index: number): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers]
        updatedChoiceAnswers.splice(index, 1)
        setChoiceAnswers(updatedChoiceAnswers as IAnswer[])

        if (index.toString() == selectedChoiceAnswer) {
          setSelectedChoiceAnswer(null)
        }
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setMultiAnswers(updatedMultiAnswers as IAnswer[])

        if (selectedMultiAnswers.includes(index.toString())) {
          const ind = selectedMultiAnswers.indexOf(index.toString())
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(ind, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        }
    }
  }

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    type: string,
    index: number,
  ): void => {
    const { value } = e.target

    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers] as IAnswer[]
        updatedChoiceAnswers[index].value = value
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers] as IAnswer[]
        updatedMultiAnswers[index].value = value
        setMultiAnswers(updatedMultiAnswers)
        return
    }
  }

  const handleSelect = (e: ChangeEvent<HTMLInputElement> | number, type: string): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const { value } = (e as ChangeEvent<HTMLInputElement>).target
        setSelectedChoiceAnswer(value)
        return
      case QuestionTypeEnums.MULTIPLE:
        const eventValue = e
        const index = selectedMultiAnswers.indexOf(eventValue.toString())
        if (index > -1) {
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(index, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        } else {
          setSelectedMultiAnswers([...selectedMultiAnswers, eventValue.toString()])
        }
    }
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: CropperResultType): void => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ] as IAttachment[])
    uploadImage(croppedFile as string, (field = 'questions/images'), (link: string) => {
      const updatedFiles = [...uploadedFiles]
      updatedFiles[index] = {
        type: 'image',
        link,
        uploading: false,
      }
      setUploadedFiles(updatedFiles)
    })
  }

  useEffect(() => {
    if (!data || !data.question) return
    const { question, type, tags, reason, boolAnswer, answers, modules, isShuffleEnabled } =
      data.question

    const formData = {
      question,
      type: { label: type.label, value: type.value },
      tags:
        tags.map(({ label }: ITagsInterface) => ({
          label,
          value: label,
        })) || [],
      reason,
      isShuffleEnabled: isShuffleEnabled || false,
      boolAnswer: null,
      answers: [],
      modules: [],
      videos: [],
      choiceAnswers: [],
      multiAnswers: [],
    } as IEditQuestionFormData
    setValues(formData)

    if (type.value === 'boolean') {
      setBoolAnswer(boolAnswer)
      formData.boolAnswer = boolAnswer
    }

    if (type.value === 'single') {
      const choiceAnswers = answers.map((answer: IAnswer) => ({
        value: answer.value.toString(),
      }))
      const correct = answers.findIndex((answer: IAnswer) => answer.isCorrect)
      setChoiceAnswers(choiceAnswers)
      setSelectedChoiceAnswer(correct.toString())
      formData.choiceAnswers = choiceAnswers
    }

    if (type.value === 'multiple') {
      const multiAnswers = answers.map((answer: IAnswer) => ({
        value: answer.value,
      }))

      const correctAnswers = answers.reduce((ids: string[], answer: IAnswer, index: number) => {
        if (answer.isCorrect) {
          ids.push(index.toString())
        }

        return ids
      }, [])

      setMultiAnswers(multiAnswers)
      setSelectedMultiAnswers(correctAnswers)
      formData.multiAnswers = multiAnswers
    }

    setSelectedModules(
      modules.map((moduleItem: IModule) => ({
        moduleId: moduleItem.moduleId,
        name: moduleItem.name,
      })),
    )

    setCurrentQuestion(formData)

    let files: IAttachment[] = []

    for (const key in data.question) {
      if (
        ['videos', 'audios', 'images', 'documents'].includes(key) &&
        data.question[key].length > 0
      ) {
        files = [
          ...files,
          ...data.question[key].map((question: IQuestion) => {
            const questionRaw = removeTypeNameFromObject(question)
            return {
              ...questionRaw,
              type: key,
            }
          }),
        ]
      }
    }
    setUploadedFiles(files)
  }, [data])

  const handleCloseDrawer = (): void => {
    const formData = removeKeys(values, ['type'])
    const currentQuestionData = removeKeys(currentQuestion, ['type'])

    if (multiAnswers.length > 0) {
      formData.multiAnswers = multiAnswers as IAnswer[]
    }
    if (choiceAnswers.length > 0) {
      formData.choiceAnswers = choiceAnswers as IAnswer[]
    }
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuestion',
        values: formData,
        compareTo: currentQuestionData,
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  const submitBtnDisabled = !values.question || !fieldsValid || !selectedValid

  if (loading) return <MyLoader />
  if (!data) return <div>No data available</div>
  console.log('values', values)
  console.log('state', state.formData)

  return (
    <>
      {(loading || editQuestionLoading || createQuestionLoading) && (
        <Loader withBackground={loading ? true : false} />
      )}
      <Drawer
        opened={linkModuleDrawerOpened}
        toggleDrawer={(): void => setLinkModuleDrawerOpened(false)}
        totalWidth='650px'
      >
        <AddModuleToQuestionDrawer
          title='Link Modules to the Question'
          closeDrawer={(): void => setLinkModuleDrawerOpened(false)}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      </Drawer>
      <CustomDrawerHeader title={title} onClose={handleCloseDrawer} />
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <Title $required>{t('form_fields.select_question_type')}</Title>
                <ReactSelect
                  onChange={(e): void =>
                    handleSelectChange('type', e as { label: string; value: QuestionTypeEnums })
                  }
                  options={QuestionTypes}
                  value={values.type}
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              <FormField>
                <Title>{t('form_fields.select_tags')} </Title>
                <CreatableSelect
                  isMulti
                  onChange={(e): void => {
                    handleSelectChange('tags', e as ITagsInterface)
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('form_fields.select_tags')}
                />
              </FormField>
              <FormField style={{ display: 'flex' }}>
                <UploadContent setUploadedFiles={setUploadedFiles} uploadedFiles={uploadedFiles} />

                <HelpButtonContainer>
                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>{t('actions.upload_content_helper')}</span>
                    }
                    arrow
                  >
                    <HelpIcon style={{ color: '#06C68F' }} />
                  </Tooltip>
                </HelpButtonContainer>
              </FormField>
              <FormField>
                <TextInput
                  title={t('general.question')}
                  required
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  placeholder={t('form_fields.type_question_here')}
                  size='small'
                  name='question'
                  type='text'
                  value={values.question}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows='2'
                />
              </FormField>
              <FormField>
                <QuestionItem
                  type={values.type.value}
                  boolAnswer={boolAnswer}
                  choiceAnswers={values.choiceAnswers}
                  multiAnswers={values.multiAnswers}
                  selectedChoiceAnswer={selectedChoiceAnswer as string}
                  selectedMultiAnswers={selectedMultiAnswers}
                  handleSelect={handleSelect}
                  onBoolChange={handleBoolChange}
                  handleAddAnswerClick={handleAddAnswerClick}
                  handleRemoveAnswerClick={handleRemoveAnswerClick}
                  handleAnswerChange={handleAnswerChange}
                  fieldsValid={fieldsValid}
                  selectedValid={selectedValid}
                />
              </FormField>
              <FormField>
                <Title>{t('question_details.reason')}</Title>
                <TextInput
                  placeholder={`${t('form_fields.type_reason_here')}`}
                  size='small'
                  name='reason'
                  type='text'
                  value={values.reason}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows='3'
                />
              </FormField>
              <StyledFormField>
                <Title>{t('create_question.shuffle_answers')}:</Title>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  defaultValue='top'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('isShuffleEnabled', e.target.value === 'true')
                  }}
                  value={values.isShuffleEnabled}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color='secondary' />}
                    label={t('general.yes')}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color='secondary' />}
                    label={t('general.no')}
                  />
                </RadioGroup>
              </StyledFormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            {!currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}:
                  </article>
                  <Modules>
                    {selectedModules.map(selectedModule => (
                      <ModuleItemWrapper key={selectedModule.moduleId}>
                        <ModuleItem>{selectedModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    ))}
                  </Modules>
                </Wrapper>
              </ModuleWrapper>
            )}

            {currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}: :
                  </article>
                  {currentModule.moduleId && (
                    <Modules>
                      <ModuleItemWrapper>
                        <ModuleItem>{currentModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    </Modules>
                  )}
                </Wrapper>
              </ModuleWrapper>
            )}
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                onClick={handleSubmit}
                background='#06C68F'
                isDisabled={submitBtnDisabled}
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default EditQuestionDrawer
