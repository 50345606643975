import React, { useState, useEffect, ReactElement, FormEvent, ChangeEvent } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Header, FormFooter, FormButtons } from './styled-components'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormGroupWrapper } from 'components/common/Form/Form'
import { useTranslation } from 'react-i18next'
import { useAddQuestionsToModule } from 'hooks/questions/useAddQuestionsToModule'
import { GET_QUESTIONS } from 'gql/questions.query'
import { useUserValue } from 'context/UserContext'
import { PER_PAGE } from 'hooks/questions/useQuestions'
import Drawer from 'components/common/Drawer'
import FilterDrawer from '../FilterDrawer'
import { Toolbar } from 'pages/questions'
import Grid from '../Grid/Grid'
import { Button } from 'components/common/Button'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import Pagination from 'components/common/Pagination'
import { SelectChangeEvent } from '@mui/material'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IQuestion } from 'interfaces/questions'
import { ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { ISelectAll } from 'pages/quizzes/quiz.interface'
import { IQuestionsFilterData, IQuestionsFilterOption } from 'hooks/questions/useQuestionsLayout'
import removeKeys from 'utils/removeKeys'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'
import { ILinkQuestionsToModuleDrawerProps } from './linkQuestionsToModule.interface'

const LinkQuestionsToModuleDrawer = ({
  title,
  handleCloseDrawer,
  currentModule,
  refetch,
  manualCompanyId,
}: ILinkQuestionsToModuleDrawerProps): ReactElement => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const defaultFilters = {
    companyId: {
      type: 'exact',
      value: manualCompanyId || state.selectedCompany?.id,
    },
    modules: {
      type: 'nestedArrayNotIn',
      value: currentModule.moduleId,
      nestedField: 'moduleId',
    },
  }
  const { data, refetch: refetchCurrentQuestions } = useQuery(GET_QUESTIONS, {
    variables: {
      type: '',
      id: currentModule.moduleId,
      filter: defaultFilters,
      currentPage: 1,
      perPage: 12,
    },
  })
  const { addQuestionsToModule } = useAddQuestionsToModule()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterOptions, setFilterOptions] = useState<IQuestionsFilterOption>({})
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [selectedItem, setSelectedItem] = useState<ISelectPage>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<IQuestionsFilterData>({})
  const [filtered, setFiltered] = useState<boolean>(false)

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = data.getQuestions.data.reduce((ids: string[], n: IQuestion) => {
        const moduleIds = n.modules.map(item => item.moduleId)
        if (!moduleIds.includes(currentModule.moduleId)) {
          ids.push(n.id)
        }
        return ids
      }, [])
      setSelectedItem({ ...selectedItem, [currentPage]: newArr })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({ ...selectedItem, [currentPage]: [] })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        question: { type: 'match', value: searchValue },
      },
    })
    setFiltered(true)
  }

  const handleResetClick = (): void => {
    setFilterData({})
    setFiltered(false)
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: defaultFilters,
    })
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: defaultFilters,
      })
    }
  }

  const onSuccess = (): void => {
    refetch()
  }

  const handleSubmit = (): void => {
    const selectedQuestionsIds = Object.values(selectedItem).reduce(
      (acc: string[], val) => acc.concat(val),
      [],
    )
    const linkableObject = removeKeys(currentModule, ['courses']) as IModule
    addQuestionsToModule(linkableObject, selectedQuestionsIds, onSuccess)
  }

  const handlePaginationClick = (_: ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: {
          ...defaultFilters,
          ...filterOptions,
          question: { type: 'match', value: searchValue },
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handlePerPageChange = (event: SelectChangeEvent<number>): void => {
    const eventNum = parseInt(event.target.value as string)
    setPerPage(eventNum)
    setCurrentPage(1)
    setSelectedItem({})
    setSelectAll({})
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        ...filterOptions,
        question: { type: 'match', value: searchValue },
      },
      currentPage: 1,
      perPage: eventNum,
    })
  }

  const handleFilterClick = (filterOptions: IQuestionsFilterOption): void => {
    setSelectedItem({})
    setSelectAll({})
    setCurrentPage(1)
    setFilterOptions(filterOptions)
    refetchCurrentQuestions({
      filter: {
        ...defaultFilters,
        ...filterOptions,
        question: { type: 'match', value: searchValue },
      },
      currentPage: 1,
    })
  }

  useEffect(() => {
    if (Object.keys(selectedItem).length > 0) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }
  }, [selectedItem])

  const questionList = (data && data.getQuestions.data) || []
  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => setFilterDrawerOpened(false)}
        totalWidth='600px'
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel='module'
          handleFilterClick={handleFilterClick}
          companyId={manualCompanyId || state.selectedCompany?.id}
          refetch={refetchCurrentQuestions}
          currentPage={1}
          t={t}
        />
      </Drawer>
      <CustomDrawerHeader title={title} onClose={handleCloseDrawer} />
      <DrawerContent>
        <Container>
          <FormGroupWrapper>
            <Header>
              <Toolbar
                fromModule={true}
                searchValue={searchValue}
                gridStyle={gridStyle}
                setGridStyle={setGridStyle}
                dataLength={questionList.length}
                handleSearchChange={handleSearchChange}
                selectedItem={selectedItem[currentPage] || []}
                selectAllItem={selectAllItem}
                handleSearchSubmit={handleSearchSubmit}
                openFilterDrawer={(): void => setFilterDrawerOpened(true)}
                handleResetClick={handleResetClick}
                filtered={filtered}
                t={t}
              />
            </Header>
            <div style={{ padding: 10 }}>
              <Grid
                cols={2}
                gridStyle={gridStyle}
                data={questionList}
                selectItem={selectItem}
                selectedItems={selectedItem[currentPage] || []}
                currentModuleId={currentModule.moduleId}
                linkDrawer={true}
                t={t}
              />
            </div>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text={t('actions.save')}
                type='small'
                background='#06C68F'
                onClick={handleSubmit}
                isDisabled={
                  selectedItem[currentPage] ? selectedItem[currentPage].length === 0 : true
                }
              />
            </FormButtons>
            <div>
              <Pagination
                currentPage={data ? data.getQuestions.currentPage : 1}
                totalPages={data ? data.getQuestions.totalPages : 0}
                handleChange={handlePaginationClick}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </FormFooter>
        </Container>
      </DrawerContent>
    </>
  )
}

export default LinkQuestionsToModuleDrawer
